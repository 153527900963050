/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import LogoGIF from '../Assets/Images/beemg_logo.gif'
import companyLogo from '../Assets/Images/logo.svg'
import { HelpLink } from '../Components/HelpLink'
import LangDropdownMenu from '../Components/LangDropdownMenu'
import ScrollToTop from '../Components/ScrollToTop'
import UserDropdownMenu from '../Components/UserDropdownMenu'
import Avatar from '../Components/base/avatar/avatar'
import {
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
} from '../Components/base/dropdown/dropdown'
import Icon from '../Components/base/icon/icon'
import {
  Navbar,
  NavbarBrand,
  NavbarCollapse,
  NavbarItem,
  NavbarItem2,
  NavbarNav,
  NavbarToggler,
} from '../Components/base/navbar/navbar'
import { Config } from '../Config'
import { useCurrentTimezoneHook } from '../Hooks/CurrentTimezoneHook'
import { useUnreadMessageCount } from '../Hooks/MessageHooks'
import useSignOutHook from '../Hooks/SignOutHook'
import {
  getMeetingConnected,
  getMeetingJoined
} from '../Services/livekitReducer'
import { useGetSettingValue } from '../Services/settingReducer'
import { getToken } from '../Services/tokenReducer'
import { useGetCurrentUserDetailsQuery, userApi, useUpdateUserMutation } from '../Services/userApi'
import { getCurrentUser } from '../Services/userReducer'
import { useAppDispatch, useAppSelector } from '../Store/hooks'
import { Verified } from '../models/user.model'
import CountDown from './CountDown'
import Footer from './Footer'
import useWebSocket from '../Hooks/SocketHook'
import { useGetNotificationCountQuery } from '../Services/meetingRequestApi'
import {ReactComponent as SKIP} from '../Assets/icons/skip-end-fill-svgrepo-com.svg'
import { ToolTip } from '../Components/base/tooltip/tooltip'

/**
 * The main application menu bar, which is visible on all pages.
 *
 * The menu bar displays the company logo, the user's avatar, and a dropdown
 * menu with language and logout options. It also includes a footer with links
 * to the company's website and social media profiles.
 *
 * When the user is not logged in, the menu bar is not visible.
 *
 * @return {JSX.Element} The menu bar component.
 */
function MenuBar() {
  const navigate = useNavigate()
  const location = useLocation()

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
  useCurrentTimezoneHook(timeZone)
  useGetCurrentUserDetailsQuery()

  const token = useAppSelector(getToken)
  const currentUser = useAppSelector(getCurrentUser)
  // const {data: currentUser} = useGetCurrentUserDetailsQuery() // To work on face verification Navigation

  useWebSocket()

  useEffect(() => {
    if (!token || !currentUser) {
      navigate('/')
    }
  }, [token, currentUser, navigate])

  if (!currentUser) {
    return null
  }

  return (
    <div className="h-screen flex flex-1 flex-col">
      <div className="flex flex-col justify-between">
        <div className="fixed w-full top-0 z-100 bg-white">
        <Header />
        </div>
        <div className="mt-20 min-h-[85vh] overflow-hidden">
          <ScrollToTop />
          <Outlet />
        </div>
        {!location.pathname.startsWith('/s/ameeting/') && <Footer />}
      </div>
    </div>
  )
}

export default React.memo(MenuBar)

const style = {
  menu: `block w-full flex mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
  item: `py-1 px-5 w-full text-left`,
  navLink: `font-normal hover:text-black hover:font-medium flex flex-row items-center justify-center py-1 text-[1rem]`,
  active: `font-medium text-black bg-primary rounded-md px-5 py-1 flex flex-row items-center justify-center text-[1rem]`,
}

/**
 * Header component which contains the main navigation of the application.
 *
 * @param {string} timeZone - The current timezone
 * @param {boolean} showLogoAnimation - Flag to show the logo animation
 * @param {boolean} meetingConnected - Flag to show the meeting connected
 * @param {boolean} meetingJoined - Flag to show the meeting joined
 * @param {number} meetingNotificationCount - The count of the meeting notifications
 * @param {number} unreadChatCount - The count of the unread chat messages
 * @returns {ReactElement} The header component
 */
const Header = () => {
  const {t, i18n} = useTranslation()

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
  useCurrentTimezoneHook(timeZone)

  const currentUser = useAppSelector(getCurrentUser)
  const {signOutActions} = useSignOutHook()
  const {data: meetingNotificationCount, refetch: notificationRefetch} =
    useGetNotificationCountQuery()
  const {unreadChatCount} = useUnreadMessageCount()
  const [updateUser, {isSuccess: profileUpdateSuccess}] =
    useUpdateUserMutation<any>()

  const meetingConnected = useAppSelector(getMeetingConnected)
  const meetingJoined = useAppSelector(getMeetingJoined)

  const location = useLocation()

  const [showLogoAnimation, setShowLogoAnimation] = useState<boolean>(false)

  const currentUserVerified =
    currentUser &&
    currentUser.phoneVerified === Verified.Complete &&
    currentUser.emailVerified === Verified.Complete &&
    currentUser.verified === Verified.Complete &&
    currentUser.faceVerified === Verified.Complete ||
      currentUser.faceVerified === Verified.Skipped;

  useEffect(() => {
    const interval = setInterval(() => {
      notificationRefetch()
    }, 20000)

    return () => clearInterval(interval)
  }, [notificationRefetch])

  useEffect(() => {
    if (location) {
      setShowLogoAnimation(true)
      setTimeout(() => {
        setShowLogoAnimation(false)
      }, 1000)
    }
  }, [location])

  return (
    <header className="z-40 bg-white h-20 px-[2.5%] ">
      <div>
        <Navbar className={`text-black bg-transparent h-20 items-center`}>
          <NavbarBrand href="/s/home">
            <div className="h-16 lg:h-20 w-20 lg:m-1 lg:pt-1 flex flex-1 justify-center items-center">
              {!showLogoAnimation ? (
                <img
                  src={companyLogo}
                  alt="logo"
                  className="h-12 w-12 m-1 pt-1"
                  id="btn_logo"
                />
              ) : (
                <img
                  src={LogoGIF}
                  alt="logo"
                  className="h-12 m-1 pt-1"
                  id="btn_logo"
                />
              )}
            </div>
          </NavbarBrand>

          <NavbarToggler>
            <DropdownMenu>
              <div className="flex justify-end mb-2">
                <div className="list-none flex">
                  {/* <LangDropdownMenu /> */}
                  <HelpLink />
                </div>
              </div>
              <div>
                {currentUser && (
                  <div className="flex flex-col mb-2 items-center">
                    <span>
                      {currentUser?.userImage && (
                        <Avatar size="small" images={currentUser.image} />
                      )}
                    </span>
                    <span className="font-semibold">
                      {'Hi '}
                      {currentUser.firstName}
                    </span>
                  </div>
                )}
              </div>
              <div className="list-none">
                {!meetingJoined && !meetingConnected && (
                  <NavbarItem2>
                    <CountDown />
                  </NavbarItem2>
                )}
              </div>
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/creator'}
                  id="creator">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="CREATOR" size="small" />
                    </div>
                    <div className="text-base">{t('creators')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/collaborator'}
                  id="collaborator">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="COLLABORATORS" size="small" />
                    </div>
                    <div className="text-base">{t('collaborators')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/movies'}
                  id="movies">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="CREATOR" size="small" />
                    </div>
                    <div className="text-base">{t('Movies')}</div>
                  </div>
                </Link>
              </DropdownItem>
              {currentUserVerified && (
                <>
                  <DropdownItem>
                    <Link
                      className="py-1 px-2 w-full text-left"
                      to={'/s/events'}
                      id="events">
                      <div className="flex">
                        <div className="mr-1">
                          <Icon icon="EVENTS" size="small" />
                        </div>
                        <div className="text-base">{t('events')}</div>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      className="py-1 px-2 w-full text-left"
                      to={'/s/chat-box'}
                      id="chat-box">
                      <div className="flex">
                        <div className="mr-1">
                          <Icon icon="MESSAGES" size="small" />
                        </div>
                        <div className="text-base">{t('messages')}</div>
                      </div>
                    </Link>
                  </DropdownItem>
                </>
              )}
              <DropdownDivider />
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/profile'}
                  id="menu_profile">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="USER_BLACK" size="small" />
                    </div>
                    <div className="text-base">{t('profile')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/transaction-list'}
                  id="menu_transactions">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="CURRENCY" size="small" />
                    </div>
                    <div className="text-base">{t('transactions')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/invite'}
                  id="menu_invite">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="USERS" size="small" />
                    </div>
                    <div className="text-base">{t('invite')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem>
                <Link
                  className="py-1 px-2 w-full text-left"
                  to={'/s/settings'}
                  id="menu_settings">
                  <div className="flex">
                    <div className="mr-1">
                      <Icon icon="SETTINGS" size="small" />
                    </div>
                    <div className="text-base">{t('settings')}</div>
                  </div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <div
                  className="flex py-1 px-2 w-full text-left"
                  onClick={() => signOutActions()}>
                  <div className="mr-1">
                    <Icon icon="LOGOUT" size="small" />
                  </div>
                  <div className="text-base">
                    <span className="text-red-500" id="menu_signOut">
                      {t('signOut')}
                    </span>
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </NavbarToggler>

          <NavbarCollapse>
            <NavbarNav orientation="start">
              <NavbarItem>
                <NavLink
                  id="btn_creator"
                  to={'/s/creator'}
                  className={(navData: any) =>
                    navData.isActive ? style.active : style.navLink
                  }>
                  <span data-testid="btn_creator">{t('creators')}</span>
                </NavLink>
              </NavbarItem>
              <NavbarItem>
                <NavLink
                  id="btn_collaborator"
                  to={'/s/collaborator'}
                  className={(navData: any) =>
                    navData.isActive ? style.active : style.navLink
                  }>
                  <span data-testid="btn_collaborator">
                    {t('collaborators')}
                  </span>
                </NavLink>
              </NavbarItem>
              <NavbarItem>
                <NavLink
                  id="btn_movie"
                  to={'/s/movies'}
                  className={(navData: any) =>
                    navData.isActive ? style.active : style.navLink
                  }>
                  <span data-testid="btn_collaborator">
                    {t('Movies')}
                  </span>
                </NavLink>
              </NavbarItem>
              {currentUserVerified && (
                <>
                  <NavbarItem>
                    <NavLink
                      id="btn_events"
                      to={'/s/events'}
                      className={(navData: any) =>
                        navData.isActive ? style.active : style.navLink
                      }>
                      <span className="flex items-center">
                        {t('events')}
                        {meetingNotificationCount !== 0 && (
                          <div
                            id="meeting_NotificationCount"
                            className="bg-BeeMG-black w-6 h-6 rounded-full text-BeeMG-yellow text-xs leading-none flex items-center justify-center ml-1">
                            {meetingNotificationCount}
                          </div>
                        )}
                      </span>
                    </NavLink>
                  </NavbarItem>
                  <NavbarItem>
                    <NavLink
                      id="btn_message"
                      to="/s/chat-box"
                      className={(navData: any) =>
                        navData.isActive ? style.active : style.navLink
                      }>
                      <span
                        className="flex items-center"
                        data-testid="btn_message">
                        {t('messages')}
                        {unreadChatCount > 0 && (
                          <div
                            id="chk_newMsgCount"
                            data-testid="chk_newMsgCount"
                            className="bg-BeeMG-black w-6 h-6 rounded-full text-BeeMG-yellow text-xs leading-none flex items-center justify-center ml-1">
                            {unreadChatCount}
                          </div>
                        )}
                      </span>
                    </NavLink>
                  </NavbarItem>
                </>
              )}
            </NavbarNav>
            <NavbarNav orientation="end" className="flex flex-row gap-x-6">
              {!meetingJoined && !meetingConnected && (
                <NavbarItem2>
                  <CountDown />
                </NavbarItem2>
              )}
              {/* <NavbarItem2>
                <LangDropdownMenu />
              </NavbarItem2> */}
              <NavbarItem2>
                <UserDropdownMenu />
              </NavbarItem2>
            </NavbarNav>
          </NavbarCollapse>
        </Navbar>
      </div>
      <VerificationMessage />
    </header>
  )

  /**
   * Renders a verification message for the user to verify their email, phone, and face.
   * The message is displayed only if the user has not completed the verification process.
   * The message provides a link to the verification page.
   * The message is displayed at the top of the page.
   *
   * @return {JSX.Element} The verification message.
   */
  function VerificationMessage() {
    const faceVErificationURL = useGetSettingValue('FACE_VERIFICATION_LINK')
    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    /**
     * Opens the face verification URL in a new window with the user's
     * id, IP address, language and API URL as parameters.
     *
     * This function is called when the user clicks on the face verification
     * link in the verification message.
     */
    const handleVerifyFace = () => {
      const params: {[key: string]: string} = {
        cUserId: currentUser.id,
        cIPAddress: currentUser.ipAddress,
        cLanguage: i18n.language,
        apiUrl: Config.API_URL,
      }
      console.log('Face verify 001 -->', params.cIPAddress)
      
      window.open(
        `${faceVErificationURL}faceverify?${new URLSearchParams(
          params,
        ).toString()}`,
        '_self',
      )
    }

    const handleSkip = () => {
      updateUser({
        ...currentUser,
        faceVerified: Verified.Skipped,
        verified : Verified.Complete
      }).then(() => {
        dispatch(userApi.endpoints.getCurrentUserDetails.initiate()).then(
          res => {
            navigate('/s/chooseInterests');
          },
        )
      })
    }

    return (
      <>
        {location.pathname !== '/verifyEmail' && (
          <>
            <div className="flex justify-center z-1024">
              {(currentUser.phoneVerified === Verified.NotComplete ||
                currentUser.emailVerified === Verified.NotComplete ||
                currentUser.faceVerified === Verified.NotComplete) && (
                <div
                  data-testid="error_verifyUser"
                  className="bg-red-100 w-fit text-yellow-900 rounded-full py-1 mt-12 px-5 mx-auto text-center fixed">
                  {/* email, phone and face verification check */}
                  {currentUser.phoneVerified === Verified.NotComplete &&
                    currentUser.emailVerified === Verified.NotComplete &&
                    currentUser.faceVerified === Verified.NotComplete && (
                      <Link to="/s/profile" id="error_verifyEmailPhoneFace">
                        <span>
                          {t(
                            'emailPhoneAndFaceVerificationNotCompletedForThisUser',
                          )}
                          ! {t('clickHereToVerify')}
                        </span>
                      </Link>
                    )}
                  {/* email and phone verification check */}
                  {(currentUser.phoneVerified === Verified.NotComplete &&
                    currentUser.emailVerified === Verified.NotComplete &&
                    (currentUser.faceVerified as Verified) ===
                      Verified.Complete) ||
                    ((currentUser.faceVerified as Verified) ===
                      Verified.Skipped && (
                      <Link to="/s/profile" id="error_verifyEmailPhone">
                        <span>
                          {t(
                            'emailAndPhoneVerificationNotCompletedForThisUser',
                          )}
                          ! {t('clickHereToVerify')}
                        </span>
                      </Link>
                    ))}
                  {/* email and face verification check */}
                  {currentUser.phoneVerified === Verified.Complete &&
                    currentUser.emailVerified === Verified.NotComplete &&
                    currentUser.faceVerified === Verified.NotComplete && (
                      <Link to="/s/profile" id="error_verifyEmailFace">
                        <span>
                          {t('emailAndFaceVerificationNotCompletedForThisUser')}
                          ! {t('clickHereToVerify')}
                        </span>
                      </Link>
                    )}
                  {/* phone and face verification check */}
                  {currentUser.phoneVerified === Verified.NotComplete &&
                    currentUser.emailVerified === Verified.Complete &&
                    currentUser.faceVerified === Verified.NotComplete && (
                      <Link to="/s/profile" id="error_verifyPhoneFace">
                        <span>
                          {t('phoneAndFaceVerificationNotCompletedForThisUser')}
                          ! {t('clickHereToVerify')}
                        </span>
                      </Link>
                    )}
                  {/* email verification check */}
                  {(currentUser.phoneVerified === Verified.Complete &&
                    currentUser.emailVerified === Verified.NotComplete &&
                    (currentUser.faceVerified as Verified) ===
                      Verified.Complete) ||
                    ((currentUser.faceVerified as Verified) ===
                      Verified.Skipped && (
                      <Link to="/s/profile" id="error_verifyEmail">
                        <span>
                          {t('emailVerificationNotCompletedForThisUser')}!{' '}
                          {t('clickHereToVerify')}
                        </span>
                      </Link>
                    ))}
                  {/* phone verification check */}
                  {(currentUser.phoneVerified === Verified.NotComplete &&
                    currentUser.emailVerified === Verified.Complete &&
                    (currentUser.faceVerified as Verified) ===
                      Verified.Complete) ||
                    ((currentUser.faceVerified as Verified) ===
                      Verified.Skipped && (
                      <Link to="/s/profile" id="error_verifyPhone">
                        <span>
                          {t('phoneVerificationNotCompletedForThisUser')}!{' '}
                          {t('clickHereToVerify')}
                        </span>
                      </Link>
                    ))}
                  {/* face verification check */}
                  {currentUser.phoneVerified === Verified.Complete &&
                    currentUser.emailVerified === Verified.Complete &&
                    currentUser.faceVerified === Verified.NotComplete && (
                      <div>
                        <div onClick={handleVerifyFace} id="error_verifyFace">
                          <span>
                            {t('faceVerificationNotCompletedForThisUser')}!{' '}
                          </span>
                          <span className="underline hover:cursor-pointer">
                            {t('clickHereToVerify')}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              )}

              {location.pathname === '/s/faceVerify' && (
                <div className="mt-28 px-2 mx-auto flex justify-center ">
                  <div
                    className="flex cursor-pointer justify-end"
                    onClick={handleSkip}>
                    <ToolTip tip={t('skipFaceVerification')} keyId="send">
                      <span className="flex px-2 items-center border border-black rounded-full">
                        <span>{t('skip')}</span>
                        <SKIP />
                      </span>
                    </ToolTip>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
