/*
 * Copyright © 2024 @Himutsulab All Rights Reserved.
 */
import * as yup from 'yup'
import differenceInYears from 'date-fns/differenceInYears'
import YupPassword from 'yup-password'
import 'yup-phone'
import { isBefore } from 'date-fns'
import { parseInt } from 'lodash'

YupPassword(yup)

yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    // @ts-ignore
    const uniqueData = Array.from(new Set(array.map(row => row[field]?.toLowerCase())))
    // @ts-ignore
    const isUnique = array.length === uniqueData.length;

    if (isUnique) {
      return true
    }

    // @ts-ignore
    const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i]);

    // @ts-ignore
    if (array[index][field] === '') {
      return true
    }

    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    })
  })
})

const emailSchema = yup
  .string()
  .email('enterValidEmailID')
  .transform((curr, orig) => (orig === null ? '' : curr))
  .required('required')
  .max(50, 'emailCanBeMax50CharactersLong')

// @ts-ignore
function getTimeDifference(startDateTime, endDateTime) {
  const timeDiff = new Date(endDateTime).getTime() - new Date(startDateTime).getTime()
  return Math.round(timeDiff / 60000)
}

export const coHostValidationSchema = yup.object().shape({
  email: emailSchema,
})

export const loginValidationSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().required('required'),
})

export const refundRequestValidationSchema = yup.object().shape({
  refundCategory: yup.string().nullable().required('Category is required'),
  participantName: yup.string().required('Participant Name is required')
    .trim()
    .test('is-greater', 'endTimeIsLessThanStartTime', function (participant) {
      console.log("participantId001",participant)

      return true;

    }),

    meetingId: yup
    .string()
    .required('Meeting ID is required')
    .trim(),

    refundReason: yup
    .string()
    .max(2000, 'refundReasonCanBeMax2000CharactersLong')
    .required('required')
    .trim()
});

export const contactValidationSchema = yup.object().shape({
  email: emailSchema,
  subject: yup
    .string()
    .max(100, 'subjectCanBeMax100CharactersLong')
    .required('required')
    .trim(),
  feedback: yup
    .string()
    .max(500, 'feedbackCanBeMax500CharactersLong')
    .required('required')
    .trim(),
  category: yup.string()
    .required('pleaseSelectOneCategory')
    .max(50, 'categoryNameShouldBeMax50Characters')
    .trim()
})

export const feedbackValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  rating: yup.number().required('required').max(5).min(1, 'required').transform(value => (isNaN(value) ? 0 : value)),
  review: yup
    .string()
    .max(800, 'reviewShouldBeMax800Characters')
    .required('required')
    .trim()
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const launchNotificationValidationSchema = yup.object().shape({
  email: emailSchema,
  firstName: yup
    .string()
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  lastName: yup
    .string()
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),

  phoneNo: yup
    .string()
    .transform(value => ('+' + value))
    // @ts-ignore
    .phone(null, null, 'enterValidCountryCodeAndPhoneNo')
    .required('required')
})

export const meetingFilterFormValidationSchema = yup.object().shape({
  minPrice: yup.string()
    .required('required')
    .test('is-greater', 'minimumPriceShouldBeLessThanMaxPrice', function (value) {
      // @ts-ignore
      const minPrice = parseInt(value.replace(/,/g, ''), 10);
      // @ts-ignore
      const maxPrice = parseInt(this.parent.maxPrice.replace(/,/g, ''), 10);

      if (minPrice === 0 && maxPrice === 0) {
        return true; // Skip validation if both minPrice and maxPrice are zero
      }

      if (isNaN(minPrice) || isNaN(maxPrice)) {
        return false;
      }

      return minPrice < maxPrice;
    }),
  maxPrice: yup.string()
    .required('required')
    .test('is-zero', 'maximumPriceShouldBeGreaterThanZero', function (value) {
      // @ts-ignore
      const maxPrice = parseInt(value.replace(/,/g, ''), 10);
      const defaultMaxPrice = parseInt(document.getElementById('filter_searchByMaxPrice')?.defaultValue.replace(/,/g, ''), 10);

      if (maxPrice === 0 && defaultMaxPrice !== 0) {
        return this.createError({ message: "maximumPriceShouldBeGreaterThanZero" });
      }

      if (isNaN(maxPrice) || isNaN(defaultMaxPrice)) {
        return false;
      }

      if (maxPrice > defaultMaxPrice) {
        return this.createError({ message: "maximumPriceCanBe" })
      }

      return true
    }),
  _startFromTime: yup.string()
    .required('required')
    // @ts-ignore
    .test('is-less-than', 'endTimeIsLessThanStartTime', function (value, x) {
      const startTime = value
      // @ts-ignore
      const endTime = document.getElementById('input_endTime')?.value + ':' + document.getElementById('endMeridiem')?.innerText
      //   if(startTime?.length <= 3 && endTime?.length <= 3){
      //     return this.createError({ message: "invalidTime" })
      //   }
      //   if (startTime && endTime) {
      //     const startDateTime = new Date(`01/01/2020 ${startTime.slice(0, 5) + ' ' + startTime.slice(6, 8)}`)
      //     const endDateTime = new Date(`01/01/2020 ${endTime.slice(0, 5) + ' ' + endTime.slice(6, 8)}`)
      //     const timeDiff = differenceInMinutes(startDateTime, endDateTime)
      //     console.log(timeDiff)
      //     if (timeDiff >= 0) {
      //       return this.createError({ message: "EndTimeIsLessThanStartTime" })
      //     }
      //     return true
      //   }
      if (startTime && endTime) {
        const startDateTime1 = new Date(`01/01/2020 ${startTime.slice(0, 5) + ' ' + startTime.slice(6, 8)}`)
        const endDateTime1 = new Date(`01/02/2020 ${endTime.slice(0, 5) + ' ' + endTime.slice(6, 8)}`)
        const timeDiff = getTimeDifference(startDateTime1, endDateTime1) / 1440
        if (timeDiff === 1) {
          return this.createError({ message: "startTimeAndEndTimeCannotBeSame" })
        }
        return true
      }
      //   const startFromTimeHours = parseInt(startTime.split(':')[0])
      //   const startFromTimeMinutes = parseInt(startTime.split(':')[1])
      //   const endFromTimeHours = parseInt(endTime.split(':')[0])
      //   const endFromTimeMinutes = parseInt(endTime.split(':')[1])
      //   if (startFromTimeHours < endFromTimeHours) {
      //     return true
      //   } else if (startFromTimeHours === endFromTimeHours) {
      //     if (startFromTimeMinutes < endFromTimeMinutes) {
      //       return true
      //     }
      //   }
      // }else {
      //   return true
      // }
    }),

  // startToTime: yup.string()
  //   .test('is-greater-than', 'endTimeShouldBeGreaterThanStartTime', function (value) {
  //     const startToTime = value
  //     const startFromTime = document.getElementById('input_startTime')?.value
  //     if (startFromTime && startToTime) {
  //       const startFromHour = parseInt(startFromTime.split(':')[0])
  //       const startFromMinute = parseInt(startFromTime.split(':')[1])
  //       const endFromHour = parseInt(startToTime.split(':')[0])
  //       const endFromMinute = parseInt(startToTime.split(':')[1])
  //       if (startFromHour < endFromHour) {
  //         return true
  //       }
  //       if (startFromHour === endFromHour) {
  //         if (startFromMinute < endFromMinute) {
  //           return true
  //         }
  //       }
  //       return this.createError({ message: "endTimeShouldBeGreaterThanStartTime" })
  //     }
  //   }),
})

export const meetingFormValidationSchema = yup.object().shape({
  maxDuration: yup.number(),
  minCost: yup.string(),
  maximumParticipants: yup.number(),
  isFreeAudioMeeting: yup.boolean(),
  fromDateTime: yup.string().required('required'),
  defaultParticipantCount: yup.number(),
  toDateTime: yup
    .string()
    .default(null)
    .required('required')
    .test('is-greater', 'endTimeIsLessThanStartTime', function (toDateTime) {
      const { fromDateTime } = this.parent
      const result = getTimeDifference(fromDateTime, toDateTime);

      return result >= 0;

    })
    .test('is-same', 'BeeMG-ERR082', function (toDateTime) {
      const { fromDateTime } = this.parent
      const timeDifference = getTimeDifference(fromDateTime, toDateTime);
      if (timeDifference === 0)
        return false
      return true
    })
    .test('duration check', `Maximum duration`, function (toDateTime) {
      const { fromDateTime } = this.parent
      const result = getTimeDifference(fromDateTime, toDateTime);
      if (result > this.parent.maxDuration) {
        const message = `eventDurationMaxMinutes`
        return this.createError({ message: message })
      } else {
        return true
      }
    }),
  cost: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('Required', 'required', function (value) {
      if ((this.parent.isFreeAudioMeeting && !value) || (!this.parent.isFreeAudioMeeting && value)) {
        return true
      } else {
        return this.createError({ message: `required` })
      }
    })
    .test('Required', 'required', function (value) {
      if (this.parent.minCost && value) {
        if (value < this.parent.minCost) {
          const message = `priceMustBeGreaterThan`
          return this.createError({ message: message })
        }
        return true
      }
      return true
    }),

  language: yup.string().required('required')
    .trim()
    .matches(/^[a-zA-Z]+$/, 'InvalidCharacters'),

  noOfParticipants: yup
    .number()
    .transform(value => (isNaN(value) ? 0 : value))
    .test('limit', `maximumParticipantsIs`, function (value) {
      const defaultValue = document.getElementById('input_noOfParticipants')?.defaultValue
      const defaultValueMobile = this.parent.defaultParticipantCount;
      if (!value) {
        const message = `required`
        return this.createError({ message: message })
      }
      if (value < 1) {
        const message = `noOfUsersMustBeAtleast1`
        return this.createError({ message: message })
      }
      if (value < (defaultValue || defaultValueMobile)) {
        const message = `userShouldBeMoreThanGivenCount`
        return this.createError({ message: message })
      }
      const { maximumParticipants } = this.parent

      if (value > maximumParticipants) {
        const message = `maximumUsersIs`
        return this.createError({ message: message })
      } else {
        return true
      }
    })
    .typeError('noOfUsersMustBeANumber')
    .required('required'),
  minimumNoOfParticipants: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .when('noOfParticipants', (noOfParticipants, schema) => {
      return noOfParticipants >= 0 ? schema.max(noOfParticipants, 'moreThanTotalUsers') : schema
    })
    .test('required', function (value) {
      if (!value && value !== 0) {
        return this.createError({ message: `required` })
      } else if (value <= 0) {
        return this.createError({ message: `minimumOfUsersMustBeAtleast1` })
      } else {
        return true
      }
    })
    .nullable(false),
})

export const meetingDetailsDeleteFormValidationSchema = yup.object().shape({
  meetingDeletedReason: yup.string().required('required')
    .trim()
    .max(500, 'reasonCanBeMax500CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const cancellationFormValidationSchema = yup.object().shape({
  category: yup.string().required('pleaseSelectOneCategory').trim(),
  reason: yup.string().required('required')
    .trim()
    .max(500, 'reasonCanBeMax500CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const meetingDetailsFormValidationSchema = yup.object().shape({
  isFreeAudioMeeting: yup.boolean(),
  title: yup
    .string()
    .trim()
    .required('required')
    .matches(/^(?!.*(?:http[s]?:\/\/[^\s]+|www\.[^\s]+)).+$/, 'linksAreNotAllowed') // Restrict link
    .matches(/^(?!.*(?:[^\s@]+@[^\s@]+\.[^\s@]+)).+$/, 'emailAddressesAreNotAllowed') // Restrict email
    .matches(/^(?!.*(?:\b(?:\d[\s-]*[a-zA-Z]*){10,}\b|(\d\s*){10,})).+$/, 'mobileNumbersAreNotAllowed') // Restrict mobile number
    .min(2, 'eventTitleShouldBeMin2Characters')
    .max(100, 'eventTitleShouldBeMax100Characters'),
  description: yup
    .string()
    .transform((value) => (value ? value.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "").trim() : value))
    .required('required')
    .matches(/^(?!.*(?:http[s]?:\/\/[^\s]+|www\.[^\s]+)).+$/, 'linksAreNotAllowed') // Restrict link
    .matches(/^(?!.*(?:[^\s@]+@[^\s@]+\.[^\s@]+)).+$/, 'emailAddressesAreNotAllowed') // Restrict email
    .matches(/^(?!.*(?:\b(?:\d[\s-]*[a-zA-Z]*){10,}\b|(\d\s*){10,})).+$/, 'mobileNumbersAreNotAllowed'), // Restrict mobile number
  cancellationPolicy: yup
    .object()
    .test('cancellationPolicy', 'required', function (cancellationPolicy) {
      if ((!this.parent.isFreeAudioMeeting && !cancellationPolicy)) {
        return this.createError({ message: 'required' })
      } else {
        return true
      }
    }).nullable(),
})

export const meetingRequestFormValidationSchema = yup.object().shape({
  maxDuration: yup.number(),
  selectedDates: yup.array().of(
    yup.object().shape({

      startDate: yup
        .string()
        .required('required')
        .test('is future date', 'youHaveChosenPastFromTime', validateFutureDate),
      endDate: yup
        .string()
        .required('required')
        .test('is future date', 'youHaveChosenPastToTime', validateFutureDate)
        .test('is end time greater', 'endTimeShouldBeGreaterThanStartTime', function (endDate) {
          return validateEndTime(this.parent.startDate, endDate)
        })
        .test('is same time', 'startTimeAndEndTimeCannotBeSame', function (endDate) {
          return validateSameTime(this.parent.startDate, endDate)
        })
        .test('duration check', `Maximum duration`, function (endDate, obj) {
          const { startDate } = this.parent
          const maxDuration = obj.from[1].value.maxDuration
          const result = getTimeDifference(startDate, endDate);
          if (result > maxDuration) {
            const message = `eventDurationMaxMinutes`
            return this.createError({ message: message })
          } else {
            return true
          }
        }),
    })),

  // .test('is duplicate', function (selectedDates, schema) {
  //   if (selectedDates) {
  //     const result = selectedDates.filter((selectedDate, index, self) => self.findIndex(t => t.startDate === selectedDate.startDate) !== index)
  //     if (result.length > 0) {
  //       const lastIndex = selectedDates.map(el => el.startDate).lastIndexOf(result[0].startDate);
  //       return this.createError({
  //         path: `${schema.path}.[${lastIndex}].startDate`,
  //         message: 'youHaveAlreadyChosenSameTimeSlot',
  //       });
  //     }
  //     return true
  //   }
  //   return true
  // })
  // ),

  // startAt2DateTime: yup
  //   .string()
  //   .nullable()
  //   .test('is future date', 'youHaveChosenPastTime', validateFutureDate),
  // endAt2DateTime: yup
  //   .string()
  //   .nullable()
  //   .test('required', 'required', function (endAt2DateTime) {
  //     return validateDateAndTimeRequired(this.parent.startAt2DateTime, endAt2DateTime)
  //   })
  //   .test('is future date', 'youHaveChosenPastTime', validateFutureDate)
  //   .test('is end time greater', 'endTimeShouldBeGreater', function (endAt2DateTime) {
  //     return validateEndTime(this.parent.startAt2DateTime, endAt2DateTime)
  //   })
  //   .test('is same time', 'startTimeAndEndTimeCannotBeSame', function (endAt2DateTime) {
  //     return validateSameTime(this.parent.startAt2DateTime, endAt2DateTime)
  //   })
  //   .test('is duplicate', 'youHaveAlreadyChosenSameTimeSlot', function (endAt2DateTime) {
  //     return validateDuplicateDate(
  //       this.parent.startAt1DateTime,
  //       this.parent.endAt1DateTime,
  //       this.parent.startAt2DateTime,
  //       endAt2DateTime,
  //     )
  //   }),
  // startAt3DateTime: yup
  //   .string()
  //   .nullable()
  //   .test('is future date', 'youHaveChosenPastTime', validateFutureDate),
  // endAt3DateTime: yup
  //   .string()
  //   .nullable()
  //   .test('required', 'required', function (endAt3DateTime) {
  //     return validateDateAndTimeRequired(this.parent.startAt3DateTime, endAt3DateTime)
  //   })
  //   .test('is future date', 'youHaveChosenPastTime', validateFutureDate)
  //   .test('is end time greater', 'endTimeShouldBeGreater', function (endAt3DateTime) {
  //     return validateEndTime(this.parent.startAt3DateTime, endAt3DateTime)
  //   })
  //   .test('is same time', 'startTimeAndEndTimeCannotBeSame', function (endAt3DateTime) {
  //     return validateSameTime(this.parent.startAt3DateTime, endAt3DateTime)
  //   })
  //   .test('is duplicate', 'youHaveAlreadyChosenSameTimeSlot', function (endAt3DateTime) {
  //     return (
  //       validateDuplicateDate(
  //         this.parent.startAt1DateTime,
  //         this.parent.endAt1DateTime,
  //         this.parent.startAt3DateTime,
  //         endAt3DateTime,
  //       ) &&
  //       validateDuplicateDate(
  //         this.parent.startAt2DateTime,
  //         this.parent.endAt2DateTime,
  //         this.parent.startAt3DateTime,
  //         endAt3DateTime,
  //       )
  //     )
  //   }),
  unitPrice: yup
    .number()
    .required('required')
    .positive('unitPriceMustBeaPositiveNumber')
    .transform(value => (isNaN(value) ? undefined : value))
    .test('Required', 'required', function (value) {
      if (this.parent.minCost && value) {
        if (value < this.parent.minCost) {
          const message = `priceMustBeGreaterThan`
          return this.createError({ message: message })
        }
        return true
      }
      return true
    }),
  minCost: yup.string(),
  // cost: yup
  //   .number()
  //   .required('required')
  //   .positive('costMustBeAPositiveNumber')
  //   .transform(value => (isNaN(value) ? undefined : value)),
  participants: yup
    .number()
    .positive()
    .transform(value => (isNaN(value) ? 0 : value))
    .min(1, 'noOfUsersMustBeAtleast1')
    .test('limit', `Maximum participants is`, function (value) {
      const { maximumParticipants } = this.parent;
      // @ts-ignore
      if (value > maximumParticipants) {
        const message = `maximumUsersIs`
        return this.createError({ message: message })
      }
      else {
        return true
      }
    })
    .typeError('noOfUsersMustBeANumber')
    .required('required'),
  maximumParticipants: yup.number(),
  details: yup
    .string()
    .max(150, 'needOfRequestCanBeMax150CharactersLong')
    .transform((value) => (value ? value.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "").trim() : value))
    .required('required'),
})

//ResetPasswordValidationSchemaForReactNative
export const resetPasswordValidationSchemaForReactNative = yup.object().shape({
  oldPassword: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  newPassword: yup
    .string()
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .test('password check', ``, function (value) {
      const message = []
      // it should be done only with if condition
      if (value) {
        if (value.length < 8) {
          message.push('greaterThanOrEqualTo8Characters')
        }

        if (value.length > 25) {
          message.push('lessThanOrEqualTo25Characters')
        }

        if (value.match(/(\s)/g)) {
          message.push('blankSpacesAreNotAllowed')
        }

        if (!value.match(/(\d)/g)) {
          message.push('atleastOneNumber')
        }

        if (!value.match(/[A-Z]/g)) {
          message.push('atleastOneUpperCaseLetter')
        }

        if (!value.match(/[a-z]/g)) {
          message.push('atleastOneLowerCaseLetter')
        }

        if (!value.match(/[!@#$%^&*]/g)) {
          message.push('atleastOneSpecialSymbol')
        }
      } else {
        return true;
      }
      if (message.length > 0) {
        return this.createError({ message: message.toString() })
      } else {
        return true
      }
    }),
})

// ResetPasswordValidationSchema
export const resetPasswordFormValidationSchema = yup.object().shape({
  code: yup.string().required('required').trim(),
  password: yup
    .string()
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .test('password check', ``, function (value) {
      const message = []
      if (value) {
        if (value.length < 8) {
          message.push('greaterThanOrEqualTo8Characters')
        }

        if (value.length > 25) {
          message.push('lessThanOrEqualTo25Characters')
        }

        if (value.match(/(\s)/g)) {
          message.push('blankSpacesAreNotAllowed')
        }

        if (!value.match(/(\d)/g)) {
          message.push('atleastOneNumber')
        }

        if (!value.match(/[A-Z]/g)) {
          message.push('atleastOneUpperCaseLetter')
        }

        if (!value.match(/[a-z]/g)) {
          message.push('atleastOneLowerCaseLetter')
        }

        if (!value.match(/[!@#$%^&*]/g)) {
          message.push('atleastOneSpecialSymbol')
        }
      } else {
        return true;
      }

      if (message.length > 0) {
        return this.createError({ message: message.toString() })
      } else {
        return true
      }
    }),
  confirmPassword: yup
    .string()
    .required('required')
    .when('password', {
      // @ts-ignore
      is: password => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "enteredPasswordsAreDifferent"),
    })
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const profileValidationSchema = yup.object().shape({
  ageLimit: yup.number(),
  firstName: yup
    .string()
    .trim()
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),
  lastName: yup
    .string()
    .trim()
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),
  email: emailSchema,
  gender: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  selfIntro: yup
    .string()
    .max(1500, 'introductionCanBeMax1500CharactersLong')
    .nullable(),
  catchPhrase: yup
    .string()
    .trim()
    .max(150, 'catchPhraseMustBeAtMost150Characters')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),
  dateOfBirth: yup
    .string()
    .test('dob', 'shouldBeGreaterThan18', function (dateOfBirth) {
      if (dateOfBirth) {
        const date = new Date(dateOfBirth)
        return differenceInYears(new Date(), date) >= this.parent.ageLimit
      } else {
        return true
      }
    })
    .required('required')
    .nullable(),
  facebookName: yup
    .string()
    .trim()
    .nullable(),
  twitterName: yup
    .string()
    .trim()
    .nullable(),
  instagramName: yup
    .string()
    .trim()
    .nullable(),
  linkedInName: yup
    .string()
    .trim()
    .nullable(),
  selectedCountry: yup
    .string()
    .required('required')
    .trim()
    .transform((curr, orig) => (orig === null ? '' : curr)),
  city: yup
    .string()
    .required('required')
    .trim()
    .transform((curr, orig) => (orig === null ? '' : curr)),
  anonymous: yup.boolean().nullable(),
  phoneNumber: yup
    .string()
    .transform(value => ('+' + value))
    // @ts-ignore
    .phone(null, null, 'enterValidCountryCodeAndPhoneNo')
    .required('required'),
  selectedNickname: yup
    .string().trim()
    .test('is required', 'required', function (selectedNickname) {
      if (this.parent.anonymous && !selectedNickname) {
        return false
      }
      return true
    })
    .max(25, 'nickNameCanBeMax25CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const emailVerificationValidationSchema = yup.object().shape({
  code: yup
    .string()
    .required('required')
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required'),
})

export const signUpValidationSchema = yup.object().shape({
  ageLimit: yup.number(),
  firstName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  lastName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  email: emailSchema,
  gender: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  phoneNumber: yup
    .string()
    .required('required'),
  dateOfBirth: yup
    .string()
    .required('required')
    .test('dob', 'shouldBeGreaterThan18', function (dateOfBirth) {
      if (dateOfBirth) {
        const date = new Date(dateOfBirth)
        return differenceInYears(new Date(), date) >= this.parent.ageLimit
      } else {
        return true
      }
    })
    .nullable(),
  password: yup
    .string()
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .test('password check', ``, function (value) {
      const message = []
      if (value) {
        if (value.length < 8) {
          message.push('greaterThanOrEqualTo8Characters')
        }

        if (value.length > 25) {
          message.push('lessThanOrEqualTo25Characters')
        }

        if (value.match(/(\s)/g)) {
          message.push('blankSpacesAreNotAllowed')
        }

        if (!value.match(/[0-9]/g)) {
          message.push('atleastOneNumber')
        }

        if (!value.match(/[A-Z]/g)) {
          message.push('atleastOneUpperCaseLetter')
        }

        if (!value.match(/[a-z]/g)) {
          message.push('atleastOneLowerCaseLetter')
        }

        if (!value.match(/[!@#$%^&*]/g)) {
          message.push('atleastOneSpecialSymbol')
        }
      } else {
        return true;
      }
      if (message.length > 0) {
        return this.createError({ message: message.toString() })
      } else {
        return true
      }
    }),
})

export const appleSignUpValidationSchema = yup.object().shape({
  ageLimit: yup.number(),
  firstName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  lastName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  gender: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  dateOfBirth: yup
    .string()
    .required('required')
    .test('dob', 'shouldBeGreaterThan18', function (dateOfBirth) {
      if (dateOfBirth) {
        const date = new Date(dateOfBirth)
        return differenceInYears(new Date(), date) >= this.parent.ageLimit
      } else {
        return true
      }
    })
    .nullable(),
})

export const signUpValidationSchema_mobile = yup.object().shape({
  ageLimit: yup.number(),
  firstName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  lastName: yup
    .string()
    .matches(/^(\s+\S+\s*)*(?!\s).*$/, 'required')
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  email: emailSchema,
  gender: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  phoneNumber: yup
    .mixed()
    .transform(value => (!value ? null : value))
    .required('required'),
  dateOfBirth: yup
    .string()
    .required('required')
    .test('dob', 'shouldBeGreaterThan18', function (dateOfBirth) {
      if (dateOfBirth) {
        const date = new Date(dateOfBirth)
        return differenceInYears(new Date(), date) >= this.parent.ageLimit
      } else {
        return true
      }
    })
    .nullable(),
  password: yup
    .string()
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .test('password check', ``, function (value) {
      const message = []
      if (value) {
        if (value.length < 8) {
          message.push('greaterThanOrEqualTo8Characters')
        }

        if (value.length > 25) {
          message.push('lessThanOrEqualTo25Characters')
        }

        if (value.match(/(\s)/g)) {
          message.push('blankSpacesAreNotAllowed')
        }

        if (!value.match(/[0-9]/g)) {
          message.push('atleastOneNumber')
        }

        if (!value.match(/[A-Z]/g)) {
          message.push('atleastOneUpperCaseLetter')
        }

        if (!value.match(/[a-z]/g)) {
          message.push('atleastOneLowerCaseLetter')
        }

        if (!value.match(/[!@#$%^&*]/g)) {
          message.push('atleastOneSpecialSymbol')
        }
      } else {
        return true;
      }
      if (message.length > 0) {
        return this.createError({ message: message.toString() })
      } else {
        return true
      }
    }),
})


export const phoneNumberValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .transform(value => ('+' + value))
    // @ts-ignore
    .phone(null, null, 'enterValidCountryCodeAndPhoneNo')
    .required('required'),
})

export const inviteValidationSchema = yup.object().shape({
  inviteEmail: emailSchema,
})

export const settingsValidationSchema = yup.object().shape({
  currency: yup.string().required('required'),
  timezone: yup.string().required('required'),
  timezoneUtc: yup.string().required('required'),
  onlineList: yup.boolean().required('required'),
  allowMessage: yup.boolean().required('required'),
  allowFreeConnection: yup.boolean().required('required'),
  allowVideo: yup.boolean().required('required'),
  gstin: yup
  .string()
  .test(
    'is-valid-gstin',
    'Invalid GSTIN format',
    (value) => {
      if (!value) {
        return true;
      } else {
        return /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/.test(value);
      }
    }
  ),
})

export const blueTickValidationSchema = yup.object().shape({
  reason: yup
    .string()
    .max(800, "reasonCanBeMax800CharactersLong")
    .required("required")
    .trim(),
  attachment: yup
    .array()
    .nullable()
    .default([])
    .of(
      yup
        .object()
        .nullable()
        .shape({
          type: yup
            .string()
            .oneOf(["image/png", "image/jpeg", "image/jpg"], "Invalid file format")
            .required("File type is required"),
        })
    )
    .min(1, "atleastOneFileIsRequired"),
});

export const paymentScreenValidationSchema = yup.object().shape({
  data: yup
    .array(
      yup.object().shape({
        email: yup.string().email('invalidEmail')
      })
    ).test('atleastone', 'required', function (emailFields) {
      // @ts-ignore
      const isEmailAvailable = emailFields.some(emailField => emailField.email)
      if (isEmailAvailable)
        return true
      return false
    })
    // @ts-ignore
    .unique('email', 'youHaveEnteredSameEmailIdTwice'),
})

export const reportValidationSchema = yup.object().shape({
  reason: yup
    .string()
    .trim()
    .max(800, 'reviewShouldBeMax800Characters')
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const replyValidationSchema = yup.object().shape({
  reply: yup
    .string()
    .required('required')
    .trim()
    .max(500, 'feedbackReplyCanBeMax500CharactersLong'),
})

export const attendingEventsValidationSchema = yup.object().shape({
  // @ts-ignore
  meetingRequestOption: yup.string().test('meetingRequestOption', 'required02', function (value) {
    return true
  }),
})

export const rejectReasonValidationSchema = yup.object().shape({
  reason: yup
    .string()
    .max(500, 'reasonCanBeMax500CharactersLong')
    .trim()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),

})

export const razorPayAccountDetailsValidationSchema = yup.object().shape({
  account_name: yup
    .string()
    .required('accountNameIsRequired')
    .matches(/^[A-Za-z ]+$/, 'accountNameShouldContainOnlyLetters')
    .max(25, 'accountNameCanBeMax25CharactersLong')
    .trim(),
  business_name: yup
    .string()
    .required('businessNameIsRequired')
    .trim()
    .transform((curr, orig) => (orig === null ? '' : curr)),
  beneficiary_name: yup
    .string()
    .required('beneficiaryNameIsRequired')
    .matches(/^[A-Za-z ]+$/, 'beneficiaryNameShouldContainOnlyLetters')
    .trim(),
  ifsc_code: yup
    .string()
    .required('IFSC code is required')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'IFSCCodeShouldBeValid')
    .length(11, 'IFSC code must be exactly 11 characters long')
    .trim(),
  account_number: yup
    .string()
    .required('accountNumberIsRequired')
    .matches(/^[0-9]+$/, 'accountNumberShouldContainOnlyNumericCharacters')
    .max(16, 'accountNumberCanBeMax16Digits')
    .trim(),
})

// function validateDateAndTimeRequired(startDateTime, endDateTime) {
//   if ((startDateTime && endDateTime) || (!startDateTime && !endDateTime)) {
//     return true
//   }

//   return false
// }

// function validateDuplicateDate(startAt1DateTime, endAt1DateTime, startAt2DateTime, endAt2DateTime) {
//   if (startAt1DateTime && startAt2DateTime && endAt1DateTime && endAt2DateTime) {
//     const isWithinRange = isRangeBetween(
//       [new Date(startAt1DateTime), new Date(endAt1DateTime)],
//       [new Date(startAt2DateTime), new Date(endAt2DateTime)],
//     );

//     if (isWithinRange) {
//       return false
//     }
//   }

//   return true
// }



// @ts-ignore
function validateFutureDate(startDateTime) {
  if (startDateTime) {
    return isBefore(new Date(), new Date(startDateTime))
  }

  return true
}

// @ts-ignore
function validateEndTime(startDateTime, endDateTime) {
  if (startDateTime && endDateTime) {
    const duration = getTimeDifference(startDateTime, endDateTime);

    if (duration < 0) {
      return false
    }
  }

  return true
}

// @ts-ignore
function validateSameTime(startAt1DateTime, endAt1DateTime) {
  if (startAt1DateTime && endAt1DateTime) {
    const duration = getTimeDifference(startAt1DateTime, endAt1DateTime);

    if (duration === 0) {
      return false
    }
  }

  return true
}

export const anonymousProfileValidationSchema = yup.object().shape({
  catchPhrase: yup
    .string()
    .trim()
    .max(150, 'catchPhraseMustBeAtMost150Characters')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),

  anonymous: yup.boolean().nullable(),
  nickName: yup
    .string().trim()
    .max(25, 'nickNameCanBeMax25CharactersLong')
    .test('is required', 'required', function (nickName) {
      if (this.parent.anonymous && !nickName) {
        return false
      }
      return true
    })
    .transform((curr, orig) => (orig === null ? '' : curr)),
})

export const userInfoValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .max(20, 'firstNameCanBeMax20CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),
  lastName: yup
    .string()
    .trim()
    .max(20, 'lastNameCanBeMax20CharactersLong')
    .transform((curr, orig) => (orig === null ? '' : curr))
    .required('required'),
  email: emailSchema,
  gender: yup
    .string()
    .required('required')
    .transform((curr, orig) => (orig === null ? '' : curr)),
  selfIntro: yup
    .string()
    .max(1500, 'introductionCanBeMax1500CharactersLong')
    .nullable(),
  ageLimit: yup.number(),
  dateOfBirth: yup
    .string()
    .test('dob', 'shouldBeGreaterThan18', function (dateOfBirth) {
      if (dateOfBirth) {
        const date = new Date(dateOfBirth)
        return differenceInYears(new Date(), date) >= this.parent.ageLimit
      } else {
        return true
      }
    })
    .required('required')
    .nullable(),
  facebookName: yup
    .string()
    .trim()
    .nullable(),
  twitterName: yup
    .string()
    .trim()
    .nullable(),
  instagramName: yup
    .string()
    .trim()
    .nullable(),
  linkedInName: yup
    .string()
    .trim()
    .nullable(),
  city: yup
    .string()
    .required('required')
    .trim()
    .transform((curr, orig) => (orig === null ? '' : curr)),
  phoneNumber: yup
    .string()
    .transform(value => ('+' + value))
    // @ts-ignore
    .phone(null, null, 'enterValidCountryCodeAndPhoneNo')
    .required('required')
})
