/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { allOthersApi } from "../Services/allOthersApi";
import { useGetCognitoKeysQuery } from "../Services/envApi";
import { preferenceApi } from "../Services/preferenceApi";
import { useGetSettingValue } from "../Services/settingReducer";
import { changeCognitoModelObject } from "../Services/signUpReducer";
import { changeToken } from "../Services/tokenReducer";
import { useCreateUserSSOMutation, userApi } from "../Services/userApi";
import { changeCurrentUserDetail } from "../Services/userReducer";
import { useAppDispatch } from "../Store/hooks";
import { User, Verified } from "../models/user.model";
import { userPolicyAgreementsApi } from "../Services/userPolicyAgreementsApi";

/**
 * Custom hook to handle login success logic.
 * 
 * Manages the state and side effects related to user authentication and policy agreements.
 * It fetches Cognito keys, user preferences, and user details; updates the Redux store
 * with user information; handles Single Sign-On (SSO) identification; and ensures
 * user policy agreements are checked post-login.
 * 
 * @returns {Object} An object containing:
 * - loading: A boolean indicating if the data is loading.
 * - navigateScreenSignIn: A boolean to control navigation on sign-in.
 * - cognitoKeys: Data for Cognito keys.
 * - linkData: Web link settings value.
 * - getUser: Function to fetch and process user data.
 * - isSSoUser: A boolean indicating if the user is an SSO user.
 * - userPolicyAgreementsData: Data regarding user policy agreements.
 * - userPolicyAgreementsLoading: A boolean indicating if user policy agreements are loading.
 */
export const useLoginSuccessfulHook = () => {
  const { data: cognitoKeys, isSuccess: cognitoKeysSuccess } = useGetCognitoKeysQuery();
  const linkData = useGetSettingValue("WEB_LINK");
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [navigateScreenSignIn, setNavigateScreenSignIn] = useState(false);
  const [isSSoUser, setIsSSoUser] = useState<boolean>(false);
  const [userPolicyAgreementsData, setUserPolicyAgreementsData] = useState(false);
  const [userPolicyAgreementsLoading, setUserPolicyAgreementsLoading] = useState(false);

  /**
   * Checks if the given username is an SSO user.
   * @param {string} name - The username to check.
   * @returns {void}
   */
  const handleSSOUserName = (name: string) => {
    setIsSSoUser(name.includes("google"));
  };

  /**
   * Fetches user policy agreements and updates the state accordingly.
   * @returns {void}
   */
  const fetchUserPolicyAgreements = async () => {
    setUserPolicyAgreementsLoading(true);
    try {
      const policyData = await dispatch(userPolicyAgreementsApi.endpoints.checkUserTermsPolicy.initiate()).unwrap();
      setUserPolicyAgreementsData(policyData);
    } catch (error) {
      console.error("Failed to fetch user policy agreements:", error);
    } finally {
      setUserPolicyAgreementsLoading(false);
    }
  };

  /**
   * Fetches the current user's data from AWS Cognito and updates the store accordingly.
   * This function is used when the user is already logged in and the app is being loaded.
   * @returns {void}
   */

  const getUser = async () => {
    try {
      const resultCurrent = await Auth.currentAuthenticatedUser();
      const userData: { [key: string]: any; address?: any; birthdate?: any; email?: any; phone_number?: any } = {
        address: resultCurrent.signInUserSession.idToken.payload.address?.formatted,
        birthdate: resultCurrent.signInUserSession.idToken.payload.birthdate,
        email: resultCurrent.signInUserSession.idToken.payload.email,
        phone_number: resultCurrent.signInUserSession.idToken.payload.phone_number,
      };

      Object.keys(userData).forEach((key) => {
        if (userData[key] === null) delete userData[key];
      });

      const model = {} as User;
      model.firstName = resultCurrent.signInUserSession.idToken.payload.given_name;
      model.lastName = resultCurrent.signInUserSession.idToken.payload.family_name;
      model.token = resultCurrent.signInUserSession.idToken.payload.email;
      model.sub = resultCurrent.signInUserSession.idToken.payload.sub;
      model.emailVerified = resultCurrent.signInUserSession.idToken.payload.email_verified
        ? Verified.Complete
        : Verified.NotComplete;

      const res = await createUserSSO(model).unwrap();
      const accessToken = resultCurrent.signInUserSession.accessToken.jwtToken;
      await dispatch(changeToken(accessToken));
      resultCurrent.attributes = userData;

      await Promise.all([
        dispatch(preferenceApi.endpoints.getUserPreference.initiate()).unwrap(),
        dispatch(userApi.endpoints.getCurrentUserDetails.initiate()).unwrap(),
        dispatch(allOthersApi.endpoints.getAllCurrency.initiate()).unwrap(),
      ]);

      const userDetail = await dispatch(userApi.endpoints.getUserDetail.initiate(res.sub)).unwrap();
      await dispatch(changeCognitoModelObject(userDetail));
      handleSSOUserName(userDetail.username);
      await dispatch(changeCurrentUserDetail(resultCurrent.attributes));

      // Fetch user policy agreements after all critical data is loaded
      fetchUserPolicyAgreements();
    } catch (error) {
      console.error("Error in getUser:", error);
      if (!navigateScreenSignIn) {
        setNavigateScreenSignIn(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const [createUserSSO] = useCreateUserSSOMutation<any>();

  useEffect(() => {
    if (!cognitoKeysSuccess || !linkData) {
      setLoading(false);
    }
  }, [cognitoKeysSuccess, linkData]);

  return {
    loading,
    navigateScreenSignIn,
    cognitoKeys,
    linkData,
    getUser,
    isSSoUser,
    userPolicyAgreementsData,
    userPolicyAgreementsLoading,
  };
};
