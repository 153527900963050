/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useSearchParams} from 'react-router-dom';

/**
 * A custom React hook for generating a URL query string based on search parameters.
 *
 * It checks for the presence of 'sort' and 'page' parameters in the search query and returns a formatted query string.
 *
 * @return {string} A URL query string with 'page' and 'sort' parameters, or a default value if no parameters are present.
 */

const PageAndSortHook = () => {
  const [searchParams] = useSearchParams();

  const sort = searchParams.has('sort');
  const page = searchParams.has('page');

  const sortValue = searchParams.get('sort') ?? 'upcomingMeeting';
  const pageValue = searchParams.get('page') ?? '1';

  if (sort && page) {
    return `?page=${pageValue}&sort=${sortValue}`;
  } else if (sort) {
    return `?sort=${sortValue}`;
  } else if (page) {
    return `?page=${pageValue}`;
  } else {
    return '?page=1';
  }
};

export default PageAndSortHook;
