/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {MeetingNotificationRemainder} from '../models/meetingNotificationRemainder.model';
import {allApis} from './allApis';

/**
 * Creates a meeting notification remainder API with endpoints for creating, getting, and deleting remainders.
 *
 * @param {object} allApis - The API object used to inject endpoints.
 * @return {object} An object containing the meeting notification remainder API endpoints.
 */

export const meetingNotificationRemainderApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createRemainder: builder.mutation<
      MeetingNotificationRemainder,
      Partial<MeetingNotificationRemainder>
    >({
      query: meetingNotificationRemainder => ({
        url: `meetingNotificationRemainder`,
        method: 'POST',
        body: meetingNotificationRemainder,
      }),
      invalidatesTags: (result, error, meetingNotificationRemainder) => [
        {
          type: 'MeetingNotificationRemainder',
          id: meetingNotificationRemainder.meeting?.id,
        },
      ],
    }),

    getMeetingNotificationTime: builder.query<
      MeetingNotificationRemainder[],
      string
    >({
      query: meetingId => `meetingNotificationRemainder/${meetingId}`,
      providesTags: (result, error, meetingId) => [
        {type: 'MeetingNotificationRemainder', id: meetingId},
      ],
    }),

    deleteRemainder: builder.mutation<
      MeetingNotificationRemainder,
      {meetingNotificationRemainderId: string; meetingId: string}
    >({
      query: ({meetingNotificationRemainderId, meetingId}) => ({
        url: `meetingNotificationRemainder/${meetingNotificationRemainderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (
        result,
        error,
        {meetingNotificationRemainderId, meetingId},
      ) => [{type: 'MeetingNotificationRemainder', id: meetingId}],
    }),
  }),
});
export const {
  useGetMeetingNotificationTimeQuery,
  useDeleteRemainderMutation,
  useCreateRemainderMutation,
} = meetingNotificationRemainderApi;
