/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useSelector} from 'react-redux';
import {getCurrencyList} from '../Services/currencyListReducer';

/**
 * A custom React hook for handling currency-related operations.
 *
 * It provides functions to retrieve a currency by its code, format a number as a currency string,
 * and extract the currency symbol from a given currency code.
 *
 * @return {Object} An object containing the getCurrencyByCode, formatCurrency, and formatCurrencySymbol functions.
 */

export const useCurrencyHook = () => {
  const currenciesList = useSelector(getCurrencyList);
  // const preference = useSelector(getPreference)

  const getCurrencyByCode = (code: string) => {
    return currenciesList.find(currency => currency.code === code);
  };
  const formatCurrency = (
    amount: number | undefined,
    code: string,
    currencyNotNeeded: boolean = false,
  ) => {
    const currencyCode = code; // ?? preference?.currency;
    const currency = getCurrencyByCode(currencyCode);

    if (amount === 0 && currencyNotNeeded) {
      return '0';
    }

    if (!amount) {
      return new Intl.NumberFormat(currency?.currencyLocale, {
        style: 'currency',
        currency: currencyCode,
      })
        .format(0)
        .replace(/^(\D+)/, '$1 ');
    }

    if (currency) {
      if (!currency?.currencyLocale) {
        console.error('Currency locale not found', currencyCode);
      }

      if (currencyNotNeeded) {
        return new Intl.NumberFormat(currency?.currencyLocale).format(
          amount ?? 0,
        );
      }

      return new Intl.NumberFormat(currency?.currencyLocale, {
        style: 'currency',
        currency: currency?.code,
      })
        .format(amount ?? 0)
        .replace(/^(\D+)/, '$1 ');
    } else {
      console.error('Currency not found', currencyCode);
    }

    return amount;
  };

  const formatCurrencySymbol = (code: string) => {
    const currency = getCurrencyByCode(code);

    if (currency && currency.currencyLocale) {
      const formattedSymbol = new Intl.NumberFormat(currency.currencyLocale, {
        style: 'currency',
        currency: currency.code,
      })
        .format(0)
        .replace(/[0-9\s.,]/g, ''); // Remove digits, spaces, commas, and periods

      return formattedSymbol;
    } else {
      console.error(
        'Currency not found or currency locale not available',
        code,
      );
      return '';
    }
  };

  return {
    getCurrencyByCode,
    formatCurrency,
    formatCurrencySymbol, // This part prints only currency symbol
  };
};
