/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {allApis} from './allApis';

/**
 * Defines the API endpoints for environment variables.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getReCaptcha: query, getIPGeolocationKey: query, getCognitoKeys: query }} - An object containing the defined endpoints.
 */

export const envApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getReCaptcha: builder.query<string, void>({
      query: () => `env/reCaptcha`,
    }),
    getIPGeolocationKey: builder.query<string, void>({
      query: () => `env/ipGeolocationKey`,
    }),
    getCognitoKeys: builder.query<any, void>({
      query: () => `env/cognito`,
    }),
    getOpenAIEnvironmentConfig: builder.query<any, void>({
      query: () => `env/useOpenAI`,
    }),
    getGoogleAnalyticsId: builder.query<any, void>({
      query: () => `env/googleAnalyticsId`,
    }),
    getIntroVideoURLForSmallerScreens: builder.query<any, void>({
      query: () => 'env/introVideoURLForSmallerScreens',
    }),
    getIntroVideoURLForLargerScreens: builder.query<any, void>({
      query: () => 'env/introVideoURLForLargerScreens',
    }),

    // getZoomKeys: builder.query<any, void>({
    //     query: () => `env/zoom`
    // }),
    // getBaseKeys: builder.query<any, void>({
    //     query: () => `env/baseURL`
    // }),
  }),
});

export const {
  useGetIPGeolocationKeyQuery,
  useGetCognitoKeysQuery,
  useGetReCaptchaQuery,
  useGetGoogleAnalyticsIdQuery,
  useGetOpenAIEnvironmentConfigQuery,
  useGetIntroVideoURLForSmallerScreensQuery,
  useGetIntroVideoURLForLargerScreensQuery,
} = envApi;
