/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {MeetingNotificationList} from '../models/meetingNotficationList.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for meeting notification list API.
 *
 * @param {builder} builder - The builder object used to construct the API endpoints.
 * @return {{ endpoints: object }} An object containing the defined endpoints.
 */

export const meetingNotficationListApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAllRemainder: builder.query<MeetingNotificationList[], void>({
      query: () => `meetingNotificationList`,
      transformResponse: (response: MeetingNotificationList[]) =>
        response.sort((a, b) =>
          a.notificationTimingInMinutes > b.notificationTimingInMinutes
            ? 1
            : -1,
        ),
    }),
  }),
});
export const {useGetAllRemainderQuery} = meetingNotficationListApi;
