/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {User} from './../models/user.model';
import {UserPolicyAgreements} from '../models/userPolicyAgreements.model';
import {allApis} from './allApis';

/**
 * API endpoints for user policy agreements.
 *
 * Handles CRUD operations for user policy agreements, allowing for retrieval, creation, update, and deletion of agreements.
 *
 * @return {object} API endpoints for user policy agreements.
 */

export const userPolicyAgreementsApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    agreeEventPolicy: builder.mutation<
      UserPolicyAgreements,
      UserPolicyAgreements
    >({
      query: userPolicyAgreements => ({
        url: `userPolicyAgreements`,
        method: 'POST',
        body: userPolicyAgreements,
      }),
    }),
    createUserTerms: builder.mutation<
      UserPolicyAgreements,
      Partial<UserPolicyAgreements>
    >({
      query: userPolicyAgreements => ({
        url: `userPolicyAgreements/terms`,
        method: 'POST',
        body: userPolicyAgreements,
      }),
      invalidatesTags: ['UserPolicyAgreements'],
    }),
    createUserPrivacyPolicy: builder.mutation<
      UserPolicyAgreements,
      Partial<UserPolicyAgreements>
    >({
      query: userPolicyAgreements => ({
        url: `userPolicyAgreements/privacyPolicy`,
        method: 'POST',
        body: userPolicyAgreements,
      }),
      invalidatesTags: ['UserPolicyAgreements'],
    }),
    checkUserTermsPolicy: builder.query<boolean, void>({
      query: () => 'userPolicyAgreements/checkUserTerms',
      providesTags: ['UserPolicyAgreements'],
    }),
    checkEventCreationPolicy: builder.query<boolean, void>({
      query: () => 'userPolicyAgreements/checkEventPolicy',
    }),
    checkPrivacyPolicy: builder.query<boolean, void>({
      query: () => 'userPolicyAgreements/checkPrivacyPolicy',
    }),
    updateUserTermsPolicy: builder.mutation<User, UserPolicyAgreements>({
      query: cUser => ({
        url: `userPolicyAgreements/updateUserPolicy`,
        method: 'PUT',
        body: cUser,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    updatePrivacyPolicy: builder.mutation<User, UserPolicyAgreements>({
      query: cUser => ({
        url: `userPolicyAgreements/updatePrivacyPolicy`,
        method: 'PUT',
        body: cUser,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const {
  useCheckUserTermsPolicyQuery,
  useCreateUserTermsMutation,
  useCreateUserPrivacyPolicyMutation,
  useCheckEventCreationPolicyQuery,
  useCheckPrivacyPolicyQuery,
  useAgreeEventPolicyMutation,
  useUpdateUserTermsPolicyMutation,
  useUpdatePrivacyPolicyMutation,
} = userPolicyAgreementsApi;
