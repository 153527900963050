/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { changeRedirectUrl } from "../Services/rediretUrlReducer";
import { store } from "../Store";

/**
 * Updates the redirect URL in the state.
 *
 * This function takes a redirect URL as its argument.
 * If the URL is valid (i.e. it starts with '/s/'), it is
 * stored in the state.
 *
 * @param {string} redirectURL - The URL to store in the state.
 */
export const updateRedirectURL = (redirectURL: string) => {
    if (redirectURL && redirectURL.startsWith('/s/')) {
        store.dispatch(changeRedirectUrl(redirectURL));
    }
}

/**
 * Returns the current location as a string.
 *
 * @returns {string} The current location (window.location.pathname).
 */
export const getLocation = () => {
    return window.location.pathname
}