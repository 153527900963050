/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Field from '../../Components/base/field/field'
import { MeetingDetails } from '../../models/meetingDetails.model'

interface _EventTitleProps {
    meetingDetails: MeetingDetails | undefined
    register: any
    errors?: any
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A reusable component for displaying a title input field for a meeting.
 *
 * @param {Object} props - The component props.
 * @param {MeetingDetails | undefined} props.meetingDetails - The meeting details object, used to prefill the input field with the meeting title.
 * @param {Function} props.register - The register function from `react-hook-form`, used to register the input field with the form.
 * @param {Object} [props.errors] - The error object from `react-hook-form`, used to display any error messages.
 * @return {JSX.Element} The rendered title input field.
 */
export default function EventTitle(props: _EventTitleProps) {
    const { meetingDetails, register, errors , value, onChange} = props
    
    return (
        <div>
            <Field
                {...register('title')}
                error={errors?.title?.message}
                name="title"
                data-testid="input_title"
                id="input_title"
                type="text"
                value={value}
                onChange={onChange}
                defaultValue={meetingDetails?.title}
            />
        </div>
    )
}