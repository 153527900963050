/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Avatar from "../../../Components/base/avatar/avatar";
import { getCurrentUser } from "../../../Services/userReducer";
import { useAppSelector } from "../../../Store/hooks";

/**
 * Displays the user's anonymous profile.
 *
 * The component renders the user's avatar and nickname
 * and if the user has a catch phrase, it will also be displayed.
 *
 * @returns {React.ReactElement} The user's anonymous profile component.
 */
const UserAnonymous = () => {
  const currentUser = useAppSelector(getCurrentUser);

  return (
    <div className="flex flex-col gap-y-2 mt-10 justify-center items-center leading-none max-w-[40rem]">
      <Avatar avatar={currentUser.avatar} anonymous={true} size="large" />
      <div className="flex flex-col justify-center items-center gap-y-3">
        <div
          id="userNickName"
          data-testid="userNickName"
          className="font-bold text-xl mt-2"
        >
          {currentUser?.nickName && currentUser.nickName.length > 15
            ? `${currentUser.nickName.slice(0, 15)}...`
            : currentUser.nickName}
        </div>
        {currentUser?.catchPhrase && <div id="userCatchPhrase" data-testid="userCatchPhrase" className="font-normal text-md text-center leading-[1.3rem]">{`"${currentUser.catchPhrase}"`}</div>}
      </div>
    </div>
  )
}

export default UserAnonymous;