/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { allApis } from './allApis';

export const openaiApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createImage: builder.mutation<any, string>({
      query: (imagePrompt) => {
        console.log('Image prompt:', imagePrompt);
        return {
          url: `openai/generate-image`,
          method: 'POST',
          body: { imagePrompt },
          invalidatesTags: ['OpenAIImages']
        };
      }
    }),
    getOpenAIImages: builder.query<any, void>({
      query: () => `openai/avatarNameList`,
      providesTags: ['OpenAIImages'],
    }),
  }),
});

export const { useCreateImageMutation, useGetOpenAIImagesQuery } = openaiApi;
