/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { Taxes } from '../models/taxes.model';
import {allApis} from './allApis';

/*
* Taxes API 
* 
*  @param {object} builder - The builder object used to define the endpoints.
*  @return {{ getTaxes: query }} - An object containing the defined endpoints.
*/

export const taxApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTaxes: builder.query<Taxes[], void>({
      query: () => 'taxes',
      
    }),
    
  }),
});
export const {useGetTaxesQuery} = taxApi;

