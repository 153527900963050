/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {logout} from '../API/logoutAWS';
import {RootState} from '../Store';

export const awsLogout = createAsyncThunk('logout', async thunkAPI => {
  return await logout();
});

const initialStateValue = '';

/**
 * Creates a slice of the Redux state for managing the authentication token.
 *
 * @return {Object} The tokenSlice object containing reducers and actions for managing the token state.
 *
 * @property {string} value - The authentication token value.
 *
 * @action changeToken - Updates the authentication token value.
 * @action logoutToken - Resets the authentication token to its initial value.
 */

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    changeToken: (state, action) => {
      state.value = action.payload;
    },
    logoutToken: state => {
      state.value = initialStateValue;
    },
  },
  extraReducers: builder => {
    builder.addCase(awsLogout.fulfilled, (state, action) => {
      state.value = initialStateValue;
    });
  },
});

export const getToken = (state: RootState): boolean => state.token?.value;

export const {changeToken, logoutToken} = tokenSlice.actions;
export default tokenSlice.reducer;
