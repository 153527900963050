/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useAppSelector} from './../Store/hooks';
import {useEffect, useState} from 'react';
import {isSocketConnected} from '../Services/socketReducer';
import {getCurrentUser} from '../Services/userReducer';
import {getOnlineUsers} from '../Services/onlineUsersReducer';
import {useGetByIdQuery} from '../Services/userApi';
import {socketClient} from '../API/socketio';

/**
 * A custom React hook to check the online status of a user.
 *
 * @param {string} id - The ID of the user to check the online status for.
 * @return {{ isOnline: boolean | null }} An object containing the online status of the user.
 */

const useCheckOnlineHook = (id: string) => {
  let interval: any;
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const socketConnected = useAppSelector(isSocketConnected);
  const currentUser = useAppSelector(getCurrentUser);
  const onlineUsers = useAppSelector(getOnlineUsers);

  const {data: userData} = useGetByIdQuery(id);

  useEffect(() => {
    if (id && socketConnected && currentUser) {
      checkStatus();
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, socketConnected, currentUser]);

  useEffect(() => {
    if (onlineUsers && onlineUsers.length > 0 && userData) {
      if (userData.onlineList) {
        const user = onlineUsers.find(value => value.userId === id);
        if (user) {
          setIsOnline(user.isOnline);
        }
      } else {
        setIsOnline(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineUsers, userData]);

  const checkStatus = () => {
    socketClient.getUserStatus({fromUserId: currentUser?.id, userId: id});
    interval = setInterval(() => {
      socketClient.getUserStatus({fromUserId: currentUser?.id, userId: id});
    }, 10000);
  };

  return {isOnline};
};
export default useCheckOnlineHook;
