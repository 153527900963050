/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { MeetingRequest } from './meetingRequest.model';
import { CommonEntity } from './commonEntity.model';
import { Invite } from './invite.model';
import { Ledger } from './ledger.model';
import { Meeting } from './meeting.model';
import { User } from './user.model';
import { CancellationRequest } from './cancellationRequest.model';

export enum Joined {
    Yes = 'Yes',
    No = 'No'
}

export enum InviteStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
}

export interface MeetingParticipant extends CommonEntity {
    meeting: Meeting;
    user: User;
    transaction: Ledger;
    joined: Joined;
    invitedUser: Invite;
    paidBy: User;
    inviteStatus: InviteStatus;
    meetingRequest: MeetingRequest;
    checkCancellation: boolean;
    display_createdAt: string;
    isCohost: boolean;
    cancelationRequest: CancellationRequest;
}