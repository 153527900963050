/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {ChatMessage} from './../Store/Chat/ChatModel';
import {Chat} from '../models/chat.model';
import {Pagination} from './../models/pagination.model';
import {allApis} from './allApis';

/**
 * Creates a message API with endpoints for managing messages, including creating, reading, and updating messages.
 *
 * @return {object} An object containing the message API endpoints.
 */

export const messageApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    // create: builder.mutation<Chat, Partial<Chat>>({
    //     query: (message) => {
    //         return {
    //             url: `message`,
    //             method: 'POST',
    //             message
    //         };
    //     }
    // }),
    getMessagesByUser: builder.query<Pagination<Chat>, any>({
      query: args => {
        const {userId, limit, skip, seenOnly} = args;
        return `message/${userId}/${limit}/${skip}/?seenOnly=${seenOnly}`;
      },
    }),
    getUnReadMessageCount: builder.query<number, null>({
      query: () => `message/count/unReadMessage`,
      providesTags: ['UnreadChatCount'],
    }),
    getMessagesByMeetingRequestId: builder.query<Chat, string>({
      query: meetingRequestId => `message/meetingRequest/${meetingRequestId}`,
    }),
    getAllChatUsers: builder.query<any[], void>({
      query: () => `message/all/users`,
    }),
    getTodayMeetingRequestCount: builder.query<number, void>({
      query: () => `message/count/todayMeetingRequest`,
      providesTags: ['VideoRequest'],
    }),

    updateSeen: builder.mutation<ChatMessage, Partial<ChatMessage>>({
      query: message => {
        return {
          url: `message/${message.messageId}`,
          method: 'PUT',
          body: message,
        };
      },
      invalidatesTags: ['UnreadChatCount'],
    }),

    // uploadUserImage: builder.mutation<Image, any>({
    //   query: args => {
    //     const {file} = args;
    //     let data: FormData = new FormData();
    //     data.append('file', file);

    //     return {
    //       url: `image/file/upload/id`,
    //       method: 'POST',
    //       body: data,
    //     };
    //   },
    // }),

    uploadFile: builder.mutation<any, { messageId: string; files: any; fromUser: any; toUser: any }>({
      query: ({messageId, files, fromUser, toUser}) => {
        const formData = new FormData();
        formData.append('files', files);
        return {
          url: `message/upload/${messageId}/${fromUser.id}/${toUser.id}`,
          method: 'POST',
          body: formData,
        };
      }
    }),

    getUploadedFile: builder.query<any, string>({
      query: messageId => `message/getFile/${messageId}`,
    }),
    
  }),
});

export const {
  useGetMessagesByUserQuery,
  useGetUnReadMessageCountQuery,
  useGetMessagesByMeetingRequestIdQuery,
  useGetAllChatUsersQuery,
  useGetTodayMeetingRequestCountQuery,
  useUpdateSeenMutation,
  useUploadFileMutation,
  useGetUploadedFileQuery,
} = messageApi;
