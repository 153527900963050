/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

//22_03_2022
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Components'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '../../Components/base/modal/modal'
import useToggle from '../../Components/_utils/useToggle'
import { useNavigate, useParams } from 'react-router-dom'
import { useEventDetailsHook } from '../../Hooks/MyEvents/EventDetailsHook'
import { toastError, toastSuccess } from '../../Components/toast'
import { getCurrentUser } from '../../Services/userReducer'
import { PaymentAccountActive } from '../../models/user.model'
import { useSelector } from 'react-redux'
import BeemgSwitch from '../MyEventsDetails/BeemgSwitch'
import EventTitle from '../MyEventsDetails/EventTitle'
import MeetingCategories from '../MyEventsDetails/MeetingCategories'
import ReactQuillEditor from '../MyEventsDetails/ReactQuillEditor'
import { ViewImage } from './ViewImage'
import { useGetPastScheduledMeetingsMeetingsDetailIdQuery } from '../../Services/meetingApi'


/**
 * EventDetails is a component that handles the logic for creating and editing an event.
 * It makes use of the useEventDetailsHook to fetch and update the event details.
 * The component also makes use of the useGetPastScheduledMeetingsMeetingsDetailIdQuery from the meetingApi to fetch the past meetings of the event.
 * The event details are displayed in a form with input fields for the title, description, kid friendly, kids only, host anonymously, display as audio only, and free audio meeting.
 * The component also displays the cancellation policy of the event.
 * The user can edit the event details and save the changes.
 * The component also handles the deletion of the event.
 * The component checks if the user has an anonymous profile and shows a button to update the anonymous profile if the user is not logged in.
 */
const EventDetails = () => {
  const { t } = useTranslation()
  const { meetingDetailId } = useParams()
  const { toggle: deleteToggle, isOpen: deleteisOpen } = useToggle()

  const currentUser = useSelector(getCurrentUser)
  const refFrom = useRef(null)
  const navigate = useNavigate()
  const {
    meetingCategory,
    meetingCategoryList,
    meetingCategoryListSuccess,
    showOtherCategory,
    setShowOtherCategory,
    meetingDetails,
    onMeetingSubmit,
    handleMeetingDelete,
    updatedMeetingDetailsSuccess,
    handleAddCategoryList,
    handleRemoveCategory,
    register,
    handleSubmit,
    submitted,
    ShowAnonymous,
    setShowAnonymous,
    setValue,
    errors,
    watch,
    getValues,
    isLoading,
  } = useEventDetailsHook({ meetingDetailId: meetingDetailId ?? '' })

  const {
    data: meetings,
    isError,
    isSuccess,
  } = useGetPastScheduledMeetingsMeetingsDetailIdQuery(meetingDetailId ?? '', {
    skip: meetingDetailId === undefined,
  })

  const [pastMeetings, setPastMeetings] = useState<boolean>(false)

  useEffect(() => {
    if (isSuccess && meetings && meetings?.length > 0) {
      setPastMeetings(true)
    } else if (isError) {
      setPastMeetings(false)
    }
  }, [meetings, pastMeetings, isSuccess, isError])

  //TODO need to check handleMeetingDelete response
  useEffect(() => {
    if (meetingDetails) {
      setValue('display_isStreaming', meetingDetails.display_isStreaming)
      setValue('display_isAudioMeeting', meetingDetails.display_isAudioMeeting)
      setValue('isFreeAudioMeeting', meetingDetails.isFreeAudioMeeting)
      setValue('cancellationPolicy', meetingDetails.cancellationPolicy)
    }
  }, [meetingDetails])

  useEffect(() => {
    if (currentUser.paymentAccountActive === PaymentAccountActive.No) {
      setValue('display_isAudioMeeting', true)
      setValue('isFreeAudioMeeting', true)
    }
  }, [currentUser])


  useEffect(() => {
    updatedMeetingDetailsSuccess && toastSuccess(t('eventUpdatedSuccessfully'))
  }, [updatedMeetingDetailsSuccess, t])

  return (
    <>
      <form ref={refFrom} onSubmit={handleSubmit(onMeetingSubmit)}>
        <div>
          <div className='flex flex-col md:flex-row gap-x-3'>
            <div className='flex flex-col pt-1 pl-1'>
              <EventTitle
                meetingDetails={meetingDetails}
                register={register}
                errors={errors}
              />
              <div className="h-4" />
              <div className="text-lg font-semibold leading-none">
                {t('eventRequirements')}
              </div>
              <div className="h-4" />
              <div className="md:grid md:grid-cols-2 sm:grid-cols-1 sm:grid sm:gap-x-2 sm:gap-y-2">
                {Array(6)
                  .fill(null)
                  .map((item, index) => (
                    <input
                      key={index}
                      type="text"
                      {...register(`helpsList.${index}.help` as const)}
                      name={`helpsList[${index}].help`}
                      maxLength={20}
                      // defaultValue={`${item.help}`}
                      placeholder={t('addRequirements') + ` ${index + 1}`}
                      id={`addHelp_${index + 1}`}
                      data-testid={`addRequirements_${index + 1}`}
                      className="leading-5 text-sm relative flex flex-1 w-full rounded-sm p-input text-gray-400 placeholder:text-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8"
                      onChange={(e) => {
                        e.target.value = e.target.value.trimStart();
                      }}
                    />
                  ))}
              </div>
              {/* beemgSwitch */}
              <div className="flex flex-col mt-2 w-full">
                {/* youngnest */}
                <BeemgSwitch
                  errors={errors?.kidFriendly?.message}
                  register={register}
                  checked={meetingDetails?.kidFriendly}
                  htmlFor={meetingDetails?.id ? undefined : 'kidFriendly'}
                  beemgSwitchText={'youngnest'}
                  name="kidFriendly"
                  registerValue="kidFriendly"
                  onClick={(e: any) => {
                    setValue('kidFriendly', e.target.checked ? true : false)
                  }}
                  toolTipText={t('suitableForAgeGroupsFrom6-12&AdultsAsWell')}
                />

                {/* littlescape */}
                <BeemgSwitch
                  errors={errors?.kidsOnly?.message}
                  register={register}
                  checked={meetingDetails?.kidsOnly}
                  htmlFor={meetingDetails?.id ? undefined : 'kidsOnly'}
                  beemgSwitchText={'littlescape'}
                  name="kidsOnly"
                  registerValue="kidsOnly"
                  onClick={(e: any) => {
                    setValue('kidsOnly', e.target.checked ? true : false)
                    setValue('kidFriendly', e.target.checked ? true : false)
                  }}
                  toolTipText={t('suitableForAgeGroupsFrom6-12')}
                />

                {/* autoDelete */}
                <BeemgSwitch
                  errors={errors?.autoDelete?.message}
                  register={register}
                  checked={meetingDetails?.autoDelete}
                  htmlFor={'autoDelete'}
                  beemgSwitchText={'autoDelete'}
                  name="autoDelete"
                  registerValue="autoDelete"
                  onClick={(e: any) => {
                    setValue('autoDelete', e.target.checked ? true : false)
                  }}
                  toolTipText={t('autoDeleteOnMinimumLimitNotReached')}
                />

                {/* hostAnonymously */}
                <BeemgSwitch
                  errors={errors?.isAnonymous?.message}
                  register={register}
                  checked={meetingDetails?.isAnonymous}
                  htmlFor={
                    meetingDetails?.id ? undefined : 'input_hostAnonymously'
                  }
                  beemgSwitchText={'hostAnonymously'}
                  name="isAnonymous"
                  registerValue="isAnonymous"
                  onClick={(e: any) => {
                    setValue('isAnonymous', e.target.checked ? true : false)
                    if (e.target.checked === true && !currentUser.anonymous) {
                      setShowAnonymous(true)
                    } else {
                      setShowAnonymous(false)
                    }
                  }}
                  showAnonymous={ShowAnonymous}
                />
                {ShowAnonymous && (
                  <Button
                    size="sm"
                    rounded
                    color="join"
                    onClick={() => navigate('/s/profile', { state: { isAnon: true } })}
                  >
                    <p
                      id="btn_anonymousProfile"
                      data-testid="btn_anonymousProfile"
                      className="text-xs md:text-sm ">
                      {t('updateAnonymousProfile')}
                    </p>
                  </Button>
                )}
                {currentUser.paymentAccountActive ===
                  PaymentAccountActive.Yes && (
                    <BeemgSwitch
                      errors={errors?.display_isAudioMeeting?.message}
                      register={register}
                      checked={meetingDetails?.display_isAudioMeeting}
                      htmlFor={
                        meetingDetails?.id ? undefined : 'display_isAudioMeeting'
                      }
                      beemgSwitchText={'makeThisEventAsAudioOnly'}
                      name="display_isAudioMeeting"
                      registerValue="display_isAudioMeeting"
                      onClick={(e: any) => {
                        setValue('display_isAudioMeeting', e.target.checked)
                        setValue('isFreeAudioMeeting', e.target.checked)
                      }}
                      disabled={meetingDetails?.id ? true : false}
                    />
                  )}
                {currentUser.paymentAccountActive === PaymentAccountActive.No && (
                  <BeemgSwitch
                    errors={errors?.display_isAudioMeeting?.message}
                    register={register}
                    checked={
                      meetingDetails?.display_isAudioMeeting
                        ? meetingDetails?.display_isAudioMeeting
                        : true
                    }
                    htmlFor={
                      meetingDetails?.id ? undefined : 'display_isAudioMeeting'
                    }
                    beemgSwitchText={'makeThisEventAsAudioOnly'}
                    name="display_isAudioMeeting"
                    registerValue="display_isAudioMeeting"
                    onClick={(e: any) => {
                      setValue('display_isAudioMeeting', e.target.checked)
                      setValue('isFreeAudioMeeting', e.target.checked)
                    }}
                    disabled={
                      meetingDetails?.id
                        ? true
                        : false ||
                        currentUser.paymentAccountActive ===
                        PaymentAccountActive.No
                    }
                  />
                )}

                {currentUser.paymentAccountActive === PaymentAccountActive.Yes && (
                  <BeemgSwitch
                    errors={errors?.display_isStreaming?.message}
                    register={register}
                    checked={meetingDetails?.display_isStreaming}
                    htmlFor={meetingDetails?.id ? undefined : 'display_isStreaming'}
                    beemgSwitchText={'makeThisEventStreaming'}
                    name="display_isStreaming"
                    registerValue="display_isStreaming"
                    onClick={(e: any) => {
                      setValue('display_isStreaming', e.target.checked);
                    }}
                    disabled={meetingDetails?.id ? true : false}
                  />
                )}

                {currentUser.paymentAccountActive === PaymentAccountActive.No && (
                  <BeemgSwitch
                    errors={errors?.display_isStreaming?.message}
                    register={register}
                    checked={
                      meetingDetails?.display_isStreaming
                        ? meetingDetails?.display_isStreaming
                        : true
                    }
                    htmlFor={meetingDetails?.id ? undefined : 'display_isStreaming'}
                    beemgSwitchText={'makeThisEventStreaming'}
                    name="display_isStreaming"
                    registerValue="display_isStreaming"
                    onClick={(e: any) => {
                      setValue('display_isStreaming', e.target.checked);
                    }}
                    disabled={
                      meetingDetails?.id
                        ? true
                        : false ||
                        currentUser.paymentAccountActive === PaymentAccountActive.No
                    }
                  />
                )}


                {/* Show free event checkbox */}
                {(watch('display_isAudioMeeting') ||
                  getValues('display_isAudioMeeting')) && (
                    <>
                      {currentUser.paymentAccountActive ===
                        PaymentAccountActive.Yes && (
                          <BeemgSwitch
                            errors={errors?.isFreeAudioMeeting?.message}
                            register={register}
                            checked={meetingDetails?.isFreeAudioMeeting}
                            htmlFor={
                              meetingDetails?.id ? undefined : 'isFreeAudioMeeting'
                            }
                            beemgSwitchText={'isFreeAudioMeeting'}
                            name="isFreeAudioMeeting"
                            registerValue="isFreeAudioMeeting"
                            onClick={(e: any) =>
                              setValue('isFreeAudioMeeting', e.target.checked)
                            }
                            disabled={meetingDetails?.id ? true : false}
                          />
                        )}
                    </>
                  )}

                {currentUser.paymentAccountActive === PaymentAccountActive.No && (
                  <BeemgSwitch
                    errors={errors?.isFreeAudioMeeting?.message}
                    register={register}
                    checked={
                      meetingDetails?.isFreeAudioMeeting
                        ? meetingDetails?.isFreeAudioMeeting
                        : true
                    }
                    htmlFor={
                      meetingDetails?.id ? undefined : 'isFreeAudioMeeting'
                    }
                    beemgSwitchText={'isFreeAudioMeeting'}
                    name="isFreeAudioMeeting"
                    registerValue="isFreeAudioMeeting"
                    onClick={(e: any) =>
                      setValue('isFreeAudioMeeting', e.target.checked)
                    }
                    disabled={
                      meetingDetails?.id
                        ? true
                        : false ||
                        currentUser.paymentAccountActive ===
                        PaymentAccountActive.No
                    }
                  />
                )}
              </div>
            </div>

            <div className='flex flex-col flex-1 pt-1'>
              <div className=" flex flex-col">
                <ReactQuillEditor
                  meetingDetails={meetingDetails}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
              </div>

              <div className='mt-4 text-red-600'>
                {meetingDetails && meetingDetails.cancellationPolicy && (
                  <div className='font-semibold'>{t('cancellationPolicy')} {': '}{meetingDetails.cancellationPolicy.policyType}</div>
                )}
              </div>

              {/* MeetingCategories */}

              <MeetingCategories
                meetingCategory={meetingCategory}
                handleRemoveCategory={handleRemoveCategory}
                meetingCategoryListSuccess={meetingCategoryListSuccess}
                showOtherCategory={showOtherCategory}
                register={register}
                getValues={getValues}
                setValue={setValue}
                handleAddCategoryList={handleAddCategoryList}
                meetingCategoryList={meetingCategoryList}
                setShowOtherCategory={setShowOtherCategory}
                watch={watch}
              />
            </div>
          </div>

          <ViewImage />

          <div>
            <div className="flex md:flex-wrap md:flex md:flex-row md:justify-end gap-x-3 sm:flex sm :flex-wrap sm:flex-row sm:justify-end gap-y-2 sm:gap-y-5">
              {meetingDetails?.id && (
                <Button
                  id="btn_deleteEvent"
                  data-testid="btn_deleteEvent"
                  key={meetingDetails?.id as string}
                  size="md"
                  color="save"
                  className='text-red-500 text-sm w-46 px-8'
                  disabled={meetingDetails?.meetings.length > 0 || pastMeetings}
                  onClick={() => {
                    deleteToggle()
                  }}>
                  {t('delete')}
                </Button>
              )}
              {meetingDetails && (
                <Modal
                  isOpen={deleteisOpen}
                  toggle={deleteToggle}
                  closeOnClickOutside={false}>
                  <ModalCloseButton toggle={deleteToggle} />
                  <ModalHeader keyId="deleteEvent">{t('deleteEvent')}</ModalHeader>
                  <ModalBody>
                    <ModalBody>
                      <div className="text-red-500 text-lg font-bold text-center"
                        data-testid="dlt_ConfirmMsg">
                        {t('areYouSureToDeleteThisEvent')} <br /> {`" `}
                        {meetingDetails?.title}
                        {` "`} ?
                      </div>
                      <br />
                    </ModalBody>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      id="btn_cancel"
                      data-testid="btn_cancel"
                      onClick={deleteToggle}
                      color="footerButton">
                      {t(`cancel`)}
                    </Button>
                    <Button
                      onClick={(e) => {
                        deleteToggle(e);
                        handleMeetingDelete(meetingDetails).then((res: any) => {
                          if (res?.error?.data?.message === 'BeeMG-ERR029') {
                            toastError(t('BeeMG-ERR029'))
                          }
                          else {
                            toastSuccess(t('eventDeletedSuccessfully'))
                            navigate('/s/events/my-events')
                          }
                        })
                      }}
                      id="btn_ok"
                      data-testid="btn_ok"
                      autoFocus
                      color="footerButton">
                      {t('ok')}
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

              <Button
                className='text-sm w-46 px-8'
                size="md"
                data-testid="btn_saveEvent"
                id="btn_saveEvent"
                color="save"
                submit
                disabled={isLoading || submitted || ShowAnonymous}>
                {t('save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default EventDetails
