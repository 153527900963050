/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {Pagination} from './../models/pagination.model';
import {Meeting} from './../models/meeting.model';
import {
  formatDateTimeFullToTimezone,
  formatDateToTimezone,
  formatMonthDateToTimezone,
  formatTimeToTimezone,
} from '../Utils/datetime';
import {allApis} from './allApis';
import {getCurrency, getUserPreferenceCurrency} from '../Utils/index.web';
import {format} from '../Utils/format';
import {CreatorServerFilter} from '../models/creatorFilter.model';

type MeetingSelectType =
  | '6Hours'
  | 'currentEvent'
  | 'nextDay'
  | 'weekend'
  | 'favorite'
  | 'country'
  | 'trending'
  | 'kidsOnly'
  | 'popularGroup'
  | 'festiveSeason'
  | 'upcomingEvents'
  | 'movies';

/**
 * Returns a query function that generates a GET request to retrieve meetings based on the provided URL and query parameters.
 *
 * @param {string} url - The base URL for the query.
 * @return {(args: { type: MeetingSelectType, formattedServerFilter?: CreatorServerFilter }) => { url: string, method: string, params: CreatorServerFilter | undefined }} A query function that takes query parameters and returns a GET request configuration.
 */

const getMeetingsQuery = (url: string) => {
  return (args: {
    type: MeetingSelectType;
    formattedServerFilter?: CreatorServerFilter;
  }) => {
    const {type, formattedServerFilter} = args;
    return {
      url: `${url}${type}/A`,
      method: 'GET',
      params: formattedServerFilter,
    };
  };
};

/**
 * Transforms an array of meetings by applying the meetingTransform function to each meeting.
 *
 * @param {Meeting[]} meetings - The array of meetings to be transformed.
 * @return {Meeting[]|undefined} The transformed array of meetings, or undefined if the input array is null or undefined.
 */

export const meetingsTransform = (meetings: Meeting[]) => {
  if (meetings) {
    meetings = meetings.map(meetingList => {
      return meetingTransform(meetingList);
    });
  }

  return meetings;
};

/**
 * Transforms a meeting object by formatting its date, time, and cost properties based on the user's preferred currency and timezone.
 *
 * @param {Meeting} meeting - The meeting object to be transformed.
 * @return {Meeting} The transformed meeting object with formatted date, time, and cost properties.
 */

const meetingTransform = (meeting: Meeting) => {
  const preferenceCurrency = getUserPreferenceCurrency();
  const currencyList = getCurrency();
  const filteredValue = currencyList?.filter(
    currency => currency.code === preferenceCurrency,
  );

  if (
    meeting.costLocal &&
    meeting.costLocal > 0 &&
    meeting.cost &&
    meeting.cost > 0
  ) {
    meeting.costLocal_formatted = format(
      filteredValue[0]?.formatType,
      meeting.costLocal,
    );
    meeting.cost_formatted = format(filteredValue[0]?.formatType, meeting.cost);
  }

  meeting.user_fromDateTime = formatDateTimeFullToTimezone(
    meeting.fromDateTime,
  );
  meeting.user_toDateTime = formatDateTimeFullToTimezone(meeting.toDateTime);
  meeting.display_date = formatDateToTimezone(meeting.fromDateTime);
  meeting.display_MonthDate = formatMonthDateToTimezone(meeting.fromDateTime);
  meeting.display_time =
    formatTimeToTimezone(meeting.fromDateTime) +
    ' - ' +
    formatTimeToTimezone(meeting.toDateTime);
  meeting.display_FromDateTime = meeting.fromDateTime
    ? new Date(meeting.fromDateTime).toLocaleString()
    : '';
  meeting.display_FromTime =
    formatTimeToTimezone(meeting.fromDateTime) +
    ' - ' +
    formatTimeToTimezone(meeting.toDateTime);
  meeting.display_createdAt = formatDateToTimezone(
    meeting?.createdAt?.toString(),
  );

  if (meeting.meetingDetails?.image?.length > 0) {
    meeting.display_imageUrl = meeting.meetingDetails.image[0].webpImageName;
  }

  if (meeting.user?.firstName) {
    meeting.display_hostName =
      meeting.user.firstName + ' ' + meeting.user.lastName;
  } else if (meeting.user?.nickName) {
    meeting.display_hostName = meeting.user.nickName;
  }

  return meeting;
};

/**
 * Transforms a pagination response of meetings by applying the meetingTransform function to each meeting in the results.
 *
 * @param {Pagination<Meeting>} response - The pagination response of meetings to be transformed.
 * @return {Pagination<Meeting>} The transformed pagination response of meetings.
 */

const meetingResponseTransform = (response: Pagination<Meeting>) => {
  if (response.results) {
    response.results = response.results.map(meeting => {
      return meetingTransform(meeting);
    });
  }

  return response;
};

export const meetingApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createMeeting: builder.mutation<Meeting, Partial<Meeting>>({
      query: meeting => {
        return {
          url: `meeting`,
          method: 'POST',
          body: meeting,
        };
      },
      invalidatesTags: (result, error, meeting) => [
        {
          type: 'MeetingByID',
          id: result?.id,
        },
        'MeetingSelectedDate',
        'FutureMeeting',
        'nextEventHeader',
      ],
    }),

    getAllMeetings: builder.query<
      Pagination<Meeting>,
      {type: MeetingSelectType; formattedServerFilter?: CreatorServerFilter}
    >({
      query: args => getMeetingsQuery(`meeting/`)(args),
      transformResponse: (res: any) => meetingResponseTransform(res),
      providesTags: (result, error, args) => [
        {type: 'MeetingByID', id: args.type},
        'MeetingRegisterAllMeeting',
        'ReRegisterAfterCancel',
        'MeetingList',
      ],
    }),

    getAllMaybeMeetings: builder.query<Meeting[], void>({
      query: () => `meeting/all/maybe`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
    }),
    getPastScheduledMeetingsMeetingsDetailId: builder.query<Meeting[], string>({
      query: meetingDetailId =>
        `meeting/all/pastScheduledByMeetingDetail/${meetingDetailId}`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
      providesTags: ['PastEventMeeting'],
    }),

    getMeeting: builder.query<Meeting, string>({
      query: meetingId => `meeting/${meetingId}`,
      transformResponse: (response: Meeting) => meetingTransform(response),
      providesTags: (result, error, meetingId) => [
        {type: 'MeetingByID', id: meetingId},
      ],
    }),

    checkMeetingForRequest: builder.query<boolean, any>({
      query: args => {
        const {fromDateTime, toDateTime} = args;
        return `meeting/checkTiming/${fromDateTime}/${toDateTime}`;
      },
    }),

    checkMeetingForGuest: builder.query<Meeting, any>({
      query: args => {
        const {fromDateTime, toDateTime, guestId} = args;
        return `meeting/host/checkTiming/${fromDateTime}/${toDateTime}/${guestId}`;
      },
    }),

    getLanguageForFilter: builder.query<{language: string}[], void>({
      query: () => `meeting/all/language`,
    }),

    getPriceForFilter: builder.query<any, string>({
      query: currency => `meeting/price/${currency}`,
    }),

    // getCategoryForFilter: builder.query<string[], void>({
    //   query: () => `meeting/all/category`,
    // }),

    // checkMeetings: builder.query<boolean, string>({
    //   query: meetingDetailsId => `meeting/checkMeetings/${meetingDetailsId}`,
    // }),

    getScheduledMeetingsDetails: builder.query<Meeting[], void>({
      query: () => `meeting/all/scheduledMeetings`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
      providesTags: [
        'AttendingMeeting',
        'MeetingRegisterForScheduledMeetings',
        'PaidInviteList',
        'CancellationRequest',
        'MeetingRequestByUser',
        'MeetingNotificationCount',
      ],
    }),

    getAttendedMeetingDetails: builder.query<Meeting[], void>({
      query: () => `meeting/all/attendedMeetings`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
      providesTags: [],
    }),

    getRequestMeetings: builder.query<Meeting[], void>({
      query: () => `meeting/all/requests`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
      providesTags: [
        'AttendingMeeting',
        'MeetingRegisterForScheduledMeetings',
        'PaidInviteList',
        'CancellationRequest',
        'MeetingRequestByUser',
        'MeetingNotificationCount',
        'blockedRequests',
      ],
    }),

    getAllMeetingsById: builder.query<Meeting[], string>({
      query: meetingDetailsId => `meeting/meetings/${meetingDetailsId}`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
    }),

    // getMeetingsBySelectDate: builder.query<Meeting[], any>({
    //   query: args => {
    //     const { meetingDetailsId, fromDate } = args
    //     return `meeting/meetingByDate/${meetingDetailsId}/${fromDate}`
    //   },
    //   transformResponse: (response: Meeting[]) => meetingsTransform(response),
    //   providesTags: ['MeetingSelectedDate'],
    // }),

    getUpcomingMeetingOfUser: builder.query<Meeting, void>({
      query: () => `meeting/upcoming/meeting`,
      providesTags: ['nextEventHeader'],
    }),

    // iCal: builder.query<any, string>({
    //   query: meetingId => `meeting/iCal/${meetingId}`,
    // }),

    // googleCalendar: builder.query<any, string>({
    //   query: meetingId => `meeting/googleCalendar/${meetingId}`,
    // }),

    // yahooCalendar: builder.query<any, string>({
    //   query: meetingId => `meeting/yahooCalendar/${meetingId}`,
    // }),

    getFutureMeetingsByMeetingDetailsId: builder.query<Meeting[], string>({
      query: meetingDetailsId =>
        `meeting/getFutureMeetings/${meetingDetailsId}`,
      transformResponse: (response: Meeting[]) => meetingsTransform(response),
      providesTags: ['FutureMeeting', 'MeetingDelete'],
    }),

    updateMeeting: builder.mutation<Meeting, Partial<Meeting>>({
      query: meeting => {
        return {
          url: `meeting/${meeting.id}`,
          method: 'PUT',
          body: meeting,
        };
      },
      invalidatesTags: (result, error, meeting) => [
        {type: 'MeetingByID', id: meeting.id},
        'MeetingSelectedDate',
        'FutureMeeting',
        'MeetingList',
      ],
    }),

    deleteMeetingWithRefund: builder.mutation<boolean, any>({
      query: args => {
        const {id, reason} = args;

        return {
          url: `meeting/refund/${id}`,
          method: 'PUT',
          body: reason,
        };
      },
      invalidatesTags: (result, error, id) => [
        {type: 'MeetingDetail', id},
        'MeetingList',
        'MeetingDelete',
      ],
    }),

    deleteMeeting: builder.mutation<boolean, Meeting>({
      query: meeting => {
        return {
          url: `meeting/${meeting.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, meeting) => [
        {
          type: 'MeetingByID',
          id: meeting.id,
        },
        'MeetingSelectedDate',
        'FutureMeeting',
        'MeetingList',
        {type: 'MeetingDetail', id: meeting.meetingDetails?.id},
      ],
    }),
  }),
});

export const {
  useGetAllMeetingsQuery,
  useCheckMeetingForGuestQuery,
  useGetAllMaybeMeetingsQuery,
  useGetAllMeetingsByIdQuery,
  useGetScheduledMeetingsDetailsQuery,
  useGetRequestMeetingsQuery,
  useGetUpcomingMeetingOfUserQuery,
  useCreateMeetingMutation,
  useUpdateMeetingMutation,
  useDeleteMeetingMutation,
  useDeleteMeetingWithRefundMutation,
  useGetMeetingQuery,
  useGetFutureMeetingsByMeetingDetailsIdQuery,
  useGetLanguageForFilterQuery,
  useCheckMeetingForRequestQuery,
  useGetPastScheduledMeetingsMeetingsDetailIdQuery,
  useGetPriceForFilterQuery,
  useGetAttendedMeetingDetailsQuery,
} = meetingApi;
