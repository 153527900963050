/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import { Button } from '../../Components'
import NewIcon from '../../Components/base/icon/newIcons'
import { useChatSeenUpdateHook } from '../../Hooks/MessageHooks'
import { useGetMessagesByMeetingRequestIdQuery, useGetUploadedFileQuery } from '../../Services/messageApi'
import { useGetSettingValue } from '../../Services/settingReducer'
import { ChatMessage, ChatMessageType } from '../../Store/Chat/ChatModel'
import { acceptVideoRequest, declineVideoRequest, getActiveMeetingRequestId } from '../../Store/Chat/chat'
import { useAppSelector } from '../../Store/hooks'
import { Accept } from '../../models/meetingRequest.model'
import { useHandleMeetingNavigation } from '../LiveKit/hooks/livekitWebOnlyHooks'
import VideoMeetingStatus from './VideoMeetingStatus'
import { DisplayFormattedTime } from '../../Components/formattedDateAndTime'
import { toastError } from '../../Components/toast'
import { getMeetingInfo } from '../../Services/livekitReducer'
import Icon from '../../Components/base/icon/icon'

const ReceivedMessage = forwardRef(({ msg }: { msg: ChatMessage }, ref: any) => {
  useChatSeenUpdateHook(msg)

  console.log('received message', msg);
  

  return (
    <div ref={ref} className="self-start">
      <div className='flex flex-row gap-x-2 items-center'>
        <RenderMessage msg={msg} />
        <div id={`receivedTime-${msg.meetingRequest?.id}`} className="text-xxs text-gray-400 text-right">
          <DisplayFormattedTime displayFromTime={msg.dateSend} />
        </div>
      </div>
    </div>
  )
})

export default ReceivedMessage

  /**
   * Render a chat message.
   *
   * This component renders a chat message based on the message type.
   * For text messages, it renders a {@link DisplayTextMessage} component.
   * For video messages, it renders a {@link DisplayVideoRequest} component.
   *
   * @param {{ msg: ChatMessage }} props The component props.
   * @param {ChatMessage} props.msg The chat message.
   *
   * @returns {JSX.Element} The rendered component.
   */
const RenderMessage = ({ msg }: { msg: ChatMessage }) => {
  console.log('render message in received', msg);
  
  return (
    <div className="max-w-[25rem]">
      {msg.type === ChatMessageType.Text && <DisplayTextMessage msg={msg} />}
      {msg.type === ChatMessageType.Video && <DisplayVideoRequest msg={msg} />}
      {msg.type === ChatMessageType.File && <DisplayFile msg={msg} />}

    </div>
  )
}

  /**
   * Display a video request message.
   *
   * This component renders a video request message with the video request details,
   * including the meeting ID, meeting start and end times, and the status of the
   * video request (accepted, declined, or expired).
   *
   * @param {{ msg: ChatMessage }} props The component props.
   * @param {ChatMessage} props.msg The chat message containing the video request.
   *
   * @returns {JSX.Element} The rendered component.
   */
function DisplayVideoRequest({ msg }: { msg: ChatMessage }) {
  const { t } = useTranslation();
  const [isExpired, setIsExpired] = useState(true);
  const chatAcceptTimeLimit = useGetSettingValue('CHAT_ACCEPT_VIDEO_REQUEST_TIME_LIMIT(MINUTES)')
  const activeMeetingRequestId = useAppSelector(getActiveMeetingRequestId)
  const meetingInfo = useAppSelector(getMeetingInfo)

  useGetMessagesByMeetingRequestIdQuery(activeMeetingRequestId, {
    skip: activeMeetingRequestId !== msg?.meetingRequest?.id,
  })
  const { joinMeeting } = useHandleMeetingNavigation()

  const handleJoinClick = () => {
    if (meetingInfo && (meetingInfo.isAudioMeeting || meetingInfo.isVideoMeeting)) {
      toastError(t('meetingOngoingDisconnectToJoin'));
    } else {
      joinMeeting(msg?.meetingRequest?.meeting?.id);
    }
  };

  useEffect(() => {
    let setTimerInterval: any
    if (chatAcceptTimeLimit) {
      if (new Date().valueOf() - new Date(msg?.dateSend).valueOf() > parseInt(chatAcceptTimeLimit) * 60 * 1000) {
        setIsExpired(true)
      } else {
        setIsExpired(false)
      }
      setTimerInterval = setInterval(() => {
        if (new Date().valueOf() - new Date(msg?.dateSend).valueOf() > parseInt(chatAcceptTimeLimit) * 60 * 1000) {
          setIsExpired(true)
        }
        else {
          setIsExpired(false)
        }
      }, 1000)
    }
    return () => {
      clearInterval(setTimerInterval)
    }
  }, [chatAcceptTimeLimit])

  return (
    <div
      className={`flex flex-col mt-2 my-2 bg-gray-200 rounded-md relative ${
        !msg.meetingRequest?.accept && !isExpired ? 'min-h-[4rem]' : ''
      }`}>
      <div className="bg-chat-received text-gray-700 rounded-md flex flex-col px-5 py-2 max-w-[20rem] z-20">
        <div className="flex flex-row justify-start items-center gap-x-3 text-sm">
          <NewIcon icon="VIDEO_CAM" size="small" height="small" />
          {!isExpired &&
            msg.meetingRequest?.accept !== Accept.Yes &&
            msg.meetingRequest?.accept !== Accept.No && (
              <div
                id={`chk_receivedVideoRequest-${msg?.messageId}`}
                data-testid="chk_receivedVideoRequest"
                className="block">
                {t('videoRequestReceived')}
              </div>
            )}
          {msg.meetingRequest?.accept === Accept.Yes && (
            <div
              id={`chk_receivedVideoRequest-${msg?.messageId}`}
              data-testid="chk_receivedVideoRequest"
              className="block">
              {t('videoRequestAccepted')}
            </div>
          )}
          {msg.meetingRequest?.accept === Accept.No && (
            <div
              id={`chk_receivedVideoRequest-${msg?.messageId}`}
              data-testid="chk_receivedVideoRequest"
              className="block">
              {t('videoRequestDeclined')}
            </div>
          )}
          {isExpired && !msg.meetingRequest?.accept && (
            <div
              id={`chk_receivedVideoRequest-${msg?.messageId}`}
              data-testid="chk_receivedVideoRequest"
              className="block">
              {t('videoRequestExpired')}
            </div>
          )}
        </div>

        {(msg.meetingRequest?.meeting?.actualStartAt ||
          (!msg.meetingRequest?.accept && !isExpired)) && (
          <div className="bg-gray-700 h-[0.2px] opacity-20 mt-3" />
        )}

        {
          <>
            {msg.meetingRequest?.accept === Accept.Yes && (
              <div className="flex flex-row mt-3 items-center justify-center text-sm">
                {!isExpired && (
                  <Button
                    id={`join-${msg?.messageId}`}
                    size="sm"
                    rounded
                    color="join"
                    onClick={handleJoinClick}>
                    {t('join')}
                  </Button>
                )}
              </div>
            )}
            <VideoMeetingStatus message={msg} />
          </>
        }
      </div>

      {!msg.meetingRequest?.accept && (
        <div className="flex flex-row text-xs relative bottom-0 w-full h-full z-10">
          {!isExpired && (
            <>
              <button
                className="flex flex-row justify-center items-end  hover:bg-chat-accepted w-full p-1  rounded-br-md rounded-bl-md rounded-t-md hover:text-white pb-2"
                title={t('accept')}
                onClick={() => acceptVideoRequest(msg)}>
                <div id={`btn_accept-${msg?.messageId}`}> {t('accept')}</div>
              </button>

              <button
                title={t('decline')}
                onClick={() => declineVideoRequest(msg)}
                className="flex flex-row justify-center items-end hover:bg-chat-declined w-full p-1 rounded-br-md rounded-bl-md rounded-t-md hover:text-white pb-2">
                <div id={`btn_decline-${msg?.messageId}`}> {t('decline')}</div>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}



function DisplayTextMessage({ msg }: { msg: ChatMessage }) {

  console.log('received text message', msg);
  
  return (
    <div id={msg?.message} data-testid={msg?.message} className="bg-chat-received rounded-md px-3 py-2 my-2 flex-1 text-gray-700 text-sm">
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a className='underline' target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
        key={msg?.message}>
        {<div id={`receivedMsg_${msg?.messageId}`} className="break-all">{msg?.message}</div>}
      </Linkify>
    </div>
  )
}

function DisplayFile({ msg }: { msg: ChatMessage }) {
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const fileUrl = `${baseImageURL}/${msg?.attachedFile ? msg?.attachedFile : msg?.message}`;
  const fileType = msg?.attachedFile ? msg?.attachedFile?.split('.').pop()?.toLowerCase(): msg?.message?.split('.').pop()?.toLowerCase();
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log('received file message', msg);
  

  const openFilePreview = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
    id={msg?.message}
    data-testid={msg?.message}
    className="rounded-md px-3 py-2 my-2 flex-1 text-gray-700 text-sm">
    <Linkify
      componentDecorator={(decoratedHref, key) => (
        <a
          className="underline"
          target="_blank"
          href={decoratedHref}
          key={key} rel="noreferrer">
          {decoratedHref}
        </a>
      )}
      key={msg?.message}>
      <div id={`receivedMsg_${msg?.messageId}`} className="break-all">
        {/* Preview logic */}
        {['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileType as string) ? (
          <img
            src={fileUrl}
            alt="Preview"
            className="max-w-full max-h-64 object-contain rounded cursor-pointer"
            onClick={openFilePreview}
            onError={(e) => (e.currentTarget.style.display = 'none')}
          />
        ) : fileType === 'pdf' ? (
          <div
              onClick={openFilePreview}
              className="flex flex-col items-center cursor-pointer border border-gray-300 rounded">
              <Icon
                icon="PDFICON"
                size="large"
                height="small"
                className="mb-2 cursor-pointer"
              />
              <span className="text-center text-sm text-gray-600 break-words">
              {msg?.attachedFile ? msg?.attachedFile : msg?.message}
              </span>
            </div>
        ) : fileType === 'svg' ? (
          <img
            src={fileUrl}
            alt="SVG Preview"
            className="max-w-full max-h-64 object-contain rounded cursor-pointer"
            onClick={openFilePreview}
          />
        ) : (
          <div className="file-name text-gray-600">{msg?.message}</div>
        )}
  
        {/* Fullscreen Preview Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-80">
          {/* Close Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
            className="absolute top-20 right-4 w-8 h-8 flex items-center justify-center bg-white text-black text-lg font-bold rounded-full shadow-lg hover:bg-gray-300 z-50 border border-gray-200"
          >
            ✕
          </button>
        
          {/* Modal Content */}
          <div className="relative w-full h-full flex justify-center items-center">
            {['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileType as string) ? (
              <img
                src={fileUrl}
                alt="Full Preview"
                className="max-h-full max-w-full object-contain pt-24 justify-center items-center"
              />
            ) : fileType === 'pdf' ? (
              <iframe
                src={fileUrl}
                className="w-full h-full border-none"
                title="PDF Preview"
              />
            ) : fileType === 'svg' ? (
              <img
                src={fileUrl}
                alt="Full Preview"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <div className="file-name text-gray-600">{msg?.message}</div>
            )}
          </div>
        </div>
        
        )}
      </div>
    </Linkify>
  </div>
  
  );
}