import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../Components';
import { Modal, ModalCloseButton, ModalHeader, ModalBody } from '../../Components/base/modal/modal';
import Icon from '../../Components/base/icon/icon';
import { ToolTip } from '../../Components/base/tooltip/tooltip';
import Chip from '../../Components/Chip';
import { useCreateBlueTickRequestMutation, useUploadBlueTickAttachmentsMutation } from '../../Services/blueTickRequestApi';
import { useAppSelector } from '../../Store/hooks';
import { getCurrentUser } from '../../Services/userReducer';
import { BlueTickVerified } from '../../models/blueTickRequest.model';
import { t } from 'i18next';
import { toastError, toastSuccess } from '../../Components/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { blueTickValidationSchema } from '../../Utils/validation';
import { Config } from '../../Config';
import { useGetSettingValue } from '../../Services/settingReducer';
import { useTranslation } from 'react-i18next';

interface BlueTickVerificationFormProps {
  toggleModal: () => void;
}

export const BlueTickVerificationForm: React.FC<BlueTickVerificationFormProps> = ({ toggleModal }) => {
  const [fileValue, setFileValue] = useState<File[]>([]);
  const [clearVisibility, setClearVisibility] = useState(false);
  const [serverError, setServerError] = useState<string>('');
  const currentUser = useAppSelector(getCurrentUser);
  const faceVerificationURL = useGetSettingValue('FACE_VERIFICATION_LINK')
  const { i18n } = useTranslation();
  

  const { register, handleSubmit, formState: { errors } , setValue, trigger, clearErrors, setError } = useForm<{
    reason: string;
    attachment: File[];
   }>({
    resolver: yupResolver(blueTickValidationSchema),
    defaultValues: {
      reason: '',
      attachment: [],
    },
  });
  console.log("1",errors);

  const [createRequest, { isLoading : requestLoading , isSuccess: requestSuccess}] = useCreateBlueTickRequestMutation();
  const [uploadAttachment, { isLoading : attachmentLoading , isSuccess: attachmentSuccess}] = useUploadBlueTickAttachmentsMutation();


  const handleVerifyFace = () => {
    const params: {[key: string]: string} = {
      cUserId: currentUser.id,
      cIPAddress: currentUser.ipAddress,
      cLanguage: i18n.language,
      apiUrl: Config.API_URL,
    }
    
    window.open(
      `${faceVerificationURL}faceverify?${new URLSearchParams(
        params,
      ).toString()}`,
      '_self',
    )
  }
  const validateFormate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) =>
        ["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      );
      setFileValue(validFiles);
      setValue("attachment", validFiles as File[], { shouldValidate: true }); // Update form value
      if (validFiles.length !== files.length) {
        
      }
    } else {
      setValue("attachment", []);
    }
  };

  const removeFile = (fileName: string) => {
    const updatedFiles = fileValue.filter((file) => file.name !== fileName);
    setFileValue(updatedFiles);
  
  
    setValue("attachment", updatedFiles, { shouldValidate: true });
  
    trigger("attachment");
  
    if (updatedFiles.length === 0) {
      setError("attachment", {
        type: "manual",
        message: "Please attach a valid file.",  // Custom error message
      });
    } else {
      clearErrors("attachment");
    }
  }
  const onSubmit = async (data: any) => {
    const blueTickRequest = {
      reason: data.reason,
      requestStatus: BlueTickVerified.NotComplete,
    };
  
    if (serverError) {
      setServerError('');
    }
  
    try {
      const response = await createRequest(blueTickRequest);
    
      if ('error' in response) {
        let errorMessage = 'An error occurred';
    
        if ('data' in response.error && response?.error?.data) {
          const errorData = response.error.data as { message?: string };
          errorMessage = errorData.message || errorMessage;
        }
    
        console.error('Error creating request:', errorMessage);
        toastError(errorMessage); 
        toggleModal();
        return; 
      
      }
    
      if (fileValue.length > 0) {
        const formData = new FormData();
        fileValue.forEach((file) => {
          formData.append('files', file);
        });
    
        const args = { id: response?.data?.id, files: formData };
    
        try {
          await uploadAttachment(args);
          toastSuccess(t('requestSentSuccessfully'));
          toggleModal();
        } catch (uploadError) {
          const uploadErrorMessage =
            (uploadError as any)?.response?.data?.message || t('Error uploading attachment');
    
          console.error('Error uploading attachment:', uploadError);
          toastError(uploadErrorMessage); 
          return; 
        }
      } else {
        toastSuccess(t('requestSentSuccessfully'));
        toggleModal();
      }
    } catch (error: any) {
      const genericErrorMessage =
        error?.response?.data?.message || 'An unexpected error occurred';
      console.error('Error creating request:', genericErrorMessage);
      toastError(genericErrorMessage); 
    }
    
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {/* Reason Field */}
      <div className="mb-4">
        <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
          Reason *
        </label>
        <textarea
          id="reason"
          {...register('reason')}
          className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500 resize-none"
          placeholder="Provide your reason here..."
        ></textarea>
        {errors.reason && <span className="text-red-500 text-xs">{t(errors.reason.message as string)}</span>}
      </div>

      {/* Attachment Field */}
      <div className="my-6 items-center cursor-pointer">
        <label htmlFor="file-upload-contact" className="justify-center flex-1">
          <p className="text-sm font-medium text-gray-700">Add an official identification document of yourself</p>
          <div className="my-2 w-full flex justify-center p-2 border-2 border-dashed rounded-md cursor-pointer">
            <div className="space-y-1 text-center">
              <div id="input_file" className="flex w-full justify-center text-center text-xs text-gray-300 py-2">
                <div>
                  <input
                    type="file"
                    id="file-upload-contact"
                    data-testid="file-upload-contact"
                    accept=".png,.jpeg,.jpg"
                    className="hidden"
                    multiple
                    {...register('attachment')}
                    onChange={validateFormate}
                  />
                </div>
                <div className="flex items-center">
                  <ToolTip tip="Attachment should be in PNG, JPEG, or JPG format">
                    <div className="flex items-center">
                      <Icon icon="ADD" size="x-small" height="small" stroke="#010101" />
                      <span className="ml-2">Add Attachment</span>
                    </div>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>
        </label>

        {/* Display Validation Errors */}
        {errors.attachment && (
          <span className="text-red-500 text-xs">
            {console.log(errors.attachment)}
            {t(errors.attachment.message as string)}
          </span>
        )}
      

        {/* Display uploaded files */}
        {!clearVisibility && fileValue.length > 0 && (
          <div>
            {fileValue.map((file, index) => (
              <div key={index} className="inline-block mr-2 mb-2">
                {file && file.name && (
                  <Chip
                    data-testid={`fileName-${file.name}`}
                    onClickClose={() => removeFile(file.name)} // Remove file on click
                    isSelected={true}
                    key={index}
                    removeCatId={`btn_removeCat_${index}`}
                    value={file.name}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Note */}
      <p className="text-sm text-gray-600">
        <strong>Note:</strong> {t('faceVerificationShouldBeCompletedToGetVerifiedFurther')}
        <div onClick={handleVerifyFace} id="error_verifyFace">
          <span>
            {t('faceVerificationNotCompletedForThisUser')}!{' '}
          </span>
          <span className="underline hover:cursor-pointer">
            {t('clickHereToVerify')}
          </span>
        </div>
      </p>

      {/* Buttons */}
      <div className="flex justify-end items-center">
        <Button id="btn_confirm" type="submit" submit>{t('confirm')}</Button>
      </div>
    </form>
  );
};

export const BlueTickVerificationModal = ({ isOpen, toggle }: any) => {
  const handleToggleModal = () => {
    toggle();
  }
  return (
     <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
      <ModalCloseButton toggle={toggle} />
      <ModalHeader>{t('requestForBlueTick')}</ModalHeader>
      <ModalBody>
      <BlueTickVerificationForm toggleModal={handleToggleModal} />
      </ModalBody>
    </Modal>
  );
};

export default BlueTickVerificationModal;
