/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {User} from './../models/user.model';
import {useState} from 'react';
import {UserCategory} from '../models/userCategory.model';
import {
  useGetCategoriesMainQuery,
  useGetSubCategoryQuery,
} from '../Services/categoryApi';
import {Category} from '../models/category.model';
import {
  useCreateUserCategoryMutation,
  useDeleteUserCategoryMutation,
} from '../Services/userCategoryApi';
import {getCurrentUser} from '../Services/userReducer';
import {store} from '../Store';
import {useAppSelector} from '../Store/hooks';
import {useUpdateUserMutation} from '../Services/userApi';

/**
 * Sorts an array of UserCategory objects based on the name of the associated Category.
 *
 * @param {UserCategory[] | undefined} userCategories - The array of UserCategory objects to sort.
 * @return {UserCategory[]} The sorted array of UserCategory objects.
 */

export function sortingCategories(userCategories: UserCategory[] | undefined) {
  return userCategories
    ?.slice()
    .sort(
      (categoryA: UserCategory, categoryB: UserCategory) =>
        categoryA.category?.name?.localeCompare(categoryB.category?.name) ?? 0,
    );
}

/**
 * A custom React hook for managing user category updates.
 *
 * It fetches the current user's categories, parent categories, and provides functions to update, remove, and set user categories.
 *
 * @return {object} An object containing the loading state, current user, parent categories, and functions to remove, set, and check user category updates.
 */

export const useUpdateCategoryHook = () => {
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useAppSelector(getCurrentUser);
  const [interestsBeforeEdit, setInterestsBeforeEdit] = useState(
    Object.freeze(currentUser.userCategory ?? []),
  );

  let {data: parentCategories, isSuccess: categoriesSuccess} =
    useGetCategoriesMainQuery();
  const [updateUser] = useUpdateUserMutation();

  const [createUserCategory] = useCreateUserCategoryMutation();
  const [deleteUserCategory] = useDeleteUserCategoryMutation();

  const checkInterestsUpdates = () => {
    // if user made changes in the interests, only then updateUser should be called.
    if (
      currentUser.userCategory &&
      currentUser.userCategory.length &&
      interestsBeforeEdit &&
      interestsBeforeEdit.length &&
      JSON.stringify(interestsBeforeEdit) !==
        JSON.stringify(currentUser.userCategory)
    ) {
      // update the user with currentUser to trigger the profile update mail
      updateUser(currentUser);
      setInterestsBeforeEdit(currentUser.userCategory);
    }
  };

  if (categoriesSuccess) {
    if (isLoading) {
      setIsLoading(false);
    }
  }

  if (currentUser && parentCategories) {
    const userCategoryNames =
      currentUser?.userCategory &&
      currentUser?.userCategory.map(
        (userCategory: any) => userCategory.category?.name,
      );
    parentCategories = parentCategories?.filter(
      (category: any) =>
        userCategoryNames === undefined ||
        !userCategoryNames.includes(category.name),
    );
  }

  const removeUserCategory = (userCat: UserCategory) => {
    deleteUserCategory(userCat);
  };

  const setSelectedUserCategory = (userCategory: UserCategory) => {
    if (userCategory) {
      createUserCategory(userCategory)
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  return {
    isLoading,
    currentUser,
    parentCategories,
    removeUserCategory,
    setSelectedUserCategory,
    checkInterestsUpdates,
  };
};

/**
 * A custom React hook for managing category selection.
 *
 * It fetches sub categories, filters out user's existing categories, and provides functions to select and reset categories.
 *
 * @return {Object} An object containing the loading state, categories, selected category, and functions to select and reset categories.
 */

export const useCategoryListForSelectHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadCategoryId, setLoadCategoryId] = useState<string>();
  const [idPath, setIdPath] = useState<string[]>([]);
  const [displayCategoriesNames, setDisplayCategoriesNames] = useState<
    string[]
  >([]);
  const [selectingCategory, setSelectingCategory] = useState<Category>();
  const [selectedUserCategory, setSelectedUserCategory] =
    useState<UserCategory>();

  const user = getCurrentUser(store.getState());
  let {data: categories, isSuccess: categoriesSuccess} = useGetSubCategoryQuery(
    loadCategoryId ?? '',
    {skip: !loadCategoryId},
  );

  if (loadCategoryId && categoriesSuccess && isLoading) {
    setIsLoading(false);
  } else if (loadCategoryId && !categoriesSuccess && !isLoading) {
    setIsLoading(true);
  }

  if (categories) {
    const userCategoryNames =
      user?.userCategory &&
      user?.userCategory.map(
        (userCategory: any) => userCategory.category?.name,
      );

    if (userCategoryNames) {
      categories = categories?.filter(
        category => category && !userCategoryNames.includes(category.name),
      );
    }
  }

  const setCategorySelected = (categorySelect: Category) => {
    setIdPath([...idPath, categorySelect?.id]);
    setDisplayCategoriesNames([
      ...displayCategoriesNames,
      categorySelect?.name,
    ]);
    setLoadCategoryId(categorySelect.id);
    setSelectingCategory(categorySelect);
  };

  const resetCategorySelected = () => {
    if (idPath.length > 1) {
      setIdPath([]);
    }

    if (displayCategoriesNames.length > 0) {
      setDisplayCategoriesNames([]);
    }

    if (loadCategoryId) {
      setLoadCategoryId(undefined);
    }

    if (selectingCategory) {
      setSelectingCategory(undefined);
    }
  };

  if (loadCategoryId && categories?.length === 0) {
    if (selectingCategory) {
      const userCategory: UserCategory = {} as UserCategory;
      userCategory.user = {id: user.id} as User;
      userCategory.category = {id: selectingCategory.id} as Category;
      userCategory.path = idPath.join('-');
      userCategory.idPath = displayCategoriesNames.join(' -> ');
      setSelectedUserCategory(userCategory);
      resetCategorySelected();
    }
  }

  return {
    isLoading,
    categories,
    loadCategoryId,
    displayCategoriesNames,
    selectingCategory,
    setCategorySelected,
    resetCategorySelected,
    selectedUserCategory,
  };
};
