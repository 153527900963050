/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {UserCategory} from './../models/userCategory.model';
import {UserDetail} from './../models/userDetail.model';
import {preferenceApi} from './preferenceApi';
import {Preference} from './../models/preference.model';
import {User} from './../models/user.model';
import {createSlice} from '@reduxjs/toolkit';
import {userApi} from './userApi';
import {RootState} from '../Store';
import {awsLogout} from './tokenReducer';

interface UserState {
  currentUser: User | null;
  preference: Preference | null;
  currentUserDetail: UserDetail | null; //cognito User Detail
}

const initialStateValue: UserState = {
  currentUser: null,
  preference: null,
  currentUserDetail: null,
};

/**
 * Updates the currentUserDetail in the user state.
 *
 * @param {UserState} state - The current state of the user.
 * @param {PayloadAction} action - The action containing the new currentUserDetail.
 * @return {void}
 */

export const userSlice = createSlice({
  name: 'user',
  initialState: initialStateValue,
  reducers: {
    changeCurrentUserDetail: (state, action) => {
      state.currentUserDetail = action.payload;
    },
    changeToInitial: state => {
      state.currentUser = null;
      state.preference = null;
      state.currentUserDetail = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(awsLogout.fulfilled, (state, action) => {
      state.currentUser = null;
      state.preference = null;
      state.currentUserDetail = null;
    });
    builder.addMatcher(
      userApi.endpoints.getCurrentUserDetails.matchFulfilled,
      (state, action) => {
        state.currentUser = action.payload;
      },
    );

    builder.addMatcher(
      preferenceApi.endpoints.getUserPreference.matchFulfilled,
      (state, action) => {
        state.preference = action.payload;
      },
    );
  },
});

export const getCurrentUser = (state: RootState): User =>
  state.user.currentUser;
export const getUserCategories = (state: RootState): UserCategory[] =>
  state.user.currentUser.userCategory;
export const getPreference = (state: RootState): Preference =>
  state.user.preference;
export const getCurrentUserDetail = (state: RootState): UserDetail =>
  state.user.currentUserDetail;
export const {changeCurrentUserDetail, changeToInitial} = userSlice.actions;
export default userSlice.reducer;
