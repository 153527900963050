/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/base/loading/loading";
import { useLoginSuccessfulHook } from "../Hooks/LoginSuccessful";
import { getCurrentUser } from "../Services/userReducer";
import { useAppSelector } from "../Store/hooks";
import { useLocation } from "react-router-dom";

  /**
   * After user login successfully, this component will handle the login successful flow, including retrieving user data,
   * checking policy agreements, and navigating to the sign-in screen if necessary.
   * @returns {JSX.Element} A component that displays "Redirecting..." and a loading spinner if the user data is being retrieved,
   *  or a redirect to the sign-in screen if the user needs to sign in again.
   */
function LoginSuccessful() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser && pathname !== '/s/home') {
      console.log('LoginSuccessful redirect to /s/home', pathname);
      navigate('/s/home');
    }
  }, [currentUser, pathname, navigate]);

  const { loading, navigateScreenSignIn, cognitoKeys, linkData, getUser, userPolicyAgreementsLoading } = useLoginSuccessfulHook();

  useEffect(() => {
    if (cognitoKeys && linkData) {
      const url = window.location.href;
      if (url.indexOf('Already+found+an+entry+for+username+google') > 0) {
        try {
          Auth.federatedSignIn({
            provider: "Google" as any,
          });
        } catch (e) {
          console.error(`Google login error --->`, e);
        }
      } else if (url.indexOf('Already+found+an+entry+for+username+facebook') > 0) {
        try {
          Auth.federatedSignIn({
            provider: "Facebook" as any,
          });
        } catch (e) {
          console.error(`Facebook login error --->`, e);
        }
      } else {
        getUser();
      }
    }
  }, [cognitoKeys, linkData])

  if (loading && userPolicyAgreementsLoading) {
    return (<div className='h-screen flex flex-1 justify-center items-center'>
      <Loading />
    </div>)
  }

  if (navigateScreenSignIn) {
    navigate('/signIn')
  }

  return (
    <h1 className="flex justify-center text-xl font-medium">{t('redirecting')}</h1>
  )
}

export default LoginSuccessful