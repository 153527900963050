/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {BlockedChats} from '../models/blockedChats.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for blocking chat API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ blockChat: mutation, getBlockedChatUserById: query, getAllBlockedChats: query, unBlockChat: mutation }} - An object containing the defined endpoints.
 */

export const blockChatApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    // block Request API
    blockChat: builder.mutation<BlockedChats, Partial<BlockedChats>>({
      query: blockChat => ({
        url: `blockedChats`,
        method: 'POST',
        body: blockChat,
      }),
      invalidatesTags: ['blockedChats'],
    }),

    getBlockedChatUserById: builder.query<BlockedChats, string>({
      query: id => `blockedChats/${id}`,
      providesTags: ['blockedChats'],
    }),

    getAllBlockedChats: builder.query<BlockedChats[], null>({
      query: () => `blockedChats`,
      providesTags: ['blockedChats'],
    }),

    unBlockChat: builder.mutation<boolean, string>({
      query: id => {
        return {
          url: `blockedChats/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['blockedChats'],
    }),
  }),
});
export const {
  useBlockChatMutation,
  useGetBlockedChatUserByIdQuery,
  useGetAllBlockedChatsQuery,
  useUnBlockChatMutation,
} = blockChatApi;
