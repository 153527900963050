/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "(clickOrDropVideo)": "(Click or drop video)",
  "(clickOrDropImage)": "(Click or drop image)",
  "(guests)": "(Guests)",
  "about": "About",
  "accept": "Accept",
  "accepted": "Accepted",
  "activate": "Activate",
  "acceptRequest": "Accept request",
  "accountName": "Account name",
  "accountNameCanBeMax25CharactersLong": "Account name can be max 25 characters long",
  "accountNumber": "Account number",
  "accountNumberCanBeMax16Digits": "Account number can be max 16 digits",
  "accountWillBeActivatedInADay": "Account will be activated in a day",
  "actions": "Actions",
  "activateYourRazorpayAccount": "Activate your razorpay account",
  "activateYourStripeAccount": "Activate your stripe account",
  "activateYourPaymentAccountToCreatePaidEvents": "Activate your payment account to create paid events",
  "activateYourPaymentAccountFromSettingsPageToReceivePayment": "Activate your payment account from settings page to receive payment",
  "activeRequests": "Active requests",
  "actor": "Actor",
  "actualTime": "Actual time",
  "add": "Add",
  "add/editMeetingDetails": "Add/edit meeting details",
  "addAllMeetingsToCalendar": "Add all meetings to calendar",
  "addAttachment": "Add attachment",
  "addCohost": "Add co-host",
  "imagesandvideoupload": "Images and video upload",
  "addImage": "Add image",
  "addImagesandVideo": "Add images and video",
  "addMeetingURL": "Add meeting URL",
  "addMoreOptions": "Add more options",
  "addNewEvent": "Add new event",
  "addNewGuest": "Add new guest",
  "addNewImage": "Add new image",
  "addNewMeeting": "Add new meeting",
  "addNewSchedule": "Add new schedule",
  "addNewSession": "Add new session",
  "addRequirements": "Add requirements",
  "newSession": "New session",
  "addSelfIntroductionAndStyleItUsingBelowButtons": "Add self introduction and style it using below buttons.",
  "addTheBelowURLInYourCalendar": "Add the below URL in your calendar",
  "addThisMeetingToCalendar": "Add this meeting to calendar",
  "addToCalendar": "Add to calendar",
  "addToFavorites": "Add to favorites",
  "removeFromFavorites": "Remove from favorites",
  "addVideo": "Add video",
  "addYours": "Add yours",
  "administrator": "Administrator",
  "after": "After",
  "afternoon": "Afternoon",
  "ageMustBe": "Age must be",
  "agree": "Agree",
  "all": "All",
  "allHosts": "All hosts",
  "allHostsLeftTheMeeting": "Session ends as all hosts left the meeting",
  "allOnlineUsers": "All online users",
  "allowFreeConnectionWithOtherGuests": "Allow free connection with other guests",
  "allowOtherUserToConnectWithYou": "Allow other user to connect with you",
  "allowOtherPeopleToSendYouRequestForVideo": "Allow other users to send you request for video",
  "allowOtherUsersToMessageYou": "Allow other users to message you",
  "allReviews": "All reviews",
  "allPrivateSessionReviews": "All private session reviews",
  "selectCategoriesOfYourInterest": "Select categories of your interest",
  "alreadyAMember": "Already a member",
  "alreadyInvited": "Already invited",
  "alreadyMember": "Already member",
  "amount": "Amount",
  "amountCredited": "Amount credited",
  "amountToBeCredited": "Amount to be credited",
  "amountCreditedThisMonth": "Amount credited this month",
  "amountDebited": "Amount Debited",
  "amountDebitedThisMonth": "Amount debited this month",
  "amountRefundedAfterCancellationCharges": "Amount refunded after cancellation",
  "amountPaid": "Amount paid",
  "amountFullyRefunded": "Amount fully refunded",
  "andBefore": "and before",
  "andMeetLikeMindedPeople": "and meet like-minded people",
  "anonymous": "Anonymous",
  "anonymousProfile": "Anonymous profile",
  "anonymousProfileUpdatedSuccessfully": "Anonymous profile updated successfully",
  "apply": "Apply",
  "approve": "Approve",
  "approved": "Approved",
  "approx": "Approx",
  "areYouSureYouWantToExitTheCall?": "Are you sure you want to exit the call ?",
  "areyouSureToCancelTheRequest?": "Are you sure to cancel the request ?",
  "areYouSureToCancelThisEvent?": "Are you sure to cancel this event ?",
  "areYouSureToDeleteThisEvent": "Are you sure to delete this event",
  "areyouSureToDeleteThisAccount": "Are you sure to delete this Account ?",
  "areyouSureToSendCancellationRequestForThisEvent": "Are you sure to send cancellation request for this event ?",
  "attachmentShouldBePngOrJpeg": "Attachment should be png or jpeg",
  "atLeast7CalendarDaysBeforeTheStartOfTheEvent": "At least 7 calendar days before the start of the event",
  "atleastOneLowerCaseLetter": "Atleast one lower case letter",
  "atleastOneNumber": "Atleast one number",
  "atleastOneSpecialSymbol": "Atleast one special symbol",
  "atleastOneUpperCaseLetter": "Atleast one uppercase letter",
  "atleastOneFileIsRequired":"Atleast one file is required",
  "attemptLimitExceededPleaseTryAfterSomeTime": 'Attempt limit exceeded. Please try after some time',
  "attemptLimitExceededPleaseTryagainlater": 'Attempt limit exceeded. Please try again later',
  "attending": "Attending",
  "attendingEvents": "Attending events",
  "audio": "Audio",
  "audioEvent": "Audio event",
  "audioEventDurationMax": "Audio event duration max",
  "autoDelete": "Auto delete",
  "autoDeleteOnMinimumLimitNotReached": "When minimum user limit not reached",
  "autoDeleteTurnedOff": "Auto delete turned off",
  "autoDeleteTurnedOn": "Auto delete turned on",
  "audioMeetingPermissionError": "Please give microphone access to proceed with the meeting",
  "audioSetting": "Audio setting",
  "avatarImageUpdatedSuccessfully": "Avatar image updated successfully",
  "only": "only",
  "awaitingCancellation": "Awaiting cancellation",
  "accountNameIsRequired": "Account name is required",
  "accountNumberIsRequired": "Account number is required",
  "accountNameShouldContainOnlyLetters": "Account name should contain only letters",
  "accountNumberShouldContainOnlyNumericCharacters": "Account number should contain only numeric characters",
  "beneficiaryNameShouldContainOnlyLetters": "Beneficiary name should contain only letters",
  "businessNameIsRequired": "Business name is required",
  "beneficiaryNameIsRequired": "Beneficiary name is required",
  "back": "Back",
  "backToMeetingDetailsScreen": "Back to meeting details screen",
  "basedEvent": "Based Event",
  "before": "before",
  "beeMG,AVideoCommunication": "BeeMG, a video communication",
  "beneficiaryName": "Beneficiary name",
  "between": "between",
  "balanceTotal": "Balance total",
  "blankSpacesAreNotAllowed": "Blank spaces are not allowed",
  "block": "Block",
  "blockUser": "Block user",
  "bluetooth": "Bluetooth",
  "businessName": "Business name",
  "bookYourSlot": "Book your slot",
  "by": "by",
  "BaseAmount" : "Base amount",
  "codeIsRequired": "IFSC code is required",
  "CVV": "CVV",
  "camera": "Camera",
  "cameraOn": "Camera on",
  "cameraOff": "Camera off",
  "cardNumber": "Card number",
  "canBeAdded": "can be added",
  "cancel": "Cancel",
  "cancelFreeEvent": "Cancel free event",
  "cancelInvite": "Cancel invite",
  "cancellation": "Cancellation",
  "cancellationTerms": "Cancellation terms",
  "cancellationBetween": "Cancellation between",
  "cancellationCharges": "Cancellation charges",
  "cancellationPolicies": "Cancellation policies",
  "cancellationPercentage": "Cancellation percentage",
  "cancellationPolicy": "Cancellation policy",
  "cancellationPolicyChosen": "Cancellation policy chosen",
  "cancellationPolicyForThisEvent": "cancellation policy for this event",
  "cancellationPolicyTerms": "Cancellation policy terms",
  "cancellationRequested": "Cancellation requested",
  "cancellationRequestSentSuccessfully": "Cancellation request sent successfully",
  "cancellationProcessedSuccessfully": "Cancellation processed successfully",
  "cancellationwithin": "Cancellation within",
  "cancellationIsNotApplicableForFreeAudioEvents": "Cancellation is not applicable for free audio events",
  "cancellationBefore12HoursWillBeCharged50PercentAndCancellationWithin12HoursWillBeCharged100Percent": "Cancellation before 12 hours will be charged 50 percent cancellation within 12 hours will be charged 100 percent",
  "cancellationBefore24HoursTo3daysWillBeCharged50PercentAndCancellationWithin24HoursWillBeCharged100Percent": "Cancellation before 24 hours to 3 days will be charged 50 percent cancellation within 24 hours will be charged 100 percent",
  "cancelled": "Cancelled",
  "cancelEvent": "Cancel event",
  "cancelledSuccessfully": "Cancelled successfully",
  "cancelRequest": "Cancel request",
  "catchPhrase": "Catch phrase",
  "catchPhraseMustBeAtMost150Characters": "Catch phrase must be at most 150 characters",
  "categories": "Categories",
  "categoriesYouAreInterestedIn": "Categories you are interested in",
  "categoryNameShouldBeMax50Characters": "Category name should be max 50 characters",
  "category": "Category",
  "elaborateYourRefundReason": "Elaborate your refund reason",
  "requestaRefund": "Request a refund",
  "upto": "Upto",
  "ofProcessingFeeWillBeApplicableUponCancellation": "of processing fee will be applicable upon cancellation",
  "refundRequestCreatedSuccessfully": "Refund request created successfully",
  "refundRequestCreationFailed": "Refund request creation failed",
  "refundRequested": "Refund requested",
  "refundReasonCanBeMax2000CharactersLong": "Refund reason can be max 2000 characters long",
  "chat": "Chat",
  "change": "Change",
  "changeAllowOtherUsersToMessageYouInSettingsToChatWithOthers": "Change Allow other users to message you in settings to chat with others",
  "changePhoneNumber": "Change phone number",
  "chargeable": "chargeable",
  "charactersLong": "characters long",
  "chargeWillBeDeductedFromRefund": "charge will be deducted from refund",
  "chatVideoRequest": "Chat video request",
  "checkoutHostProfile": "Checkout host profile",
  "checkYourTransactionDetails": "Check your transaction details",
  "chef": "Chef",
  "choose": "Choose",
  "chooseAValidLanguage": "Choose a valid language",
  "chooseAudioInput": "Choose audio input",
  "chooseAudioOutput": "Choose audio output",
  "chooseCategory": "Choose category",
  "chooseYourInterests": "Choose your interests",
  "chooseYourInterestsFromTheListGivenBelow": "Choose Your interests from the list given below",
  "chooseYourAvatar": "Choose your avatar",
  "chooseYourAIGeneratedAvatar": "Choose your AI generated avatar",
  "chooseEventPhoto": "Choose event photo",
  "city": "City",
  "city/town": "City/Town",
  "clear": "Clear",
  "clearAllFilters": "Clear all filters",
  "clickHereToActivate": "Click here to activate",
  "clickHereToFindPeople": "Click here to find people",
  "clickHereToPayForRequestedMeeting": "Click here to pay for requested meeting",
  "clickHereToSelectYourInterests": "Click here to select your interests",
  "clickHereToVerify": "Click here to verify",
  "clickHereToViewYourRequests": "Click here to view your requests",
  "clickHereToSendYourRequests": "Click here to Send your requests",
  "clickStartStreaming": "Click start streaming",
  "clickUploadIconToSave": "Click upload icon to save",
  "close": "Close",
  "codeSentTo": "Code sent to",
  "codeSentSuccessfully": "Code sent successfully",
  "co-Host": "Co-Host",
  "coHostAddedSuccessfully": "Co-Host added successfully",
  "coHostDeletedSuccessfully": "Co-Host deleted successfully",
  "collaborate": "Collaborate",
  "collaborators": "Collaborators",
  "comment": "Comment",
  "company": "Company",
  "companyLtd": "Company Ltd.",
  "Completed": "Completed",
  "confirm": "Confirm",
  "confirmationStatus": "Confirmation status",
  "confirmPassword": "Confirm password",
  "confirmPhoneNumber": "Confirm phone number",
  "continuePayment": "Continue Payment",
  "confirmThatYourPhoneNumberIs": "Confirm that your phone number is",
  "connect": "Connect",
  "connectionIssue": "Connection issue",
  "connecting": "Connecting",
  "connectingPeople": "Connecting people",
  "connectThroughVideo": "Connect through video",
  "connectWithPeople": "Connect with people",
  "connectingWithPeople": "Connecting with people",
  "connectWithRazorpay": "Connect with razorpay",
  "connectWithStripe": "Connect with stripe",
  "contact": "Contact",
  "contactInformation": "Contact information",
  "contactUs": "Contact us",
  "copyURL": "Copy URL",
  "cost": "Cost",
  "costMustBeAPositiveNumber": "Cost must be a positive number",
  "coverVideo": "Cover video",
  "country": "Country",
  "countryCode": "Country code",
  "craftedByTalentedCreators": "Crafted by talented creators",
  "create": "Create",
  "createNewSession": "Create new session",
  "createEvent": "Create event",
  "createConnectCollaborate": "Create. Connect. Collaborate",
  "createNewEvent": "Create new event",
  "createYourNewEvent": "Create your new event",
  "createYourEvent": "Create your event !",
  "createSessionsForYourEvent": "Create sessions for your event",
  "createSessions": "Create sessions",
  "forYourEvent": "for your event",
  "creators": "Creators",
  "credited": "Credited",
  "crop": "Crop",
  "cropAndSave": "Crop and save",
  'credit': 'Credit',
  "creditDetails": "Credit details",
  "debitDetails": "Debit details",
  "refundDetails": "Refund details",
  "currencyIsNotSupported": "Currency is not supported",
  "currencyPreference": "Currency preference",
  "customSearch": "Custom search",
  "convenienceFee": 'Convenience fee',
  "date": "Date",
  "dateAndTime": "Date and time",
  "dateAndTimeShouldNotEmpty": "Date and time should not be empty",
  "dateOfBirth": "Date of birth",
  "debit": "Debit",
  "debited": "Debited",
  "decline": "Decline",
  "declined": "Declined",
  "delete": "Delete",
  "deleteVideo": "Delete video",
  "deletedSuccessfully": "Deleted successfully",
  "deleteEvent": "Delete event",
  "deleteAccount": "Delete account",
  "deleteMyAccount": "Delete my account",
  "deleteOnMinimumLimit": "Delete On minimum limit",
  "deleteRequest": "Delete request",
  "describeYourPurposeOfTheRequest": "Describe your purpose of the request",
  "description": "Description",
  "details": "Details",
  "done": "Done",
  "detailsEntered": "Details entered",
  "doYouWantToEndTheActiveSession?": "Do You want to end the active session ?",
  "doYouWantToEndThisSession?": "Do you want to end this session ?",
  "doYouWantToLeaveAndEndMeeting?": "Do you want to leave and end meeting ?",
  "doYouWantToDeleteTheMeeting": "Do you want to delete this meeting ?",
  "doYouWantToDeleteThis": "Do you want to delete this",
  "duration": "Duration",
  "eachUser": "Each user",
  "earpiece": "Earpiece",
  "earningStatistic": "Earning statistic",
  "edit": "Edit",
  "endTimeShouldBeGreaterThanStartTime": "End time should be greater than start time",
  "editEvent": "Edit event",
  "editSession": "Edit session",
  "editRequest": "Edit request",
  "elaborateYourSubject": "Elaborate your subject",
  "email": "Email",
  "emailAddressesAreNotAllowed": "Email addresses are not allowed",
  "emailAlreadyInvited": "Email already invited",
  "emailID": "email ID",
  "email/Name": "Email / Name",
  "email/Username": "Email/username",
  "emailAndPhoneVerificationNotComplete": "Email and phone verification not complete",
  "emailPhoneAndFaceVerificationNotCompletedForThisUser": "Email, phone and face verification not completed for this user",
  "emailAndPhoneVerificationNotCompletedForThisUser": "Email and phone verification not completed for this user",
  "emailAlreadyRegistered": "Email already registered",
  "emailAlreadyVerified": "Email already verified",
  "emailCanBeMax50CharactersLong": "Email can be max 50 characters long",
  "emailOrPasswordIsIncorrect": "Email or password is incorrect",
  "emailVerification": "Email verification",
  "emailVerificationMailSendSuccessfully": "Email verification mail send successfully",
  "emailVerificationNotComplete": "Email verification not complete",
  "emailAndFaceVerificationNotCompletedForThisUser": "Email and face verification not completed for this user",
  "emailVerificationNotCompletedForThisUser": "Email verification not completed for this user",
  "end": "End",
  "endAt": "End at",
  "endCall": "End call",
  "endMeeting": "End meeting",
  "endsIn": "Ends in -",
  "endTime": "End time",
  "endTimeIsLessThanStartTime": "End time is less than start time",
  "engageDirectlyWithExperiencedPeople": "Engage directly with experienced people",
  "enterEmailsToStartInviting": "Enter emails to start inviting",
  "enterAValidDate": "Enter a valid date",
  "enterCode": "Enter code",
  "enterCodeSentToYour": "Enter code sent to your",
  "enterCodeSentToYourEmail": "Enter code sent to your email",
  "enterDate": "Enter date",
  "enterEmails": "Enter emails",
  "enterEmailsToInvite": "Enter emails to invite",
  "enteredPasswordsAreDifferent": "Entered passwords are different",
  "enteredRequestedUserEmailId": "Entered requested user email id",
  "enterOtherCategory": "Enter other category",
  "enteredYourEmailId": "Entered your email ID",
  "enterAValidSelfIntro": "Enter a valid self introduction",
  "enterAValidFacebookName": "Enter a valid facebook name",
  "enterAValidInstagramName": "Enter a valid instagram name",
  "enterAValidTwitterName": "Enter a valid twitter name",
  "enterAValidLinkedInName": "Enter a valid linkedIn name",
  "enterEmailID": "Enter email ID",
  "enterEmailIDAbove": "Enter email ID above",
  "enterEmailToSendInvitation": "Enter email to send invitation",
  "enterEventTitle": "Enter event title",
  "enterOTP": "Enter OTP",
  "enterOTPCode": "Enter OTP code",
  "enterPassword": "Enter password",
  "enterSomeOtherEmailIdToRegister": "Enter some other email ID to register",
  "enterThe6DigitCodeFromYourMail": "Enter the 6-digit code from your mail ",
  "enterThe6DigitCodeFromYourPhone": "Enter the 6-digit code from your phone ",
  "enterTheCardNumber": "Enter the card number",
  "enterTheNumber": "Enter the number",
  "enterValidCountryCode": "Enter valid country code",
  "enterValidCountryCodeAndPhoneNo": "Enter valid country code and phone number",
  "enterValidDateOrAgeMustBe": "Enter valid date or age must be",
  "enterValidEmailID": "Enter valid email ID",
  "enterYourCode": "Enter your Code",
  "enterYourEmailToReceive": "Enter your email to receive",
  "enterTheOtpReceivedOnMobile": "Enter the OTP received on mobile",
  "errorTryingToUpload": "Error trying to upload",
  "errorOccured": "Error Occured, Please Try again Later",
  "evening": "Evening",
  "event": "Event",
  "eventLanguage": "Event language",
  "gstinNumber": "GSTIN number",
  "gst": "GST",
  "enterYourGSTINNumber": "Enter your GSTIN number",
  "eventCreatedSuccessfully": "Event created successfully",
  "eventCreationAgreement": "Event creation agreement",
  "eventDate": "Event date",
  "eventDeletedSuccessfully": "Event deleted successfully",
  "eventDescription": "Event description",
  "EventEndTimeIsLessThanTheStartTime": "Event end time is less than the start time",
  "eventImageDeletedSuccessfully": "Event Image deleted successfully",
  "eventImageUploadedSuccessfully": "Event Image uploaded successfully",
  "eventDescriptionShouldBeMax2000CharactersLong": "Event description should be max 2000 characters long",
  "eventDetails": "Event details",
  "eventName": "Event name",
  "eventOn": "Event on",
  "eventPolicyAgreed": "Event policy agreed",
  "events": "Events",
  "eventRequirements": "Event requirements",
  "eventReviews": "Event reviews",
  "eventDurationMaxMinutes": "Event duration max {{maxDuration}} minutes",
  "eventTime": "Event Time",
  "eventTitleShouldBeMax100Characters": "Event title should be max 100 characters",
  "eventTitleShouldBeMin2Characters": "Event title should be min 2 characters",
  "eventTitleShouldBeMin20Characters": "Event title should be min 20 characters",
  "eventType": "Event Type",
  "eventUpdatedSuccessfully": "Event updated successfully",
  "eventVideoUploadedSuccessfully": "Event video uploaded successfully",
  "eventVideoDeletedSuccessfully": "Event video deleted successfully",
  "expandYour": "Expand Your",
  "expandYourHive": "CREATE | CONNECT <br> COLLABORATE",
  "expandYourSocialNetwork": "Expand your social network",
  "expectedTotalMeetingPrice": "Expected total meeting price",
  "expired": "Expired",
  "expiryMonth": "Expiry month",
  "explore": "Explore",
  "exploreMoviesFromVariousGenres": "Explore movies from various genres",
  "excluded" : "Excluded",
  "freeEvent": "Free event",
  "faceToFace": "Face-to-face",
  "facebookAccountName": "Facebook account name",
  "Failed": "Failed",
  "favorite": "Favorite",
  "favoriteHosts": "Favorite hosts",
  "faceVerification": "Face Verification",
  "faceVerificationNotCompletedForThisUser": "Face verification not completed for this user",
  'faceVerificationShouldBeCompletedToGetVerifiedFurther': 'Face verification & all the social media profile should be entered and completed to get verified further',
  "feedback": "Feedback",
  "feedbacks": "Feedbacks",
  "feedbackCanBeMax500CharactersLong": "Feedback can be max 500 characters long",
  "feedbackRegisteredSuccessfully": "Feedback registered successfully",
  "female": "Female",
  "festiveSeasonPlans": "Festive season plans",
  "filter": "Filter",
  "fileName": "File name",
  "fileType": "File type",
  "findSomeoneToTextTo...": "Find someone to text to...",
  "firefoxMeetingError": "Firefox is not supported, Please open in a different Browser",
  "firstName": "First name",
  "firstNameCanBeMax20CharactersLong": "First name can be max 20 characters long",
  "findSomeoneToTextTo": "Find someone to text to...",
  "flatAsPlatformFeeByDefault": "Flat as platform fee by default!",
  "for": "For",
  "forgotPassword": "Forgot password ?",
  "forThisEvent": "for this event",
  "freeAudio": "Free audio",
  "freeAudioEvent": "Free Audio event",
  "freeCancellation": "Free cancellation",
  "freeCancellationBefore": "Free cancellation before",
  "freeEvents": "Free events",
  "freeEventCancellation": "Free event cancellation",
  "freeMeetingRequest": "Free meeting request",
  "freePrice": "Free price",
  "freeRequest": "Free request",
  "from": "From",
  "fromDate": "From date",
  "fromTimeShouldBeLessThanToTime": "From time should be less than To time",
  "fullRefund": "Full refund",
  "fullFee": "FULL fee",
  "fromYourRefundAmount": "from your refund amount",
  "futureMeetings": "Future meetings",
  "gender": "Gender",
  "generateAvatar": "Generate avatar",
  "generating": "Generating",
  "generateAvatarImage": "Generate avatar image",
  "getInTouch": "Get in touch",
  "getYourEmailAndPhoneNumberVerifiedToAddMeeting": "Get your Email and Phone Number verified to add meeting",
  "getYourEmailAndPhoneNumberVerifiedToSendRequestForMeeting": "Get your Email and Phone Number verified to request for meeting",
  "globalCreators": "Global creators",
  "goBack": "Go back",
  "greaterThanOrEqualTo8Characters": "Greater than or equal to 8 characters",
  "groupchat": "Group Chat",
  "guest": "Guest",
  "guestCount": "Guest count",
  "guestList": "Guest list",
  "guests": "Guests",
  "guestsHaveBeenInvited": "guests have been invited",
  "guestsInvitedSuccessfully": "Guests invited successfully",
  "guestsWhomYouPaidToAttendTheEventUsingBeeMG": "guests whom you paid to attend the event using BeeMG.",
  "handraise": "Hand Raise",
  "hasRaisedTheirHand": "has raised their hand",
  "haveAChat": "Have a chat",
  "headset": "Headset",
  "help": "Help",
  "heldOn": "Held on",
  "hi": "Hi",
  "higherThanLastMonth": "higher than last month",
  "history": "History",
  "hive": "HIVE",
  "home": "Home",
  "host": "Host",
  "hostCancellationFee": "Host cancellation fee",
  "hostTimeZone": "Host Timezone",
  "hostAnonymously": "Host anonymously",
  "hostHaveChosen": "Host have chosen",
  "hostedBy": "Hosted by",
  "hostIntroduction": "Host introduction",
  "hostGrantedPermissionToTalk": "Host granted permission to talk",
  "hostRemovedPermissionToTalk": "Host removed permission to talk",
  "hour": "Hour",
  "hours": "Hours",
  "hoursRemaining": "hours remaining",
  "IAcceptThePrivacyPolicyConditions": "I accept the privacy policy conditions",
  "IAcceptTheTermsAndConditions": "I accept the terms and conditions",
  "IFSC": "IFSC",
  "IFSCCode": "IFSC Code",
  "IFSCCodeShouldBeValid": "IFSC code should be valid",
  "IfYouAgreeToTheAbove,PleaseClickNext": "If you agree to the above, please click Next",
  "IfYouAgreeToThisCancellationPolicyTermsAndConditions,YouCanGoThroughTheRequest": "If you agree to this Cancellation policy terms and conditions, you can go through the request",
  "ifYouCancelBetween": "If you cancel between",
  "ifYouCancelThisMeeting": "If you cancel this meeting",
  "ifYouCancelThisMeetingBefore": "If you cancel this meeting before",
  "ifYouCancelWith": "If you cancel with",
  "ifYouCancel": "If you cancel",
  "ifYouAgreeToTheAbove,PleaseTapSend": "If you agree to the above, please tap Send",
  "ifYouAgreeToTheAbove,PleaseTapNext": "If you agree to the above, please tap Next",
  "iHaveReadAndAgreedToThe": "I have read and agreed to the",
  "inconvenienceFee": 'Inconvenience fee',
  "identityVerified": "Identity verified",
  "imageSizeLimitIs": "Image size limit is",
  "imagesOrderSavedSuccessfully": "Images order saved successfully",
  "imageFormatsOf.PNGAnd.jpegOnlySupported": "Image formats Of .PNG and .jpeg only supported",
  "inProgress": "In progress",
  "insideStreamPasteTheSecretKeyAndClickOk": "Inside stream, paste the secret key and click OK",
  "instagramAccountName": "Instagram account name",
  "instructions": "Instructions",
  "interest": "Interest",
  "interested": "Interested",
  "interestedEvents": "Interested events",
  "interests": "Interests",
  "interestsAlreadyExits": "Interests already exits",
  "internet": "Internet",
  "internetAccess–BeeMGUsesVideoCommunication": "Internet Access – BeeMG platform uses Audio/Video communication to connect people, so you will require a High-speed Internet connection with Audio and Video features enabled in your Computer, Smartphone or Tablet.",
  "introductionCanBeMax1500CharactersLong": "Introduction can be max 1500 characters long",
  "InvalidCharacters": "Invalid characters",
  "invalidEmail": "Invalid email",
  "invalidInput": "Invalid input",
  "invalidPhoneNumber": "Invalid phone number",
  "invalidTime": "Invalid time",
  "invalidTimings": "Invalid timings",
  "invalidToken": "Invalid token",
  "invitationsLeft": "Invitations left",
  "invitations": "Invitations",
  "invitation": "invitation",
  "invite": "Invite",
  "invited": "Invited",
  "inviteCountExceeded": "Invite count exceeded",
  "inviteFrom": "Invite from",
  "inviteGuests":"invite guests",
  "invitedBeemgGuests": "Invited BeeMG guests",
  "invitedForEvent": "Invited for event",
  "invitedOn": "Invited on",
  "invitedSuccessfully": "Invited successfully",
  "invitedYouTo": "invited you to",
  "inviteEmail must be an email": "Invite Email must be a valid Email",
  "yourInvitees": "Your invitees",
  "invitedGuests": "Invited guests",
  "inviteNewGuest": "Invite new guest",
  "invitePeople": "Invite people",
  "inviteYourFriendsForThisEvent": "Invite your friends for this event",
  "inviteYourFriends": "Invite your friends",
  "inviteRemaining": "Invite remaining",
  "ipAddress": "IP address",
  "iPadMeetingError": "Please Use BeeMG mobile app to join the meeting",
  "isApplied": "is applied",
  "isDeducted": "is deducted",
  "isAskingToSpeak": "is asking to speak",
  "isFreeAudioMeeting": "Free audio meeting",
  "included": "Included",
  "join": "Join",
  "joinAnonymous": "Join as anonymous user",
  "joined": "Joined",
  "Joined": "JOINED",
  "joinTheMeeting": "Join the meeting",
  "joinUsingYourComputer/Tablet/Smartphone": "Join using your Computer/ Smartphone / Tablet",
  "joinZoom": "Join meeting",
  "justNow": "Just now",
  "kidFriendly": "Kid friendly",
  "kidsEvents": "Kids events",
  "kidsOnly": "Kids only",
  "kindlyGiveYourFeedback": "Kindly give your feedback",
  "language": "Language",
  "lastName": "Last name",
  "lastNameCanBeMax20CharactersLong": "Last name can be max 20 characters long",
  "latestEvents": "Latest Events",
  "latestUpdatedEvent": "Latest updated event",
  "launchNotification": "launch notification",
  "leave": "Leave",
  "lessThan": "less than",
  "lessThanOrEqualTo25Characters": "Less than or equal to 25 characters",
  "limit": "Limit",
  "limitedTo": "Limited to",
  "limitAlert": "Limit alert",
  "limitReached": "Limit reached",
  "littlescape": "Littlescape",
  "linksAreNotAllowed": "Links are not allowed",
  "linkedInAccountName": "LinkedIn account name",
  "liveEvents": "Live events",
  "login": "Log in",
  "loginSuccessful": "Login successful",
  "makeThisEventAsAudioOnly": "Make this event as audio only",
  "makeThisEventStreaming": "Make this event streaming",
  "makeSureThatYouHave": "Make sure that you have No active meetings paid events & No pending settlements or requests.",
  "male": "Male",
  "match": "Match",
  "maximum": "Maximum",
  "maximum1VideoOfLessThan": "Maximum 1 video of less than",
  "maximum5ImagesOfLessThan": "Maximum 5 images of less than",
  "maximumUsersIs": "Maximum users are {{maxParticipants}}",
  "maximumPrice": "Maximum price",
  "maximumPriceIs": "Maximum price is {{maxPrice}}",
  "maximumLimitForRequestIs": "Maximum limit for request is",
  "maybe": "Maybe",
  "mb": "MB",
  "meet": "Meet",
  "meeting": "Meeting",
  "meetingCompleted": "Meeting completed",
  "meetingOngoingDisconnectToJoin": "Meeting ongoing, disconnect to join",
  "meetingDate": "Meeting date",
  "meetingDeleted": "Meeting deleted",
  "meetingDeletedSuccessfully": "Meeting deleted successfully",
  "meetingDetails": "Meeting details",
  "meetingDuration": "Meeting duration",
  "meetingDurationForAudioEventIs": "Meeting duration for audio Event is",
  "meetingDurationForVideoEventIs": "Meeting duration for video Event is",
  "meetingEnded": "Meeting ended",
  "meetingHasBeenCancelledBy": "Meeting has been cancelled by",
  "meetingHostedBy": "Meeting hosted by",
  "meetingID": "Meeting ID",
  "meetingList": "Meeting list",
  "meetingNotificationRemainder": "Meeting notification remainder",
  "meetingOver": "Meeting over",
  "meetingRequest": "Meeting request",
  "meetingRequestReceivedFrom": "Meeting request received from",
  "meetings": "Meetings",
  "meetingScheduledSuccessfully": "Meeting scheduled successfully",
  "meetingRequestSentTo": "Meeting request sent to",
  "meetingsRemaining": "Meetings remaining",
  "meetingStartsAt": "Meetings starts at",
  "meetingUpdatedSuccessfully": "Meeting updated successfully",
  "meetingURL": "Meeting URL",
  "meetingWillEndIn": "Meeting will end in",
  "meetingWith": "Meeting with",
  "message": "Message",
  "messages": "Messages",
  "messageCanBeMax": "Message can be max",
  "mic": "Mic",
  "microphoneOn": "Microphone on",
  "microphoneOff": "Microphone off",
  "midnight": "Midnight",
  "minimum": "Minimum",
  "minimumAmount": "Minimum amount",
  "minimumLimitSet": "Minimum limit set",
  "minimumNoOfUsers": "Minimum number of users",
  "userShouldBeMoreThanTheGivenCount": "User should be more than the given count {{minParticipants}}",
  "minimumOfUsersMustBeAtleast1": "Minimum of users must be atleast 1",
  "minimumUsersLimitNotReached": "Minimum users limit not reached",
  "minimumPrice": "Minimum price",
  "maximumPriceNeedToBeGreaterThanMinimumPrice": "Maximum Price need to be greater than Minimum Price",
  "minimumPriceShouldBeGreaterThanZero": "Minimum Price should be greater than Zero",
  "maximumPriceShouldBeGreaterThanZero": "Maximum Price should be greater than Zero",
  "maximumPriceCanBe": "Maximum Price Can Be {{maxPrice}}",
  'minimumPriceShouldBeLessThanMaxPrice': 'Minimum Price Should Be Less Than Max Price',
  "min": "min",
  "minute": "Minute",
  "minutes": "Minutes",
  "mobileNumbersAreNotAllowed": "Mobile numbers are not allowed",
  "month": "Month",
  "more": "More",
  "moreThanTotalUsers": "More than total Users",
  "moreInvitationsLeft": "More invitations left",
  "morning": "Morning",
  "multiSession": "Multi session",
  "multipleSessionVideoMeeting": "Session ended due to multiple sessions",
  "musician": "Musician",
  "mute": "Mute",
  "muteAll": "Mute all",
  "myEvents": "My events",
  "mySettings": "My settings",
  "myProfile": "My profile",
  "name": "Name",
  "needOfRequest": "Need Of Request",
  "needOfRequestCanBeMax150CharactersLong": "Need of request can be max 150 characters long",
  "netTotal": "Net Total",
  "netTransactionAmount": "Net transaction amount",
  "network": "network",
  "new": "New",
  "newArrival": "New arrival",
  "newSchedule": "New schedule",
  "next": "Next",
  "nextEventStartsIn": "Next event in",
  "nextScheduledDateOn": "Next scheduled date on",
  'next10Days': 'Next 10 days',
  "nickName": "Nick name",
  "nickNameCanBeMax25CharactersLong": "Nick name can be max 25 characters long",
  "no": "No",
  "noCancellationCharge": "No cancellation charge",
  "noDebitTransactions": "No debit transactions",
  "noCreditTransactions": "No credit transactions",
  "noEvents": "No events",
  "noEventsToDisplay": "No events to display",
  "noFeedbacks": "No feedbacks",
  "noFiltersToApply": "No filters to apply",
  "noMeetingUsersToDisplay": "No meeting users to display",
  "noMeetings": "No meetings",
  "notAMember": "Not a member",
  "noTransactionFound": "Transaction not found",
  "Non-registeredUsersShouldCheckMailAndThenRegisterInBeeMGAtTheEarliestBeforeTheEventBegins.": "Non-registered users should check mail and then register in BeeMG at the earliest before the event begins.",
  "noOfUsers": "No of Users",
  "noOfUsersMustBeAtleast1": "Number of users must be atleast 1",
  "noOfUsersMustBeANumber": "Number of users must be a number",
  "notSupportedFileFormat": "Not supported file format",
  "noRefundTransactionFound": "No refund transaction found",
  "userShouldBeMoreThanGivenCount": "User should be more than the given count",
  "userProfile": "User profile",
  "noMoreInvitationsLeft": "No more invitations left",
  "noon": "Noon",
  "noUsers": "No users",
  "noRecords": "No records",
  "noRefund": "No refund",
  "noReviewsForTheEvent": "No reviews for the event",
  "noReviewsForTheMeeting": "No reviews for the meeting",
  "noSessions": "No sessions",
  "noschedules": "No schedules available !",
  "notAccepted": "Not accepted",
  "note": "Note",
  "notification": "Notification",
  "notify": "Notify",
  "notifyMeWhenItsUpAndRunning": "Notify me when it's up and running",
  "notJoined": "NOT JOINED",
  "notifyThem": "Notify them",
  "noTransactions": "No transactions",
  "notRegistered": "not registered",
  "notRegisteredUser": "Not registered user",
  "noUpcomingEvents": "No upcoming events",
  "numberOfFreeMeetingsRemaining": "Number of free meetings remaining",
  "numberOfUsers": "Number of users",
  "noResultsFoundForTheName": "No results found for the name.",
  "noRelatedDebitTransactionFound" : "No related debit transaction found",
  "normal" : "Normal",
  "net": "Net",
  "ok": "Ok",
  "ofAMultitudeOfASelectedCategory": "of a multitude of a selected category.",
  "offline": "Offline",
  "ofTheEventwillNotBeRefunded": "of the event, Amount will not be refunded",
  "ofTheTotalFee": "of the total fee",
  "on": "on",
  "oneToOneMeeting": "One-One meeting/ Request for meeting",
  "onGoingMeeting": "On going meeting",
  "online": "Online",
  "onRightBottomClickOnSettingsInsideControlsMenu": "On right bottom, click on settings inside controls menu",
  "openOBSApplication": "Open OBS application",
  "option": "Option",
  "options": "Options",
  "orAbove": "or above",
  "others": "Others",
  "otpResentSuccessfully": "OTP resent successfully",
  "otpSentSuccessfully": "OTP sent successfully",
  "paid": "Paid",
  "paidOn": "Paid on",
  "paidAudio": "Paid audio",
  "paidVideo": "Paid video",
  "paidBy": "Paid By",
  "paidFor": "Paid For",
  "paidRequest": "Paid request",
  "pleaseTryADifferentSearchTerm": " Please try a different search term.",
  "userConfirmation": "User confirmation",
  "usersAreThere": "Users are there",
  "usersMustBeAnInteger": "Users must be an integer",
  "userIsThere": "User is there",
  "participants": "Participants",
  "password": "Password",
  "passwordHasBeenReset": "Password has been reset",
  "participantIsThere": "Participant is there",
  "participantsAreThere": "Participants are there",
  "participantsMeeting": "Participants meeting",
  "participantMeeting": "Participant meeting",
  "passwordDoesnotMatch": "Password doesn't match",
  "password must be at least 8 characters": "Password must be at least 8 characters",
  "password must contain at least 1 number": "Password must contain at least one number",
  "password must contain at least 1 symbol": "Password must contain at least one symbol",
  "password must contain at least 1 uppercase letter": "Password must contain at least one uppercase letter",
  "password must contain at least 1 lowercase letter": "Password must contain at least one lowercase letter",
  "pastEvents": "Past events",
  "pastSchedules": "Past schedules",
  "pay": "Pay",
  "payAmount": "Pay amount",
  "paymentDetails": "Payment details",
  "paymentMethodIsNotActiveForThisUser": "Payment method is not active for this user",
  "paymentSuccessful": "Payment successful",
  "payNow": "Pay Now",
  "payNowForRequestedMeeting": "Pay Now for requested meeting",
  "pending": "Pending",
  "people": "people",
  "peopleLikeYou": "People like you",
  "peopleNearYou": "People near you",
  "peopleYouMetRecently": "People you met recently",
  "person": "person",
  "phone": "Phone",
  "phoneNo": "Phone number",
  "phoneNumberVerification": "Phone number verification",
  "phoneNumberMustBeAValidPhoneNumber": "Phone number must be a valid phone number",
  "phoneVerificationNotComplete": "Phone verification not complete",
  "phoneAndFaceVerificationNotCompletedForThisUser": "Phone and face verification not completed for this user",
  "phoneVerificationNotCompletedForThisUser": "Phone verification not completed for this user",
  "pin": "Pin",
  "plannedTime": "Planned time",
  "platformFee": "Platform fee",
  "platformFee+stripeFee": "Platform fee + Stripe fee",
  "pleaseCheckYourEmailToVerify": "Please check your email to verify",
  "pleaseChooseYourInterestsToFindPeopleLikeYou": "Please choose your Interests to find people like you",
  "pleaseConfirmRequestDetails": "Please confirm request details",
  "pleaseConfirmYourPassword": "Please confirm your password",
  "pleaseEnterEmailsAndPressEnter": "Please enter emails and press enter",
  "pleaseGiveCorrectPrice": "Please give correct price",
  "pleaseSelectOneCategory": "Please select one category",
  "pleaseStateTheReason": "Please state the reason",
  "pleaseVerifyYourAccount": "Please verify your account",
  "pleaseUpload5ImagesAndaVideo": "Please upload 5 images and a video",
  "PleaseCheckWithYourBank": `***** Terms and Conditions apply *****\n\nPlease check with your bank for the latest conversion rates. The rates displayed on the website are for demonstration purposes. BeeMG holds no liability for any changes in conversion rates.`,
  "pleaseTryAgainLater": "Please try again later",
  "pointsToBeNoted": "Points to be noted",
  "popularGroupEvents": "Popular group events",
  "popularInYourCountry": "Popular in your country",
  "popularity": "Popularity",
  "post": "Post",
  "purpose": "Purpose",
  "purposeForYourprivateSession": "Purpose for your private session",
  "preference": "Preference",
  "price": "Price",
  "priceBreakDown": "Price breakdown",
  "priceMustBeAnInt": "Price must be an int",
  "priceMustBeGreaterThan": "Price must be greater than or equal to {{minPrice}}",
  "priorThingsNeeded": "Prior things needed",
  "privateChat": "Private chat",
  "previousTransactionId": "Previous transaction ID",
  "privateSessionRequest": "Private session request",
  "theminimumAmountCanBeOf": "The minimum amount can be of {{minPrice}} {{currency}}",
  "pricing": "Pricing",
  "print": "Print",
  "privacyPolicy": "Privacy policy",
  "privacyPolicyUpdatedSuccessfully": "Privacy policy updated successfully",
  "privacyPolicyAcceptedSuccessfully": "Privacy policy accepted successfully",
  "proceed": "PROCEED",
  "profile": "Profile",
  "profileInformation": "Profile information",
  "profileImage": "Profile image",
  "profileImageDeletedSuccessfully": "Profile image deleted successfully",
  "profileImageUploadedSuccessfully": "Profile image uploaded successfully",
  "profileUpdatedSuccessfully": "Profile updated successfully",
  "queueLength": "Queue length",
  "rating": "Rating",
  "raiseYourHandAndWaitForTheHostToUnmuteYou": "Raise your hand and wait for the host to unmute you",
  "razorpayAccountDetails": "Razorpay account details",
  "razorpayAccountIsActive": "Razorpay account is active",
  "processingFee": "Processing Fee",
  "razorpayProcessingFee": "Razorpay processing fee",
  "raisedTheirHand": "Raised their hand",
  "reachedForToday": "reached for today",
  "readLess": "Read less",
  "readMore": "Read more",
  "reason": "Reason",
  "reasonCanBeMax500CharactersLong": "Reason can be max 500 characters long",
  "reasonCanBeMax800CharactersLong": "Reason can be max 800 characters long",
  "feedbackReplyCanBeMax500CharactersLong": "Feedback reply can be max 500 characters long",
  "received": "Received",
  "receivedFrom": "Received from",
  "receivedOn": "Received On",
  "receivedRequests": "Received",
  "recommendedToAdd5Images": "Recommended to add 5 images",
  "redirecting": "Redirecting",
  "reEnterPassword": "Re-enter password",
  "regarding": "Regarding",
  "regd.user": "Regd.User",
  "register": "Register",
  "registerFor": "Register for",
  "registered": "Registered",
  "registration": "Registration",
  "registerForInvite": "Register for invite",
  "registerForPublicLaunch": "Register for public launch",
  "registerUser": "Registered user",
  "registeredSuccessfully": "Registered successfully",
  "registeredUsersShouldCheck’AttendingEvents’ToAcceptTheInvitation": "Registered users should check 'attending events' to accept the invitation",
  "remove": "Remove",
  "reject": "Reject",
  "rejected": "Rejected",
  "rejectRequest": "Reject request",
  "rejoin": "Rejoin",
  "replied": "Replied",
  "repliedSuccessfully": "Replied successfully",
  "reply": "Reply",
  "replyShouldBeMax800Characters": "Reply should be max 800 characters",
  "reportAbuse": "Report Abuse",
  "reportedSuccessfully": "Reported successfully",
  "reportProfile": "Report profile",
  "reportUser": "Report user",
  "request": "Requests",
  "requestARefundFor": "Request a refund for",
  "requestInProgress": "Request in progress",
  "requestRaised": "Request raised",
  "requestFor": "Request for",
  "requestCancellation": "Request cancellation",
  "requestCancelledSuccessfully": "Request cancelled cuccessfully",
  "requestConfirmation": "Request confirmation",
  "requestAcceptedSuccessfully": "Request accepted successfully",
  "requestDeclinedSuccessfully": "Request declined Successfully",
  "requestDeletedSuccessfully": "Request deleted successfully",
  "requestPrivateSession": "Request private session",
  "requestForMeetingConfirmation": "Request for meeting confirmation",
  "requestForPartialCancellation": "Request for partial cancellation",
  "requestReceivedFrom": "Request received from",
  "requestRejectedSuccessfully": "Request rejected successfully",
  "requestedBy": "Requested by",
  "requestedDateAndTime": "Requested date and time",
  "requestedForAMeeting": "Requested for meeting",
  "requestedOn": "Requested on",
  "requesterCanPay": "Requester can pay",
  "requestForCancellation": "Request for cancellation",
  "requestForBlueTick": "Request for blue tick verification",
  "requestedForBlueTickVerification": "Requested for blue tick verification",
  "requestedForCancellation": "Requested for cancellation",
  "requestForMeeting": "Request for meeting",
  "requestPartialCancellation": "Request partial cancellation",
  "requestRejected": "Request rejected",
  "requests": "Requests",
  "requestSeen": "Request seen",
  "requestSentSuccessfully": "Request sent successfully",
  "requestSent": "Request sent",
  "requestSentTo": "Request sent to",
  "requestedSession": "Requested session",
  "requestType": "Request type",
  "requestUpdatedSuccessfully": "Request updated successfully",
  "privateSessionRequestAsAnonymous": "Private session request as (Anonymous)",
  "required": "Required",
  "requirements": "Requirements",
  "resend": "Re-send",
  "refundRequest": "Refund request",
  "resendCode": "Resend code",
  "resendInvitation": "Re-send invitation",
  "resendInviteSentSuccessfully": "Resent Invite mail successfully",
  "resendVerificationMail": "Resend verification mail",
  "resendMail": "Resend mail",
  "reset": "Reset",
  "resetLinkHasBeenSentToYourEmail": "Reset link has been sent to your email",
  "resetPassword": "Reset password",
  "resetPasswordLinkWillBeSentToYourEmail": "Reset Password link will be sent to your email",
  "goHereAndEnterYourPreviousPasswordToResetThePassword": "Go here and enter your previous password to reset the password",
  "reviewFor": "Review for",
  "reviews": "Reviews",
  "reviewShouldBeMax800Characters": "Review should be max 800 characters",
  "salesOrder": "Sales order",
  "save": "Save",
  "saveAndUploadImagesOrVideo": "Save and upload images or video",
  "saveEventDetails": "Save event details",
  "saveOrder": "Save order",
  "saveProfile": "Save profile",
  "sayHello": "Say Hello!",
  "schedule": "Schedule",
  "scheduledDateIsDeleted": "Scheduled date is deleted",
  "scheduledDateisExpired": "Scheduled date is expired",
  "scheduledEvents": "Scheduled events",
  "scheduledMeetings": "Scheduled meetings",
  "scheduledOn": "Scheduled on",
  "scheduledTime": "Scheduled time",
  "scheduleSessions": "Schedule sessions",
  "screenShare": "Screenshare",
  "screenShareOn": "Screenshare On",
  "screenShareOff": "Screenshare Off",
  "scrollToTop": "Scroll to top",
  "searchByCountry": "Search by country",
  "searchByName": "Search by name",
  "searchByTitle": "Search by title",
  "secretKey": "Secret key",
  "security": "Security",
  "select": "Select",
  "selectAnAvailableMeetingOrRequestForANewOne": "Select an available meeting or request for a new one",
  "selectAValidDate": "Select a valid date",
  "selectedDateisExpired": "Selected date is expired",
  "selectedTimeSlotIsExpired": "Selected time slot has expired ",
  "selectMultipleCategoryFromTheListandSubCategoryIfPresent": "Select multiple categories from the list and sub-category if present.",
  "selectNameFromTheList": "Select name from the list",
  "selectTheUsersForCancellation": "Select the users for cancellation",
  "selectUsersForCancellation": "Select users for cancellation",
  "self": "Self",
  "selfIntro": "Self intro",
  "selfIntroduction": "Self introduction",
  "send": "Send",
  "sendOTP": "Send OTP",
  "sendRequest": "Send request",
  "sendRequestRFM": "Send request",
  "sent": "Sent",
  "sentRequests": "Sent",
  "series": "Series",
  "sessionOptions": "Session options",
  "setMaxPrice*": "Set max price *",
  "setMinPrice*": "Set min price *",
  "setRemainder": "Set reminder",
  "settings": "Settings",
  "settingsUpdatedSuccessfully": "Settings updated successfully",
  "session": "Session",
  "sessionRequests": "Session requests",
  "sessions": "Sessions",
  "scheduleMeetings": "Schedule meetings",
  "share": "Share",
  "speaker": "Speaker",
  "shareAbout": "Share about",
  "shareEvent": "Share event",
  "shareProfile": "Share profile",
  "shareHostProfile": "Share host profile",
  "shouldBeGreaterThan18": "Should be greater than 18",
  "showLess": "Show less",
  "showMore": "Show more",
  "showUpOnOtherUsersOnlineList": "Show up on other users online list",
  "sightundermaintenance": "Sight under maintenance",
  "notifyMeWhenItIsUpAndRunning": "Notify me when it is up and running",
  "signIn": "Sign in",
  "signInWith": "Sign in with",
  "signInWithG": "Sign in with G",
  "signOut": "Sign out",
  "signUp": "Sign up",
  "signUpWith": "Sign up with",
  "siteUnderMaintenance": "Site under maintenance",
  "size": "Size",
  "sizeEachCanBeAdded.": "size can be added.",
  "skipFaceVerification": "Skip face verification",
  "socialMedia": "Social media",
  "socialNetwork": "social network",
  "socialNetworkThatProvides.....": "social network that provides.....",
  "sorry...!HostHaveAnotherSessionAtTheTime": "Sorry...! Host have another session at the time",
  "soldOut": "Sold out",
  "specificReasonsforCancellationIfAny": "Specific reasons for cancellation, if any",
  "specificReasonsForCancellation": "Specific reasons for cancellation",
  "somethingWentWrong": "Something went wrong",
  "start": "Start",
  "startTyping": "Start typing",
  "staged": "Staged",
  "startAt": "Start at",
  "started": "Started",
  "startMeeting": "Start meeting",
  "startingInNext6Hours": "In the next 6 hours!",
  "startingNextDay": "The next day",
  "laterToday": "Later today",
  "startInviting": "Start inviting!",
  "startTime": "Start time",
  "startTimeAndEndTimeCannotBeSame": "Start time and end time cannot be same",
  "status": "Status",
  "stepsToBeFollowedForStreamingSetup": "Steps to be followed for streaming setup",
  "stripAccountIsActive": "Stripe account is active",
  "stripeNotSupportedInYourCountry": "stripe not supported in your country",
  "stripeIsNotActiveForThisUser": "Stripe is not active for this user",
  "stripeProccessingFee": "Stripe proccessing fee",
  "suitableForAgeGroupsFrom6-12": "Suitable for age groups from 6-12",
  "suitableForAgeGroupsFrom6-12&AdultsAsWell": "Suitable for age groups from 6-12 & adults as well",
  "success": "Success",
  "subject": "Subject",
  "subjectCanBeMax100CharactersLong": "Subject can be max 100 characters long",
  "submit": "Submit",
  "Submitted": "Submitted",
  "suggestions": "Suggestions",
  "summary": "Summary",
  "switchAudioOutput": "Switch Audio Output",
  "support": "Support",
  "supportedImageFormats.JPG,.JPEG,.PNG,.BMP": "Supported image formats: .JPG, .JPEG, .PNG, .BMP",
  "supportedVideoFormats.MP4": "Supported video formats: .MP4",
  "taxDetails": "Tax details",
  "taxCalculationOnBaseAmount": "Tax calculation on base amount",
  "taxForPlatformFee": "Tax for platform fee",
  "terms": "Terms",
  "termsAndConditions": "Terms and conditions",
  "termsAndConditionsUpdatedSuccessfully": "Terms and conditions updated successfully",
  "termsAndConditionsAcceptedSuccessfully": "Terms and conditions accepted successfully",
  "thankYouForProvidingTheDetails.We’llNotifyYouAboutThePublicLaunchThroughTheRegisteredEmail": "Thank you for providing the details. We’ll notify you about the public launch through the registered email",
  "thatIsReachedForToday": "that is reached for today",
  "eventHostIsOpenToConnect": "Event host is open to connect",
  "theCreators": "The Creators'",
  "thingsNeeded/ToBeDoneInAdvance": "Things needed or to be done in advance",
  "thingsToKnow": "Things to Know",
  "ThisShouldNotBeaNumberAlone": "This should not be a number alone",
  "thisWeek": "This week",
  "thisMonth": "This month",
  "TheMinimumEntryAgeIs18": "The minimum entry age is 18",
  "time": "Time",
  "times": "times",
  "timeZone": "Time zone",
  "title": "Title",
  "to": "to",
  "today": "Today",
  "toDate": "To date",
  "toHost": "To host",
  "tokenExpired": "Token expired",
  "topTrending": "Top trending",
  "toShareYourScreenKindlyRequestTheHostToUnmuteYou": "To share your screen, kindly request the host to unmute you",
  "total": "Total",
  "totalAmountCredited": "Total amount credited",
  "totalAmountDeducted": "Total amount deducted",
  "totalAmount": "Total amount",
  "totalUsers": "Total users",
  "totalPrice": "Total price",
  "totalCostOf": "Total cost of",
  "transactionID": "Transaction ID",
  "transactionDate": " Transaction date",
  "transactionAmount": "Transaction amount",
  "transactionDetails": "Transaction details",
  "transactionDescription": "Description",
  "transactionHistory": "Transaction history",
  "transactionList": "Transaction list",
  "transactions": "Transactions",
  "transactionStatus": "Transaction status",
  "twitterAccountName": "Twitter account name",
  "type": "Type",
  "typeAMessage": "Type a message",
  "typeEventTitle": "Type event title",
  "typeYourPreferredLanguage": "Type your preferred language",
  "typeYourTextHere": "Type your text here",
  "thanks": "Thanks",
  "totalTaxes" :"Total taxes",
  "totalFee" :"Total fee",
  "unitPrice": "Unit price",
  "unitPriceMustBeaPositiveNumber": "Unit price must be a positive number",
  "unmute": "UnMute",
  "unmuteAll": "Unmute all",
  "unpin": "Unpin",
  "unBlock": "Unblock",
  "unBlockTheUserToChat": "Unblock the user to chat",
  "unBlockTheUserToSendVideoRequest": "Unblock the user to send video request",
  "unBlockTheUserToSendChatRequest": "Unblock the user to send chat request",
  "under": "Under",
  "upcoming": "Upcoming",
  "upcomingMeeting": "Upcoming meeting",
  "updateAnonymousProfile": "Update anonymous profile",
  "updatedSuccessfully": "Updated successfully",
  "updateRequiredFields": "Update required fields",
  "upload": "Upload",
  "uploaded": "Uploaded",
  "uploadEventImage": "Upload event image",
  "uploadEventVideo": "Upload event video",
  "uploadImageAndVideo": "Upload image and video",
  "uploadVideo": "Upload Video",
  "upcomingEvents": "Upcoming events",
  "userAlreadyInvited": "User already invited",
  "User not found": "User not found",
  "userBlockedSuccessfully": "User blocked successfully",
  "user": "User",
  "userEvents": "events",
  "userForRfm": "user",
  "usersForRfm": "users",
  "userId": "User ID",
  "userList": "User list",
  "users": "Users",
  "upcomingSessions": "Upcoming sessions",
  "userShouldCheckAttendingEventsToAcceptTheRequest": "User should check attending events to accept the request.",
  "userShouldHaveOnlyOneActiveRequest": "User should have only one active request",
  "validFor": "Valid for",
  "verification": "Verification",
  "verificationCode": "Verification code",
  "verificationEmailResendSuccessfully": "Verification email resend successfully",
  "verificationLinkHasBeenSentToYourEmail": "Verification link has been sent to your Email",
  "verificationNotComplete": "Verification not complete",
  "verify": "Verify",
  "verifyYourPhoneNumber": "Verify your phone number",
  "verifyYourEmail": "Verify your email",
  "verifyYourFace": "Verify your face",
  "video": "Video",
  "videoOn": "Video on",
  "videoOff": "Video off",
  "yourIntro/ProfleVideoDeletedSuccessfully": "Your Intro/Profile video deleted successfully",
  "yourIntro/ProfleVideoUploadedSuccessfully": "Your Intro/Profile video uploaded successfully",
  "youCanChooseToCreateTheEventWithoutAddingAnyImage": "You can choose to create the event without adding any image/video",
  "orIfYouDoWantToAddThenClick": "Or if you do want to add, then click 'NEXT'",
  "videoEvent": "Video event",
  "videoEventDurationMax": "Video event duration max",
  "videoFormatsOf.MPG,.GIF,.WMV,.VOB,.WebVTTAnd.AVIAreNotSupported": "Video formats of .MPG, .GIF, .WMV, .VOB, .WebVTT and .AVI are not supported",
  "videoMeetConfirmDialogHeader": "Please confirm",
  "videoMeetConfirmDialogLeaveMeeting": "Do you want to leave the meeting?",
  "videoMeetHostEnds": "Host has ended the meeting",
  "videoMeetHostEndsHeader": "Meeting ended!",
  "videoMeetingPermissionError": "Please give camera and microphone access to proceed with the meeting",
  "videoRequest": "Video request",
  "videoRequested": "Video requested!",
  "videoRequestReceived": "Video request received",
  "videoRequests": "Video requests",
  "videoRequestSent": "Video request sent",
  "videoRequestAccepted": "Video request accepted",
  "videoRequestDeclined": "Video request declined",
  "videoRequestExpired": "Video request expired",
  "videoSizeLimitIs": "Video size limit is",
  "videoSizeShouldBeLessThan": "video size should be less than",
  "view": "View",
  "viewDetails": "View details",
  "viewEvent": "View event",
  "viewFeedbacks": "View feedbacks",
  "viewGuest": "View guest",
  "viewGuests": "View guests",
  "viewLess": "View less",
  "viewMore": "View more",
  "viewMoreReviews": "View more reviews",
  "viewSecretKey": "View secret key",
  "viewUsers": "View Users",
  "viewProfile": "View profile",
  "viewMoreEvents": "View more events",
  "viewLessReviews": "View less reviews",
  "warning!SizeExceedsThan": "Warning! size exceeds than",
  "warning": "Warning",
  "weekendPlans": "Weekend plans",
  "weValueYourFeedback,ThankyouForYourResponse!": "We value your feedback,Thanks for your response!",
  "whatIsItAbout": "What is it about",
  "whatYouNeedToPrepare?": "What you need to prepare?",
  "whatYouWillBeDoing?": "What you will be doing?",
  "whatTypeOfEventYouWantToHost?": "What type of event you want to host?",
  "whyDoYouWantToReport": "Why do you want to report",
  "willBeDeducted": "will be deducted",
  "willBeProvidedAfter": "will be provided after",
  "within24HoursOfPurchase": "Within 24 hours of purchase",
  "wontBeAttending": "Wont be attending",
  "writeYourConcern/Suggestion": "Write your concern/suggestion",
  "wrongCodeEntered": "Wrong Code Entered. Please try again.",
  "yes": "Yes",
  "year": "Year",
  "you": "You",
  "YOU": "YOU",
  "youAlreadyHaveMeetingInThisTimeSlot": "You already have meeting in this timeslot",
  "youHaveAlreadySentYourRequestForThisHostAndItIsInProgress": "You have already sent your request for this user and it is In-Progress",
  "youHaveAnActiveRequestAlready": "You have an active request already",
  "youHavePaidFor": "You have paid for",
  "youCanHostBothPaidAndFreeEventsOnPaymentAccountActivation": "You can host both paid and free events on payment account activation",
  "youCannotInviteTheEventHost": "You cannot invite the event host",
  "youCannotInviteYourself": "You cannot invite yourself",
  "youCanNowConnectThroughVideoWithThisUser": "You can now connect through video with this user",
  "youCannotSendMessagesToThisUser": "You cannot send messages to this user",
  "youCanOnlyHostFreeEventsTryToConnectWithUSDForPaymentActivation": "You can only host free events, Try to connect with USD for payment activation!",
  "youCanGenerateAnAIAvatarUpTo": "You can generate an AI avatar up to",
  "YouCanSendANewRequestAfterTheEarlierRequestIsCancelled/Completed": "You can send a new request after the earlier request is cancelled/deleted.",
  "youDon'tHaveAnyGuestsAttending": "You don't have any guests attending",
  "youDontHaveAnyMessages": "You don't have any messages",
  "youHaveAlreadyChosenThisDate": "You have already chosen this date",
  "youHaveAlreadyChosenSameTimeSlot": "You have already chosen same timeslot",
  "youHaveChosenPastFromTime": "You have chosen past from time",
  "youHaveChosenPastToTime": "You have chosen past to time",
  "youHaveChosenPreviousDate": "You have chosen previous date",
  "youHaveEnteredSameEmailIdTwice": "You have entered same email Id twice",
  "youHaveNoActiveEvents": "You have no active events",
  "youHaveNoActiveRequests": "You have no active requests",
  "youHaveNoPastEvents": "You have no past events",
  "youngnest": "Youngnest",
  "youShouldBeEitherAHostorCohostToStartWith": "You should be either a host or a co-host to start meeting with",
  "your": "Your",
  "yourLocalCurrencyIsNotSupportedByStripe": "Your Local currency is not supported by stripe !",
  "yourOldPasswordAreInCorrect": "Your old password are incorrect",
  "yourBrowserDoesNotSupportHTMLvideo.": "Your browser does not support HTML video.",
  "yourComment": "Your comment",
  "youHaveNone": "You have none!",
  "yourEmailHasBeenVerified": "Your email has been verified",
  "yourEvents": "Your events",
  "yourIntro": "Your intro",
  "yourIntroVideo": "Your intro video",
  "yourInvitations": "Your invitations",
  "yourInvitedGuests": "Your invited guests",
  "yourPaymentConfirmed": "Your payment confirmed",
  "yourPaymentHasFailed.PleaseTryAgainLater.": "Your payment has failed. Please try again later.",
  "yourPaymentHasFailed": "Your payment has failed",
  "yourPaymentIsSuccessful": "Your payment is successful",
  "yourProfileWillBeAnonymousToOtherUsers": "Your profile will be anonymous to other users",
  "yourRequestHasBeenCancelled": "Your request has been cancelled",
  "YourTotalEarnings": "Your total earnings",
  "youWillStartSeeingYourEventsAsSoonAsSomethingComesUp": "You will start seeing your events as soon as something comes up.",
  "youMustHaveAtleastOneCategory": "You must have atleast one category",
  "zoomMeetingID": "Zoom Meeting ID",
  "cookie": {
    "header": "Cookies used on the website!",
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "allow": "Allow cookies",
    "deny": "Decline",
    "link": "Learn more",
    "policy": "Cookie policy"
  },
  "blueTick": {
      "reasonLabel": "Reason *",
      "reasonPlaceholder": "Provide your reason here...",
      "attachmentLabel": "Add an official identification document of yourself",
      "attachmentTooltip": "Attachment should be in PNG, JPEG, or JPG format",
      "addAttachmentButton": "Add Attachment",
      "verificationNote": "Note: Face Verification Should be completed to get verified further.",
      "cancelButton": "CANCEL",
      "confirmButton": "CONFIRM",
      "modalHeader": "Request Blue Tick"
  },
  "userCategories": {
    "Business and Industry": "Business and Industry",
    "Advertising": "Advertising",
    "Agriculture": "Agriculture",
    "Architecture": "Architecture",
    "Aviation": "Aviation",
    "Banking": "Banking",
    "Investment banking": "Investment banking",
    "Online banking": "Online banking",
    "Retail banking": "Retail banking",
    "Business": "Business",
    "Construction": "Construction",
    "Design": "Design",
    "Fashion design": "Fashion design",
    "Graphic design": "Graphic design",
    "Interior design": "Interior design",
    "Economics": "Economics",
    "Engineering": "Engineering",
    "Entrepreneurship": "Entrepreneurship",
    "Health care": "Health care",
    "Higher education": "Higher education",
    "Management": "Management",
    "Marketing": "Marketing",
    "Nursing": "Nursing",
    "Online": "Online",
    "Digital marketing": "Digital marketing",
    "Display advertising": "Display advertising",
    "Email marketing": "Email marketing",
    "Online advertising": "Online advertising",
    "Search engine optimization": "Search engine optimization",
    "Social media": "Social media",
    "Social media marketing": "Social media marketing",
    "Web design": "Web design",
    "Web development": "Web development",
    "Web hosting": "Web hosting",
    "Personal finance": "Personal finance",
    "Credit cards": "Credit cards",
    "Insurance": "Insurance",
    "Investment": "Investment",
    "Mutual funds": "Mutual funds",
    "Mortgage loans": "Mortgage loans",
    "Real estate": "Real estate",
    "Retail": "Retail",
    "Sales": "Sales",
    "Science": "Science",
    "Small business": "Small business",
    "Entertainment": "Entertainment",
    "Games": "Games",
    "Action games": "Action games",
    "Board games": "Board games",
    "Browser games": "Browser games",
    "Card games": "Card games",
    "Casino games": "Casino games",
    "First-person shooter games": "First-person shooter games",
    "Gambling": "Gambling",
    "Massively multiplayer online games": "Massively multiplayer online games",
    "Massively multiplayer online role-playing games": "Massively multiplayer online role-playing games",
    "Online games": "Online games",
    "Online poker": "Online poker",
    "Puzzle video games": "Puzzle video games",
    "Racing games": "Racing games",
    "Role-playing games": "Role-playing games",
    "Shooter games": "Shooter games",
    "Simulation games": "Simulation games",
    "Sports games": "Sports games",
    "Strategy games": "Strategy games",
    "Video games": "Video games",
    "Word games": "Word games",
    "Role-Playing Games": "Role-Playing Games",
    "Live events": "Live events",
    "Ballet": "Ballet",
    "Bars": "Bars",
    "Concerts": "Concerts",
    "Dance halls": "Dance halls",
    "Music festivals": "Music festivals",
    "Night clubs": "Night clubs",
    "Parties": "Parties",
    "Plays": "Plays",
    "Theatre": "Theatre",
    "Movies": "Movies",
    "Action movies": "Action movies",
    "Animated movies": "Animated movies",
    "Anime movies": "Anime movies",
    "Bollywood movies": "Bollywood movies",
    "Comedy movies": "Comedy movies",
    "Documentary movies": "Documentary movies",
    "Drama movies": "Drama movies",
    "Fantasy movies": "Fantasy movies",
    "Horror movies": "Horror movies",
    "Musical theatre": "Musical theatre",
    "Science fiction movies": "Science fiction movies",
    "Thriller movies": "Thriller movies",
    "Music": "Music",
    "Blues music": "Blues music",
    "Classical music": "Classical music",
    "Country music": "Country music",
    "Dance music": "Dance music",
    "Electronic music": "Electronic music",
    "Gospel music": "Gospel music",
    "Heavy metal music": "Heavy metal music",
    "Hip hop music": "Hip hop music",
    "Jazz music": "Jazz music",
    "Music videos": "Music videos",
    "Pop music": "Pop music",
    "Rhythm and blues music": "Rhythm and blues music",
    "Rock music": "Rock music",
    "Soul music": "Soul music",
    "Reading": "Reading",
    "Books": "Books",
    "Comics": "Comics",
    "E-books": "E-books",
    "Fiction books": "Fiction books",
    "Literature": "Literature",
    "Magazines": "Magazines",
    "Manga": "Manga",
    "Mystery fiction": "Mystery fiction",
    "Newspapers": "Newspapers",
    "Non-fiction books": "Non-fiction books",
    "Romance novels": "Romance novels",
    "TV": "TV",
    "TV comedies": "TV comedies",
    "TV game shows": "TV game shows",
    "TV reality shows": "TV reality shows",
    "TV talkshows": "TV talkshows",
    "Family and relationships": "Family and relationships",
    "Dating": "Dating",
    "Family": "Family",
    "Fatherhood": "Fatherhood",
    "Friendship": "Friendship",
    "Marriage": "Marriage",
    "Motherhood": "Motherhood",
    "Parenting": "Parenting",
    "Weddings": "Weddings",
    "Fitness and wellness": "Fitness and wellness",
    "Bodybuilding": "Bodybuilding",
    "Meditation": "Meditation",
    "Physical exercise": "Physical exercise",
    "Physical fitness": "Physical fitness",
    "Running": "Running",
    "Weight training": "Weight training",
    "Yoga": "Yoga",
    "Food and drink": "Food and drink",
    "Alcoholic beverages": "Alcoholic beverages",
    "Beer": "Beer",
    "Distilled beverage": "Distilled beverage",
    "Wine": "Wine",
    "Beverages": "Beverages",
    "Coffee": "Coffee",
    "Energy drinks": "Energy drinks",
    "Juice": "Juice",
    "Soft drinks": "Soft drinks",
    "Tea": "Tea",
    "Cooking": "Cooking",
    "Baking": "Baking",
    "Recipes": "Recipes",
    "Cuisine": "Cuisine",
    "Chinese cuisine": "Chinese cuisine",
    "French cuisine": "French cuisine",
    "German cuisine": "German cuisine",
    "Greek cuisine": "Greek cuisine",
    "Indian cuisine": "Indian cuisine",
    "Italian cuisine": "Italian cuisine",
    "Japanese cuisine": "Japanese cuisine",
    "Korean cuisine": "Korean cuisine",
    "Latin American cuisine": "Latin American cuisine",
    "Mexican cuisine": "Mexican cuisine",
    "Middle Eastern cuisine": "Middle Eastern cuisine",
    "Spanish cuisine": "Spanish cuisine",
    "Thai cuisine": "Thai cuisine",
    "Vietnamese cuisine": "Vietnamese cuisine",
    "Food": "Food",
    "Barbecue": "Barbecue",
    "Chocolate": "Chocolate",
    "Desserts": "Desserts",
    "Fast food": "Fast food",
    "Organic food": "Organic food",
    "Pizza": "Pizza",
    "Seafood": "Seafood",
    "Veganism": "Veganism",
    "Vegetarianism": "Vegetarianism",
    "Restaurants": "Restaurants",
    "Coffeehouses": "Coffeehouses",
    "Diners": "Diners",
    "Fast casual restaurants": "Fast casual restaurants",
    "Fast food restaurants": "Fast food restaurants",
    "Hobbies and activities": "Hobbies and activities",
    "Arts and music": "Arts and music",
    "Acting": "Acting",
    "Crafts": "Crafts",
    "Dance": "Dance",
    "Drawing": "Drawing",
    "Drums": "Drums",
    "Fine art": "Fine art",
    "Guitar": "Guitar",
    "Painting": "Painting",
    "Performing arts": "Performing arts",
    "Photography": "Photography",
    "Sculpture": "Sculpture",
    "Singing": "Singing",
    "Writing": "Writing",
    "Current events": "Current events",
    "Home and garden": "Home and garden",
    "Do it yourself (DIY)": "Do it yourself (DIY)",
    "Furniture": "Furniture",
    "Gardening": "Gardening",
    "Home Appliances": "Home Appliances",
    "Home improvement": "Home improvement",
    "Pets": "Pets",
    "Birds": "Birds",
    "Cats": "Cats",
    "Dogs": "Dogs",
    "Fish": "Fish",
    "Horses": "Horses",
    "Pet food": "Pet food",
    "Rabbits": "Rabbits",
    "Reptiles": "Reptiles",
    "Politics and social issues": "Politics and social issues",
    "Charity and causes": "Charity and causes",
    "Community issues": "Community issues",
    "Environmentalism": "Environmentalism",
    "Law": "Law",
    "Military": "Military",
    "Politics": "Politics",
    "Religion": "Religion",
    "Sustainability": "Sustainability",
    "Veterans": "Veterans",
    "Volunteering": "Volunteering",
    "Travel": "Travel",
    "Adventure travel": "Adventure travel",
    "Air travel": "Air travel",
    "Beaches": "Beaches",
    "Car rentals": "Car rentals",
    "Cruises": "Cruises",
    "Ecotourism": "Ecotourism",
    "Hotels": "Hotels",
    "Lakes": "Lakes",
    "Mountains": "Mountains",
    "Nature": "Nature",
    "Theme parks": "Theme parks",
    "Tourism": "Tourism",
    "Vacations": "Vacations",
    "Vehicles": "Vehicles",
    "Automobiles": "Automobiles",
    "Boats": "Boats",
    "Electric vehicle": "Electric vehicle",
    "Hybrids": "Hybrids",
    "Minivans": "Minivans",
    "Motorcycles": "Motorcycles",
    "RVs": "RVs",
    "SUVs": "SUVs",
    "Scooters": "Scooters",
    "Trucks": "Trucks",
    "Shopping and fashion": "Shopping and fashion",
    "Beauty": "Beauty",
    "Beauty salons": "Beauty salons",
    "Cosmetics": "Cosmetics",
    "Fragrances": "Fragrances",
    "Hair products": "Hair products",
    "Spas": "Spas",
    "Tattoos": "Tattoos",
    "Clothing": "Clothing",
    "Children's clothing": "Children's clothing",
    "Men's clothing": "Men's clothing",
    "Shoes": "Shoes",
    "Women's clothing": "Women's clothing",
    "Fashion accessories": "Fashion accessories",
    "Dresses": "Dresses",
    "Handbags": "Handbags",
    "Jewelry": "Jewelry",
    "Sunglasses": "Sunglasses",
    "Shopping": "Shopping",
    "Boutiques": "Boutiques",
    "Coupons": "Coupons",
    "Discount stores": "Discount stores",
    "Luxury goods": "Luxury goods",
    "Online shopping": "Online shopping",
    "Shopping malls": "Shopping malls",
    "Toys": "Toys",
    "Sports and outdoors": "Sports and outdoors",
    "Outdoor recreation": "Outdoor recreation",
    "Boating": "Boating",
    "Camping": "Camping",
    "Fishing": "Fishing",
    "Horseback riding": "Horseback riding",
    "Hunting": "Hunting",
    "Mountain biking": "Mountain biking",
    "Surfing": "Surfing",
    "Sports": "Sports",
    "American football": "American football",
    "Soccer": "Soccer",
    "Auto racing": "Auto racing",
    "Baseball": "Baseball",
    "Basketball": "Basketball",
    "College football": "College football",
    "Golf": "Golf",
    "Marathons": "Marathons",
    "Skiing": "Skiing",
    "Snowboarding": "Snowboarding",
    "Swimming": "Swimming",
    "Tennis": "Tennis",
    "Thriathlon": "Thriathlon",
    "Volleyball": "Volleyball",
    "Badminton": "Badminton",
    "Cricket": "Cricket",
    "Hockey": "Hockey",
    "Table Tennis": "Table Tennis",
    "Technology": "Technology",
    "Computers": "Computers",
    "AI": "AI",
    "Blockchain": "Blockchain",
    "Cloud technology": "Cloud technology",
    "Data Science & analytics": "Data Science & analytics",
    "Extended Reality": "Extended Reality",
    "IoT": "IoT",
    "Machine Learning": "Machine Learning",
    "Open Source Software": "Open Source Software",
    "Robotics": "Robotics",
    "Quantum Computing": "Quantum Computing",
    "UI/UX Design": "UI/UX Design",
    "Consumer electronics": "Consumer electronics",
    "Audio equipment": "Audio equipment",
    "Camcorders": "Camcorders",
    "Cameras": "Cameras",
    "E-book readers": "E-book readers",
    "GPS devices": "GPS devices",
    "Game consoles": "Game consoles",
    "Mobile phones": "Mobile phones",
    "Portable media players": "Portable media players",
    "Projectors": "Projectors",
    "Smartphones": "Smartphones",
    "Televisions": "Televisions",
    "ESports": "ESports",
    "Agent": "Agent",
    "Battle": "Battle",
    "Royale": "Royale",
    "Coach": "Coach",
    "First Person Shooter": "First Person Shooter",
    "Fighting Game": "Fighting Game",
    "Mass Multiplayer Online": "Mass Multiplayer Online",
    "Role-Playing Game": "Role-Playing Game",
    "Multiplayer Online Battle Arena": "Multiplayer Online Battle Arena",
    "Online Trading Card Game": "Online Trading Card Game",
    "Player vs. Player": "Player vs. Player",
    "Professional player": "Professional player",
    "Real-time Strategy": "Real-time Strategy",
    "Shoutcaster": "Shoutcaster",
    "Sports Game": "Sports Game",
    "Third-Person Shooter": "Third-Person Shooter",
    "ToCheckMergeConflict": "To Check Merge Conflict",
    "Master Class": "Master Class",
    "Culinary Arts": "Culinary Arts",
    "Business Leadership": "Business Leadership",
    "Green Living": "Green Living",
    "Renewable energy": "Renewable energy",
    "Sustainable Living": "Sustainable Living",
    "Gadgets": "Gadgets",
    "Sleep Health": "Sleep Health",
    "Holistic Health": "Holistic Health",
    "Nutrition": "Nutrition",
    "Mental Health": "Mental Health",
    "Podcasts": "Podcasts",
    "Streaming Services": "Streaming Services",
    "Virtual Reality (VR)": "Virtual Reality (VR)",
    "Education": "Education",
    "E-learning": "E-learning",
    "Language Learning": "Language Learning",
    "STEM Education": "STEM Education",
    "Cryptocurrency": "Cryptocurrency",
    "Fintech": "Fintech",
  },

  "contactUsCategories": {
    "SignUp and SignIn": "SignUp and SignIn",
    "Profile and Settings": "Profile and Settings",
    "Host Bank Activation": "Host Bank Activation",
    "Event and User": "Event and User",
    "Collaboration": "Collaboration",
    "Hosting Event": "Hosting Event",
    "Invitation": "Invitation",
    "Payment": "Payment",
    "Users": "Users",
    "Cancellation / Refund": "Cancellation / Refund",
    "Technical": "Technical",
    "Suggestions": "Suggestions",
    "Event and Participation": "Event and Participation",
  },

  "meetingCategories": {
    "Great for Groups": "Great for Groups",
    "Flowers Arrangement & Floristry": "Flowers Arrangement & Floristry",
    "Festive Season": "Festive Season",
    "Learning": "Learning",
    "Drinks": "Drinks",
    "Health & Wellness": "Health & Wellness",
    "Fitness": "Fitness",
    "Family Friendly": "Family Friendly",
    "Culture & History": "Culture & History",
    "Fashion & Beauty": "Fashion & Beauty",
    "Magic": "Magic",
    "Arts & Crafts": "Arts & Crafts",
    "Baking & Cooking": "Baking & Cooking",
    "Entertainment": "Entertainment",
    "Dance": "Dance",
    "Writing": "Writing",
    "Music": "Music",
    "Photography": "Photography",
    "Others": "Others"
  },

  "BeeMG-ERR001": "Meeting not found",
  "BeeMG-ERR002": "You have already given feedback for this meeting, Thankyou",
  "BeeMG-ERR003": "Already invited to BeeMG",
  "BeeMG-ERR004": "User Already Registered",
  "BeeMG-ERR005": "Invite limit reached",
  "BeeMG-ERR006": "Registration is invite based only",
  "BeeMG-ERR007": "Email already registered with BeeMG",
  "BeeMG-ERR008": "Email already registered for launch notification",
  "BeeMG-ERR009": "Guest value should be minimum 1",
  "BeeMG-ERR010": "Sold out",
  "BeeMG-ERR011": "User already Accepted to this meeting",
  "BeeMG-ERR012": "Cancellation period over",
  "BeeMG-ERR013": "Amount already refunded",
  "BeeMG-ERR014": "Wrong signature",
  "BeeMG-ERR015": "Please mention maximum and minimum number of users for the meeting",
  "BeeMG-ERR016": "Meeting details Id is not given",
  "BeeMG-ERR017": "Cannot create meeting of some other user",
  "BeeMG-ERR018": "Free only for audio event",
  "BeeMG-ERR019": "Please choose correct timeslot within a day",
  "BeeMG-ERR020": "Free only for free audio meetings",
  "BeeMG-ERR021": "Please mention language for the meeting",
  "BeeMG-ERR022": "Please give valid date and time",
  "BeeMG-ERR023": "Invalid input data",
  "BeeMG-ERR024": "Not current user meeting",
  "BeeMG-ERR025": "Event policy not accepted by this user",
  "BeeMG-ERR026": "Only verified user can create event",
  "BeeMG-ERR027": "Cannot edit event of someother user",
  "BeeMG-ERR028": "Event not created by this user",
  "BeeMG-ERR029": "Event cannot be deleted, once meetings are scheduled",
  "BeeMG-ERR030": "Already set remainder for this time",
  "BeeMG-ERR031": "You are not a participant for this meeting",
  "BeeMG-ERR032": "Not current user notification remainder",
  "BeeMG-ERR033": "Only verified user can register for free event",
  "BeeMG-ERR034": "Already registered for this free event",
  "BeeMG-ERR035": "This user is already invited for this event",
  "BeeMG-ERR036": "User not in BeeMG",
  "BeeMG-ERR037": "User not verified, Please add some other user",
  "BeeMG-ERR038": "Host of this event cannot be also a CoHost",
  "BeeMG-ERR039": "You already added this CoHost",
  "BeeMG-ERR040": "Cohosts can be added only for Audio Event",
  "BeeMG-ERR041": "Cannot add CoHost for some other user`s event",
  "BeeMG-ERR042": "CoHosts adding limit Exceeded",
  "BeeMG-ERR043": "Schedule Meetings and then add CoHosts",
  "BeeMG-ERR044": "Not current user meeting to delete Co-Host",
  "BeeMG-ERR045": "Invited User Id is not given",
  "BeeMG-ERR046": "Please mention number of users for the meeting",
  "BeeMG-ERR047": "Guest should not empty",
  "BeeMG-ERR048": "You can no longer request a meeting",
  "BeeMG-ERR049": "Cannot edit other user request",
  "BeeMG-ERR050": "No request found",
  "BeeMG-ERR051": "Current user cannot report his/her own profile",
  "BeeMG-ERR052": "Only verified user can report other users",
  "BeeMG-ERR053": "Can't edit user",
  "BeeMG-ERR054": "User Not Created",
  "BeeMG-ERR055": "Meeting Time is expired",
  "BeeMG-ERR056": "Please wait for Host to start the meeting",
  "BeeMG-ERR057": "Please wait for the start time",
  "BeeMG-ERR058": "Active Session is already present",
  "BeeMG-ERR059": "Meeting already exists for same time, choose some other time slot",
  "BeeMG-ERR060": "Participants are there for this meeting, cannot edit the meeting",
  "BeeMG-ERR061": "Invalid datetime format",
  "BeeMG-ERR062": "Cannot delete meeting notification remainder of some other user",
  "BeeMG-ERR063": "Cannot give feedback for own meeting",
  "BeeMG-ERR064": "Cannot give feedback for the unpaid event",
  "BeeMG-ERR065": "Cannot give feedback for unstarted meeting",
  "BeeMG-ERR066": "Login with Google",
  "BeeMG-ERR067": "You have paid for only",
  "BeeMG-ERR068": "users",
  "BeeMG-ERR069": "Entered your Email Id",
  "BeeMG-ERR070": "You cannot invite the Host",
  "BeeMG-ERR071": "You cannot pay for Guest",
  "BeeMG-ERR072": "Cannot edit other user request",
  "BeeMG-ERR073": "You have already chosen same timeslot",
  "BeeMG-ERR074": "You cannot request for your own meeting",
  "BeeMG-ERR075": "Enter valid cost",
  "BeeMG-ERR076": "You cannot give feedback for others",
  "BeeMG-ERR077": "User should have only one active request",
  "BeeMG-ERR078": "Reason should not be empty",
  "BeeMG-ERR079": "Fields should not be empty",
  "BeeMG-ERR080": "Only verified user can invite",
  "BeeMG-ERR081": "You cannot create anonymous event",
  "BeeMG-ERR082": "Start time and end time cannot be same",
  "BeeMG-ERR083": "Meeting cost should be minimum",
  "BeeMG-ERR084": "Large file size",
  "BeeMG-ERR085": "Cancellation policy for paid event should not be empty",
  "BeeMG-ERR086": "User not allow to message",
  "BeeMG-ERR087": "Maximum participants exceeds",
  "BeeMG-ERR088": "Cannot set remainder for pending/declined request",
  "BeeMG-ERR089": "Active your stripe account and then create paid events",
  "BeeMG-ERR090": "Video request limit is reached for a day",
  "BeeMG-ERR091": "Cannot pay for past meeting",
  "BeeMG-ERR092": "Meeting id or meeting details id should not empty",
  "BeeMG-ERR093": "Pay for the event and try cancellation",
  "BeeMG-ERR094": "This user is blocked as fake profile",
  "BeeMG-ERR095": "Reason length exceeds",
  "BeeMG-ERR096": "Refund failed",
  "BeeMG-ERR097": "Already requested for cancellation",
  "BeeMG-ERR098": "User cannot be added as cohost as already registered",
  "BeeMG-ERR099": "User already Rejected to this meeting",
  "BeeMG-ERR100": "Account not Activated, Please Try Again Later",
  "BeeMG-ERR101": "Too many attempts, Please try after sometime",
  "BeeMG-ERR102": "Meeting timing option is not selected",
  "BeeMG-ERR103": "Meeting request was not accepted",
  "BeeMG-ERR104": "MeetingRequest service failed to update the request",
  "BeeMG-ERR105": "The user is not anonymous, Please change the user to anonymous",
  "BeeMG-ERR106": "You cannot invite the Co-Host",
  "BeeMG-ERR107": "You cannot invite yourself",
  "BeeMG-ERR108": "User already paid for the event",
  "BeeMG-ERR109": "The maximum limit for generating avatars has been reached.",

}

