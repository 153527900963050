/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect, useState } from 'react';
import { UserPolicyAgreements } from '../models/userPolicyAgreements.model';
import { useGetTermsAndConditionsQuery } from '../Services/policyApi';
import { changeToken } from '../Services/signUpReducer';
import { awsLogout, getToken } from '../Services/tokenReducer';
import { useGetCurrentUserDetailsQuery } from '../Services/userApi';
import {
  useCheckUserTermsPolicyQuery,
  useCreateUserTermsMutation,
} from '../Services/userPolicyAgreementsApi';
import { useAppDispatch, useAppSelector } from '../Store/hooks';
import { getCurrentUser } from '../Services/userReducer';

/**
 * Custom hook to manage terms and conditions.
 *
 * It provides functionality to check if the user has accepted the terms and conditions,
 * save the user's acceptance, and cancel the process.
 *
 * @return {Object} An object containing the hide button state, policies, navigation screen,
 *                  policies success status, save function, cancel function, accept terms success status,
 *                  and policy accepted status.
 */

export const useTermsAndConditionsHooks = () => {
  const [createUserTerms, { isSuccess: acceptTermsSuccess }] =
    useCreateUserTermsMutation<any>();
  const dispatch = useAppDispatch();

  const [hidebutton, setHideButton] = useState(true);
  const [navigateScreen, setNavigateScreen] = useState<
    'NAVIGATE_TO_LOGIN' | 'NAVIGATE_TO_HOME_SCREEN' | undefined
  >();

  const { data: policies, isSuccess: policiesSuccess } =
    useGetTermsAndConditionsQuery();
  const userAccessToken = useAppSelector(getToken);
  const currentUser = useAppSelector(getCurrentUser)

  // Conditionally fetch user details based on currentUser and userAccessToken
  const { data: user = null } = useGetCurrentUserDetailsQuery(
    undefined,
    {
      skip: !currentUser || !userAccessToken,
    }
  );

  // Conditionally fetch policyAccepted based on currentUser and userAccessToken
  const { data: policyAccepted = false } = useCheckUserTermsPolicyQuery(
    undefined,
    {
      skip: !currentUser || !userAccessToken,
    }
  );

  useEffect(() => {
    if (!policyAccepted && hidebutton) {
      setHideButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyAccepted]);

  const save = async () => {
    if (user) {
      const userPolicyAgreements: UserPolicyAgreements = {
        user: user,
      } as UserPolicyAgreements;

      const res = createUserTerms(userPolicyAgreements);

      if (await res) {
        setNavigateScreen('NAVIGATE_TO_HOME_SCREEN');
      }
    }
  };

  const cancel = () => {
    dispatch(awsLogout());
    dispatch(changeToken(null));

    setNavigateScreen('NAVIGATE_TO_LOGIN');
  };

  return {
    hidebutton,
    policies,
    navigateScreen,
    policiesSuccess,
    save,
    cancel,
    acceptTermsSuccess,
    policyAccepted,
  };
};
