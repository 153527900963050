/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FeedbackCategory} from '../models/feedbackCategory.model';

import {
  useCreateContactMutation,
  useFeedbackCategoryListQuery,
  useCreateContactForNonLoginUserMutation,
} from '../Services/allOthersApi';

import {
  useUploadFeedbackAttachmentsMutation,
  useUploadFeedbackAttachmentsForNonLoginusersMutation,
} from '../Services/contactFeedbackAttachementsApi';

import {useGetReCaptchaQuery} from '../Services/envApi';
import {getCurrentUserDetail} from '../Services/userReducer';
import {contactValidationSchema} from '../Utils/validation';

/**
 * A custom React hook for managing the footer functionality.
 *
 * It provides various state and functions for handling user input,
 * uploading files, and submitting feedback.
 *
 * @return {object} An object containing the state and functions for the footer.
 */

export const useFooterHook = () => {
  const {t} = useTranslation();
  const currentUser = useSelector(getCurrentUserDetail);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    formState: {errors},
    reset,
  } = useForm<any>({
    resolver: yupResolver(contactValidationSchema),
  });

  const {data: feedBackCategoryData} = useFeedbackCategoryListQuery();
  const {data: siteKey} = useGetReCaptchaQuery();

  const [uploadAttachment, {isSuccess: uploadSuccess}] =
    useUploadFeedbackAttachmentsMutation();
  const [
    createContact,
    {isSuccess: contactUsSuccess, isLoading: contactUsLoading},
  ] = useCreateContactMutation();
  const [createNonLogin, {isSuccess: nonLoginSuccess}] =
    useCreateContactForNonLoginUserMutation();

  const [
    uploadAttachmentForNonLogin,
    {isSuccess: uploadAttachmentForNonLoginSuccess},
  ] = useUploadFeedbackAttachmentsForNonLoginusersMutation();

  const [chipsVisibility, setChipsVisibility] = useState<boolean>(true);
  const [clearVisibility, setClearVisibility] = useState<boolean>(false);
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [fileValue, setFileValue] = useState<any[]>([]);
  // const [currentMail, setCurrentMail] = useState<boolean>();
  const [disable, setDisable] = useState<boolean>(false);

  const onChange = (value: any) => {
    if (value) {
      if (!recaptchaValue) {
        setRecaptchaValue(true);
      }
    } else if (recaptchaValue) {
      setRecaptchaValue(false);
    }
  };

  const onSubmit = (values: FeedbackCategory | any) => {
    if (currentUser) {
      createContact(values).then((res: any) => {
        onUploadFile(res.data.id);
      });
    } else {
      createNonLogin(values).then((res: any) => {
        onUploadFile(res.data.id);
      });
    }
  };

  const onSelectedFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      setFileValue([...fileValue, ...newFiles]);
    }
  };

  const onSelectedFileMobile = (e: any) => {
    const newFiles = e;
    setFileValue([...fileValue, newFiles]);
  };

  const onUploadFile = async (id: string) => {
    if (fileValue && fileValue.length > 0) {
      fileValue.map((fileList: any) => {
        const file = fileList as File;
        const args = {id: id, file};
        return currentUser
          ? uploadAttachment(args)
          : uploadAttachmentForNonLogin(args);
      });
    }
  };

  const removeFile = (fileNameToRemove: any) => {
    const updatedFiles = fileValue.filter(file => {
      if (file.name.localeCompare(fileNameToRemove)) {
        return true;
      }
      return false;
    });
    setFileValue(updatedFiles);
  };

  return {
    currentUser,
    feedBackCategoryData,
    siteKey,
    chipsVisibility,
    setChipsVisibility,
    onChange,
    recaptchaValue,
    onSubmit,
    trigger,
    contactUsSuccess,
    contactUsLoading,
    nonLoginSuccess,
    uploadAttachmentForNonLoginSuccess,
    onUploadFile,
    onSelectedFile,
    t,
    setFileValue,
    uploadSuccess,
    setClearVisibility,
    setRecaptchaValue,
    clearVisibility,
    fileValue,
    removeFile,
    reset,
    handleSubmit,
    register,
    disable,
    setDisable,
    control,
    errors,
    setValue,
    watch,
    getValues,
    // currentMail,
    // setCurrentMail,
    onSelectedFileMobile,
  };
};
