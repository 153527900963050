/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {ContactFeedbackAttachements} from '../models/contactFeedbackAttachements.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for contact feedback attachments API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ uploadFeedbackAttachments: mutation, uploadFeedbackAttachmentsForNonLoginusers: mutation }} - An object containing the defined endpoints.
 */

export const contactFeedbackAttachementsApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    uploadFeedbackAttachments: builder.mutation<
      ContactFeedbackAttachements,
      any
    >({
      query: arg => {
        const {id, file} = arg;
        let testData: FormData = new FormData();
        testData.append('file', file);

        return {
          url: `contactFeedBackAttachments/feedbackAttachmentsUpload/${id}`,
          method: 'POST',
          body: testData,
        };
      },
    }),
    uploadFeedbackAttachmentsForNonLoginusers: builder.mutation<
      ContactFeedbackAttachements,
      any
    >({
      query: arg => {
        const {id, file} = arg;
        let testData: FormData = new FormData();
        testData.append('file', file);

        return {
          url: `contactFeedBackAttachments/feedbackAttachmentsUploadForNonLoginUsers/${id}`,
          method: 'POST',
          body: testData,
        };
      },
    }),
  }),
});
export const {
  useUploadFeedbackAttachmentsMutation,
  useUploadFeedbackAttachmentsForNonLoginusersMutation,
} = contactFeedbackAttachementsApi;
