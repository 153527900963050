/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { useTitle } from './useTitle'
import MoviesImage from './MoviesImage'
import MoviesLayOut from './MoviesLayOut'

/**
 * Renders the Movies screen which displays a list of nearby users, users similar to the current user, and users the current user has recently met.
 *
 * @return {JSX.Element} The Movies screen UI.
 */
function Movies() {

  const { t } = useTranslation()
  useTitle(t('movies'))

  return (
    <>
      <MoviesImage />
      <MoviesLayOut />
    </>
  )
}

export default Movies
