/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { TFunctionDetailedResult } from "i18next";
import i18n from "../Translations"

/**
 * Checks if the given array is not empty.
 *
 * @param array - The array to check
 * @returns true if the array is not empty, false otherwise
 */
export const isNotEmptyArray = (array?: any[]): boolean => {
  return array !== undefined && array.length !== 0
}

/**
 * Translate a key using i18next.
 *
 * @param key The key to translate.
 * @param options The options to pass to i18next.
 * @returns The translated string if key is defined, an empty string otherwise.
 */
export const tr = (key?: string, options?: any): string | TFunctionDetailedResult<string> => {
  return key ? i18n.t(key, options) : '';
};


/**
 * Generates a unique identifier.
 *
 * @returns A string that is unique to the point of execution.
 */
export const uid = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

/**
 * Converts a timezone offset in minutes to a string in the format "UTC ±HH:MM".
 *
 * @param {{ timezoneUtc: string }} params - The timezone offset in minutes.
 * @returns The timezone offset as a string in the format "UTC ±HH:MM".
 */
export const getTimezoneOffsetString = ({ timezoneUtc }: { timezoneUtc: string }): string => {
  const offsetInMinutes = parseInt(timezoneUtc);

  let hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  let minutes = Math.abs(offsetInMinutes % 60);

  let hoursString = ("0" + Math.abs(hours)).slice(-2);
  let minutesString = ("0" + minutes).slice(-2);
  let offsetString = "UTC " + (offsetInMinutes < 0 ? "+" : "-") + hoursString + ":" + minutesString;

  return offsetString;
}