/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useState, useEffect} from 'react'

/**
 * Returns an object with the `width` and `height` properties
 * containing the inner window dimensions.
 *
 * It returns `width` and `height` properties.
 */
function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height,
  }
}

/**
 * A hook that returns the window dimensions.
 *
 * It returns `width` and `height` properties.
 */
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    /**
     * An event handler that sets the window dimensions state to the current
     * inner window dimensions.
     */
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

/**
 * Checks if a value is included in an array.
 *
 * If the array is modern and has an `includes` method, it will be used.
 * Otherwise, the array will be filtered to see if there is a matching value.
 *
 * @param {any} val The value to check for.
 * @param {Array} arr The array to check against.
 *
 * @returns {boolean} True if the value is in the array, false otherwise.
 */
export const includes = (val, arr) =>
  arr.includes ? arr.includes(val) : !!arr.filter(item => item === val).length

const wrapAroundValue = (val, max) => ((val % max) + max) % max

const hardBoundedValue = (val, max) => Math.max(0, Math.min(max, val))

/**
 * Normalize an index to a range of [0, len) or [0, len] when wrap is true.
 *
 * When wrap is true, the index will wrap around the array. For example,
 * if len is 5, indices -2, 3, and 8 would be normalized to 3, 3, and 3, respectively.
 *
 * When wrap is false (default), the index will be bounded to the range [0, len).
 * For example, if len is 5, indices -2, 3, and 8 would be normalized to 0, 3, and 4, respectively.
 *
 * @param {number} idx The index to normalize.
 * @param {number} len The length of the array.
 * @param {boolean} [wrap=false] Whether to wrap the index.
 *
 * @returns {number} The normalized index.
 */
export const normalizeIndex = (idx, len, wrap = false) =>
  wrap ? wrapAroundValue(idx, len) : hardBoundedValue(idx, len - 1)

export const values =
  Object.values || (obj => Object.keys(obj).map(key => obj[key]))

/**
 * A curried function that takes any number of values, and returns a
 * function that takes one value and returns the minimum of all the
 * values passed to the outer function, and the value passed to the
 * inner function.
 *
 * @param {...any} vals The values to compare against.
 *
 * @returns {(val: any) => number} A function that takes a value and
 *   returns the minimum of that value and all the values passed to
 *   the outer function.
 */
export const minMap =
  (...vals) =>
  val =>
    Math.min(...vals, val)

/**
 * A curried function that takes any number of values, and returns a
 * function that takes one value and returns the maximum of all the
 * values passed to the outer function, and the value passed to the
 * inner function.
 *
 * @param {...any} vals The values to compare against.
 *
 * @returns {(val: any) => number} A function that takes a value and
 *   returns the maximum of that value and all the values passed to
 *   the outer function.
 */
export const maxMap =
  (...vals) =>
  val =>
    Math.max(...vals, val)

export const noop = () => {}

export const easeOutQuint = t => {
  let n = t
  return 1 + --n * n ** 4
}

/**
 * A higher-order function that returns a function that takes a callback
 * and a DOM element, and returns a function that removes the event
 * listener.
 *
 * If the element is `undefined` or `null`, the event listener will not
 * be added.
 *
 * @param {string} evt The event name to listen for.
 * @param {Object} [opts=false] An options object to pass to `addEventListener`.
 *
 * @returns {(cb: (e: Event) => void) => (el: EventTarget) => (() => void)} A
 *   function that takes a callback and a DOM element, and returns a
 *   function that removes the event listener.
 */
export const on =
  (evt, opts = false) =>
  cb =>
  el => {
    if (el && typeof el.addEventListener === 'function') {
      el.addEventListener(evt, cb, opts)
      return () => el.removeEventListener(evt, cb)
    }
  }

export const onWindowScroll = cb => on('scroll', true)(cb)(window)

/**
 * A higher-order function that returns a function that takes a callback
 * and an optional `target` property, and returns a function that removes
 * the event listener.
 *
 * The callback will be called whenever the target element (or the window if
 * `target` is not specified) scrolls.
 *
 * @param {function} cb The callback to be called when the target scrolls.
 * @param {Object} [opts] An options object with a `target` property.
 * @param {EventTarget} [opts.target=window] The element to listen to for scroll events.
 *
 * @returns {function} A function that removes the event listener.
 */
export const onScroll = (cb, {target = window} = {}) =>
  onWindowScroll(e => (target === window || target === e.target) && cb(e))


/**
 * A higher-order function that returns a function that takes a callback
 * and an optional `wait` and `target` property, and returns a function
 * that removes the event listener.
 *
 * The callback will be called after the target element (or the window if
 * `target` is not specified) has finished scrolling. The `wait` property
 * specifies how long to wait after the last scroll event before calling
 * the callback.
 *
 * @param {function} cb The callback to be called when the target finishes scrolling.
 * @param {Object} [opts] An options object with `wait` and `target` properties.
 * @param {number} [opts.wait=100] The amount of time to wait after the last scroll event.
 * @param {EventTarget} [opts.target=window] The element to listen to for scroll events.
 *
 * @returns {function} A function that removes the event listener.
 */
export const onScrollEnd = (cb, {wait = 100, target = window} = {}) =>
  (timeoutID =>
    onScroll(evt => {
      clearTimeout(timeoutID)
      timeoutID = setTimeout(
        () => (evt.target === target ? cb() : undefined),
        wait,
      )
    }))(0)

/**
 * A higher-order function that returns a function that takes a callback
 * and an optional `target` property, and returns a function that removes
 * the event listener.
 *
 * The callback will be called once when the target element (or the window if
 * `target` is not specified) starts scrolling.
 *
 * @param {function} cb The callback to be called when the target starts scrolling.
 * @param {Object} [opts] An options object with a `target` property.
 * @param {EventTarget} [opts.target=window] The element to listen to for scroll events.
 *
 * @returns {function} A function that removes the event listener.
 */
export const onScrollStart = (cb, {target = window} = {}) => {
  let started = false
  const offScrollEnd = onScrollEnd(
    () => {
      started = false
    },
    {target},
  )
  const offScroll = onScroll(
    e => {
      if (!started) {
        started = true
        cb(e)
      }
    },
    {target},
  )

  return () => {
    if (typeof offScroll === 'function') {
      offScroll()
    }
    if (typeof offScrollEnd === 'function') {
      offScrollEnd()
    }
  }
}

export const onSwipe = cb => target => {
  const offTouchStart = on('touchstart')(({targetTouches}) => {
    const {pageX: startX, pageY: startY} = targetTouches[0]
    const offTouchEnd = on('touchend')(({changedTouches}) => {
      const {pageX: endX, pageY: endY} = changedTouches[0]
      const xDiff = endX - startX
      const absXDiff = Math.abs(xDiff)
      const yDiff = endY - startY
      const absYDiff = Math.abs(yDiff)
      if (Math.max(absXDiff, absYDiff) > 20) {
        const dir =
          absXDiff > absYDiff
            ? /* h */ xDiff < 0
              ? 'right'
              : 'left'
            : /* v */ yDiff < 0
            ? 'down'
            : 'up'
        cb(dir)
      }
      if (typeof offTouchEnd === 'function') {
        offTouchEnd()
      }
    })(target)
  })(target)

  return offTouchStart
}

export const trackTouchesForElement = el => {
  let touchIds = []
  on('touchend')(({targetTouches}) => {
    touchIds = targetTouches
  })(el)
  return () => touchIds.length
}

export const trackOngoingMouseInteraction = el => {
  let isInteracting = false
  on('mousedown')(() => {
    isInteracting = true
  })(el)
  on('mouseup')(() => {
    isInteracting = false
  })(document.body)
  return () => isInteracting
}

export const hasOngoingInteraction = el => {
  const getOngoingTouchCount = trackTouchesForElement(el)
  const getOngoingMouseClick = trackOngoingMouseInteraction(el)
  return () => !!getOngoingTouchCount() || getOngoingMouseClick()
}

const fakeChild = {getBoundingClientRect: () => ({})}
export const isWhollyInView =
  parent =>
  (child = fakeChild) => {
    const {left: cLeft, right: cRight} = child.getBoundingClientRect()
    const {left: pLeft, right: pRight} = parent.getBoundingClientRect()
    return cLeft >= pLeft && cRight <= pRight
  }

  export const calculateTaxAmount = (cost, percentage) => {
    const parsedCost = parseFloat(cost);
    if (isNaN(parsedCost) || isNaN(percentage)) return "0.00";
    return (parsedCost * (percentage / 100)).toFixed(2);
  };

/**
 * Detects whether the browser supports the "passive" option for event listeners.
 *
 * @returns {boolean} true if the browser supports passive event listeners, false otherwise.
 */
const supportsPassive = () => {
  try {
    window.addEventListener('__rw_test__', null, {passive: true})
    window.removeEventListener('__rw_test__', null)
    return true
  } catch {
    return false
  }
}

/**
 * Animates an element's given property to a new value over a specified duration.
 *
 * When an animation is interrupted by the user interacting with the element, the
 * promise will be rejected with the string `'Animation interrupted by interaction'`.
 *
 * By default, the animation will be eased out using the quintic easing function.
 * The easing function is called with the progress ratio of the animation, and should
 * return the corresponding value for the property.
 *
 * @param {EventTarget} el The element to animate.
 * @param {Object} [opts] An options object.
 * @param {number} [opts.delta=0] The value to animate the property to.
 * @param {boolean} [opts.immediate=false] If true, the animation will be instantaneous.
 * @param {number} [opts.duration=500] The duration of the animation in milliseconds.
 * @param {function} [opts.easing=easeOutQuint] The easing function to use.
 * @param {string} [opts.prop='scrollTop'] The property of the element to animate.
 *
 * @returns {Promise<void>} A promise that resolves when the animation is complete.
 */
export const animate = (
  el,
  {
    delta = 0,
    immediate = false,
    duration = 500,
    easing = easeOutQuint,
    prop = 'scrollTop',
  } = {},
) =>
  new Promise((res, rej) => {
    if (!delta) {
      return res()
    }
    const initialVal = el[prop]
    if (immediate) {
      el[prop] = initialVal + delta
      return res()
    }
    let hasBailed = false
    /**
     * Called when the user interacts with the element during an animation.
     * Removes the animation's event listener and resets the element's property
     * to its current value, rejecting the animation promise with the string
     * `'Animation interrupted by interaction'`.
     */
    const bail = () => {
      hasBailed = true
      const pos = el[prop]
      el.removeEventListener('touchstart', bail)
      el[prop] = pos
      return rej('Animation interrupted by interaction')
    }
    el.addEventListener(
      'touchstart',
      bail,
      supportsPassive() ? {passive: true} : false,
    )
    let startTime = null
    const step = timestamp => {
      if (hasBailed) {
        return
      }
      if (!startTime) {
        startTime = timestamp
      }
      const progressTime = timestamp - startTime
      const progressRatio = easing(progressTime / duration)
      el[prop] = initialVal + delta * progressRatio
      if (progressTime < duration) {
        window.requestAnimationFrame(step)
      } else {
        el[prop] = initialVal + delta // jump to end when animation is complete. necessary at least for immediate scroll
        res()
      }
    }
    window.requestAnimationFrame(step)
  })
