/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import ReactGA from 'react-ga4';

/**
 * Tracks a custom event in Google Analytics
 * 
 * @param {string} category - The category of the event.
 * @param {string} action - The action taken, like 'click'.
 * @param {string} [label] - Optional label to provide more information.
 */
export const trackEvent = (category: string, action: string, label?: string): void => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
