/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { CommonEntity } from './commonEntity.model';
import { Group } from './group.model';
import { Image } from './image.model';
import { Preference } from './preference.model';
import { UserCategory } from './userCategory.model';

export enum State {
    Online = 'Online',
    Offline = 'Offline'
}

export enum Verified {
    Complete = 'Complete',
    NotComplete = 'Not complete',
    Skipped = 'Skipped',
}

export enum PaymentAccountActive {
    Yes = 'Yes',
    No = 'No',
}

export enum Pricing {
    Guest = 'Guest',
    Hour = 'Hour',
}

export enum Gender {
    Female = 'Female',
    Male = 'Male',
    Others = 'Others'
}

export enum PaymentAccountType {
    Stripe = 'Stripe',
    Razorpay = 'Razorpay'
}

export interface User extends CommonEntity {
    firstName: string;
    lastName: string;
    gender: Gender;
    userId: string;
    sub: string;
    nickName: string;
    image: Image[];
    country: string;
    userVideo: string;
    catchPhrase: string;
    selfIntro: string;
    userCategory?: UserCategory[];
    paymentAccountActive: PaymentAccountActive;
    paymentAccountType: PaymentAccountType;
    verified: Verified;
    cost: number;
    costUSD: number;
    costLocal: number;
    pricing: Pricing;
    average: number;
    unreadMessage: number;
    state: State;
    salesOrder: number;
    city: string;
    ipAddress: string;
    ratingCount: number;
    isCelebrity: boolean;
    totalMeeting: number;
    pastMeeting: number;
    facebookName: string;
    twitterName: string;
    instagramName: string;
    linkedInName: string;
    emailVerified: Verified;
    phoneVerified: Verified;
    faceVerified: Verified;
    onlineList: boolean;
    allowMessage: boolean;
    allowVideo: boolean;
    allowFreeConnection: boolean;
    favorite: string;
    match: number;
    avatar: string;
    anonymous: boolean;
    token: string;
    isUserTermsPolicyStatus: boolean;
    isUserPrivacyPolicyStatus: boolean;
    preference: Preference;
    gstin: string;
    // userVideoBaseURL: any;

    // display fields
    userImage: string;
    group: Group;
}